import { Component, ElementRef, EventEmitter, HostListener, Input, Output, signal } from '@angular/core';
import { Highlightable } from '@angular/cdk/a11y';

@Component({
  selector: 'app-option',
  template: `
    <div class="pt-1.5" data-custom-select-option>
      <div class="text-sm font-medium text-gray-500 pb-1">
        <div [ngClass]="{
          'bg-soft-blue text-digital-blue': isActive()
          }" 
          class="w-full block px-2 py-1.5 hover:bg-soft-blue hover:text-digital-blue rounded-md text-left group/filterButton font-medium">
          <div class="flex items-center gap-10 justify-between">
            <div class="flex items-center gap-1">
              <label
                class="group relative max-w-fit inline-flex items-center justify-start cursor-pointer tap-highlight-transparent p-2 -m-2"
                [attr.data-selected]="isSelected()"
                [attr.data-focus-visible]="isActive()">
                <span
                  aria-hidden="true"
                  class="relative inline-flex items-center justify-center flex-shrink-0 overflow-hidden before:content-[''] before:absolute before:inset-0 before:border-solid before:border-2 before:box-border before:border-default after:content-[''] after:absolute after:inset-0 after:scale-50 after:opacity-0 after:origin-center group-data-[selected=true]:after:scale-100 group-data-[selected=true]:after:opacity-100 group-data-[hover=true]:before:bg-default-100 outline-none group-data-[focus-visible=true]:z-10 group-data-[focus-visible=true]:ring-2 group-data-[focus-visible=true]:ring-focus group-data-[focus-visible=true]:ring-offset-2 group-data-[focus-visible=true]:ring-offset-background after:bg-digital-blue after:text-white text-white w-4 h-4 mr-2 rounded-md before:rounded-md after:rounded-md before:transition-colors group-data-[pressed=true]:scale-95 transition-transform after:transition-transform-opacity after:!ease-linear after:!duration-200 motion-reduce:transition-none">
                  <svg aria-hidden="true" role="presentation" viewBox="0 0 17 18"
                    class="z-10 opacity-0 group-data-[selected=true]:opacity-100 w-3 h-2 transition-opacity motion-reduce:transition-none">
                    <polyline fill="none" points="1 9 7 14 15 4" stroke="currentColor" stroke-dasharray="22"
                      stroke-dashoffset="44" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      style="transition: stroke-dashoffset 250ms linear 0.2s;"></polyline>
                  </svg>
                </span>
              </label>
              <span class="flex-1 line-clamp-1">
                <ng-content></ng-content>
              </span>
            </div>
            <button type="button" (click)="onlySelected($event)"
              class="opacity-0 group-hover/filterButton:opacity-100 text-digital-blue font-medium hover:bg-white p-1 rounded">
              {{ 'global.only' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: `
    :host {
      display: block;
    }
  `
})
export class OptionComponent<T> implements Highlightable {
  @Input({ required: true }) value: T | null = null;
  @Input() disabled = false;

  @Output() selected = new EventEmitter<OptionComponent<T>>();

  protected isSelected = signal(false);
  protected isActive = signal(false);
  public onlySelectedItem = signal(false);

  @HostListener('click')
  protected select() {
    if (!this.disabled) {
      this.highlightAsSelected();
      this.selected.emit(this);
    }
  }

  constructor(
    private el: ElementRef<HTMLElement>
  ) {}

  setActiveStyles(): void {
    this.isActive.set(true);
  }

  setInactiveStyles(): void {
    this.isActive.set(false);
  }

  scrollIntoView(options?: ScrollIntoViewOptions) {
    this.el.nativeElement.scrollIntoView(options);
  }

  highlightAsSelected() {
    this.isSelected.set(true);
  }

  deselect() {
    this.isSelected.set(false);
  }

  protected onlySelected(e: Event) {
    e.stopPropagation();
    this.onlySelectedItem.set(!this.onlySelectedItem());
    this.select();
  }
}