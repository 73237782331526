import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-nav-list-group-item',
  templateUrl: './nav-list-group-item.component.html'
})
export class NavListGroupItemComponent {
  @Input() isScenarioNavGroupItem: boolean = false;
  constructor() { }
}

