import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { Scenario } from '../../model/scenario.model';

@Component({
  selector: 'app-scenario-progress-v2',
  templateUrl: './scenario-progress-v2.component.html'
})
export class ScenarioProgressV2Component implements OnChanges {
  @Input() isProcessing: boolean = false;
  @Input() disabled: boolean = false;
  @Input() scenario: Scenario;

  @Output() onClick = new EventEmitter<void>();

  private progress$ = new BehaviorSubject<number>(0);
  public progressing$ = this.progress$.asObservable();

  constructor() { }

  ngOnChanges(): void {
    this.scenarioJobsProgress(this.scenario);
  }

  ngOnDestroy(): void {
    this.progress$.complete();
  }

  private scenarioJobsProgress(scenario: Scenario) {
    const {currentScenarioJob, estimatedRuntime} = scenario;
    const {createdAt} = currentScenarioJob || {};
    const duration =  !!estimatedRuntime && this.isProcessing ? (moment().diff(createdAt, 'seconds') / estimatedRuntime) : 0;
    this.progress$.next(duration);
  }

  handleClick(): void {
    this.onClick.emit();
  }

  get isError(): boolean {
    if (this.isProcessing) return false
    const state = this.scenario.currentScenarioJob?.state || this.scenario.currentScenarioJobState;
    return (
      state === 'FAILED' || state === 'ERROR' || state === 'CANCELLED'
    );
  }
}
