import { CdkScrollable, ScrollDispatcher } from '@angular/cdk/scrolling';
import { AfterViewInit, Directive, ElementRef, EventEmitter, OnDestroy, Output } from '@angular/core';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[tableStickyTopBar]'
})
export class StickyTopDirective implements AfterViewInit, OnDestroy {

  TOP_SPACING = 10;
  tableRef: HTMLTableElement;
  scrollSubscription: Subscription;

  constructor(private el: ElementRef, private scrollDispatcher: ScrollDispatcher) {}

  ngOnDestroy(): void {
    this.scrollSubscription.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.tableRef = this.el.nativeElement as HTMLTableElement;
    this.scrollSubscription = this.scrollDispatcher.scrolled().subscribe(
      (event: CdkScrollable) => this.scrollPosition(event));
  }
  
  private scrollPosition(event: CdkScrollable): void {
    if(!event) return;
    const scrollContainer = event.getElementRef().nativeElement;
    
    const scrollContainerTopPosition = scrollContainer.getBoundingClientRect()?.top;
    const scrollContainerHeight = scrollContainer.getBoundingClientRect()?.height;
    const breadcrumbsBarHeight = scrollContainer.querySelector('app-breadcrumbs-menu')?.getBoundingClientRect()?.height;
    const footerBarHeight = scrollContainer.querySelector('app-footer').getBoundingClientRect()?.height;
    const totalFixedTopHeight = Math.floor(scrollContainerTopPosition + breadcrumbsBarHeight + footerBarHeight + this.TOP_SPACING); 
    const height = scrollContainerHeight - totalFixedTopHeight;

    this.tableRef.setAttribute('style', `max-height: ${height}px`)
  }
}
