import { createSelector } from '@ngrx/store';
import { AppState } from '../reducers';

export const pageFeatureKey = 'singlePage';

export const selectPageState = (state: AppState) => state.page;

export const selectPageHeaderTitle = createSelector(
  selectPageState,
  state => state?.pageTitle
);

export const selectPage = (name: string) => createSelector(
  selectPageHeaderTitle,
  pageTitle => pageTitle?.includes(name)
);