import { createReducer, on } from '@ngrx/store';
import * as OrganisationActions from '../actions/organisation.actions';
import { IOrganisation } from '../../model/user-management.model';
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';


export interface OrganisationState extends EntityState<IOrganisation> {
  loading: boolean;
  totalCount?: number;
}

export const organisationAdapter: EntityAdapter<IOrganisation> = createEntityAdapter<IOrganisation>();

export const initialOrganisationState: OrganisationState = organisationAdapter.getInitialState({
  loading: false,
  totalCount: 0,
});

export const organisatioStateReducer = createReducer(
  initialOrganisationState,
  on(
    OrganisationActions.updateOrganisation,
    OrganisationActions.fetchOrganisations,
    OrganisationActions.uploadLogo,
    OrganisationActions.deleteLogo,
    (state) => {
      return { ...state, loading: true };
    }
  ),
  on(OrganisationActions.addOrganisation,
    (state, action) => organisationAdapter.addOne(action.organisation, {...state, loading: false})
  ),
  on(OrganisationActions.upsertOrganisation,
    (state, action) => organisationAdapter.upsertOne(action.organisation, {...state, loading: false})
  ),
  on(OrganisationActions.addOrganisations,
    (state, action) => organisationAdapter.addMany(action.organisations, {...state, totalCount: action.totalCount, loading: false})
  ),
  on(OrganisationActions.upsertOrganisations,
    (state, action) => organisationAdapter.upsertMany(action.organisations.results, {...state, loading: false})
  ),
  on(OrganisationActions.updateOrganisationSuccess,
    (state, action) => organisationAdapter.updateOne(action.organisation, {...state, loading: false})
  ),
  on(OrganisationActions.updateOrganisations,
    (state, action) => organisationAdapter.updateMany(action.organisations, {...state, loading: false})
  ),
  on(OrganisationActions.deleteOrganisationById,
    (state, action) => organisationAdapter.removeOne(action.id, {...state, loading: false})
  ),
  on(OrganisationActions.deleteOrganisations,
    (state, action) => organisationAdapter.removeMany(action.ids, {...state, loading: false})
  ),
  on(OrganisationActions.loadOrganisations,
    (state, action) => organisationAdapter.setAll(action.organisations, {...state, totalCount: action.totalCount, loading: false})
  ),
  on(OrganisationActions.clearOrganisations,
    state => organisationAdapter.removeAll(state)
  ),
);