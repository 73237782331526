import { createAction, props } from '@ngrx/store';

export const trackItem = createAction(
  '[AnalyticsNext/API] Create Item',
  props<{ label: string, data: any }>()
);

export const trackPage = createAction(
  '[AnalyticsNext/API] Track Page',
  props<{ pageTitle: string }>()
);