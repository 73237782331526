import { createSelector } from '@ngrx/store';
import { userAdapter } from '../reducers/user.reducer';
import { getAllUserManagement } from './user-management.selectors';

const { selectAll } = userAdapter.getSelectors();

const getUser = createSelector(
  getAllUserManagement,
  (userManagement) => userManagement.users
);

export const selectAllUsers = createSelector(
  getUser, selectAll,
);

export const selectUsers = createSelector(
  getUser, selectAllUsers,
  ({totalCount}, users) => ({users, totalCount})
);

export const selectUserById = (id: string) => createSelector(
  getUser, ({entities}) => entities[id]
);

export const getUserLoading = createSelector(
  getUser, ({loading}) => loading
);