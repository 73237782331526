import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-image-icon',
  templateUrl: './image-icon.component.html',
  styleUrls: ['./image-icon.component.scss'],
  standalone: true,
  imports:[MatIconModule, CommonModule]
})
export class ImageIconComponent implements OnInit {
  @Input() src: string;
  @Input() alt: string;
  
  constructor() { }

  ngOnInit(): void {
  }

}
