import { Component, Input, forwardRef, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-switch-toggle',
  standalone: true,
  imports: [CommonModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SwitchToggleComponent),
      multi: true
    }
  ],
  template: `
    <label [for]="identifier" class="relative flex items-center cursor-pointer">
      <input [id]="identifier" 
      [name]="identifier" 
      type="checkbox" 
      (change)="onChanged($event)" 
      (blur)="regOnTouched($event)"
      [checked]="isChecked()"
      [disabled]="isDisabled()"
       class="sr-only peer" />
        <div class="w-9 h-5 bg-gray-200 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[3px] after:start-[4px] after:bg-white after:rounded-full after:h-3.5 after:w-3.5 after:transition-all peer-checked:bg-kohort-primary active"></div>
    </label>
  `
})
export class SwitchToggleComponent implements ControlValueAccessor {

  @Input() value: any;
  @Input() identifier: any;
  public isChecked = signal(false);
  public isDisabled = signal(false);
  constructor() { }
  
  public regOnChange = (_: any) => {};

  public regOnTouched = (_: any) => {};

  registerOnChange(fn: any): void {
    this.regOnChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.regOnTouched = fn;
  }

  writeValue(value: any) {
    this.isChecked.set(value);
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled.set(isDisabled);
  }

  onChanged($event): void {
    this.isChecked.set($event.target && $event.target.checked);
    this.regOnChange($event.target.checked);
  }
}
