import * as ScenarioActions from '../actions/scenario.actions';
import * as AppActions from '../actions';
import { pillarModelsType, Scenario } from '../../views/scenario/model/scenario.model';
import { createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter, Update } from '@ngrx/entity';
import { IScenarioJobDto } from 'src/app/views/scenario/model/scenario-job-dto.model';
import { IScenarioOverrideEvent } from 'src/app/views/scenario/model/scenario-override.models';
import { FileState } from 'src/app/views/product/model/product.model';

export const scenarioFeatureKey = 'scenario';

export interface State extends EntityState<Scenario> {
  scenario: Scenario;
  breadcrumbScenarios?: Scenario[];
  totalCount?: number;
  jobs?: IScenarioJobDto[];
  job?: IScenarioJobDto;
  loading?: boolean;
  overrideEvents?: IScenarioOverrideEvent[];
  allPillarModels?: pillarModelsType;
}

export const adapter: EntityAdapter<Scenario> = createEntityAdapter<Scenario>();

export const initialState: State = adapter.getInitialState({
  scenario: new Scenario(),
  breadcrumbScenarios: [],
  totalCount: 0,
  jobs: [],
  job: null,
  overrideEvents: [],
  allPillarModels: {monetisation: [], new_users: [], retention: []},
  loading: false,
});

export const reducer = createReducer(
  initialState,
  on(ScenarioActions.fetchScenarios, ScenarioActions.setPillarModel, AppActions.toggleInternalData, state => ({
    ...state,
    loading: true
  })),
  on(ScenarioActions.setScenario, (state, action) => {
    return {...state, scenario: action.scenario, loading: false};
  }),
  on(ScenarioActions.loadScenariosFailure, ScenarioActions.loadScenarioJobsFailure, ScenarioActions.loadScenarioLatestJobFailure, ScenarioActions.setPillarModelFailure, (state) => ({
    ...state,
    loading: false
  })),
  on(ScenarioActions.loadScenarioJobsSuccess, (state, action) => {
    return {...state, jobs: action.scenarioJobs, loading: false};
  }),
  on(ScenarioActions.loadScenarioLatestJobSuccess, (state, action) => {
    return {...state, job: action.scenarioLatestJob, loading: false};
  }),
  on(ScenarioActions.setOverrideEvent, (state, action) => {
    const loading = ![FileState.PROCESSED, FileState.FAILED].includes(action.overrideEvent?.state);
    return {
      ...state,
      loading,
      overrideEvents: [action.overrideEvent, ...state.overrideEvents],
      scenario: {
        ...state.scenario,
        files: [{
          state: action.overrideEvent.state,
          filename: action.overrideEvent.filename,
          type: action.overrideEvent.fileType,
        }, ...state.scenario.files ?? []]
      }
    };
  }),
  on(ScenarioActions.setPillarModelSuccess, (state, action) => {
    function getPillarModel(value: string): string[] {
      const {pillar, pillarModels} = action;
      return pillar === value ? pillarModels.map(model => model.name) : state.allPillarModels[value];
    }

    const monetisation = getPillarModel('monetisation'),
      new_users = getPillarModel('new_users'),
      retention = getPillarModel('retention'),
      allPillarModels = {...state.allPillarModels, monetisation, new_users, retention};
    return {
      ...state,
      allPillarModels,
      loading: false
    };
  }),
  on(ScenarioActions.setPillarModelsSuccess, (state, action) => {
    function getPillarModel(value: string): string[] {
      return action.pillarModels[value].map(model => model.name);
    }

    const monetisation = getPillarModel('monetisation'),
      new_users = getPillarModel('new_users'),
      retention = getPillarModel('retention');
    return {
      ...state,
      allPillarModels: {...state.allPillarModels, monetisation, new_users, retention},
      loading: false
    };
  }),
  // UI - V2 reducers
  on(ScenarioActions.loadBreadcrumbScenarios, (state, action) => {
    return {...state, breadcrumbScenarios: action.scenarios, loading: false};
  }),
  on(ScenarioActions.addScenario,
    (state, action) => {
      const breadcrumbScenarios = [...state.breadcrumbScenarios, action.scenario].sort((a, b) => a.name.localeCompare(b.name));
      return adapter.addOne(action.scenario, {...state, breadcrumbScenarios, totalCount: state.totalCount + 1});
    }
  ),
  on(ScenarioActions.upsertScenario,
    (state, action) => adapter.upsertOne(action.scenario, state)
  ),
  on(ScenarioActions.addScenarios,
    (state, action) => adapter.addMany(action.scenarios, state)
  ),
  on(ScenarioActions.upsertScenarios,
    (state, action) => adapter.upsertMany(action.scenarios, {...state, totalCount: action.totalCount, loading: false})
  ),
  on(ScenarioActions.loadScenarios,
    (state, action) => adapter.setAll(action.scenarios, {...state, totalCount: action.totalCount, loading: false})
  ),
  on(ScenarioActions.updateScenarioSuccess,
    (state, action) => adapter.updateOne(action.scenario, {
      ...state,
      scenario: updateScenario(state, action.scenario),
      breadcrumbScenarios: updateBreadcrumbScenarios(state, action.scenario)
    })
  ),
  on(ScenarioActions.setScenarioJob,
    (state, action) => adapter.updateOne({
      id: action.scenarioJob.scenario.id,
      changes: {currentScenarioJob: action.scenarioJob}
    }, {
      ...state,
      scenario: updateScenario(state, {
        id: action.scenarioJob.scenario.id,
        changes: {currentScenarioJob: action.scenarioJob}
      })
    })
  ),
  on(ScenarioActions.updateScenarios,
    (state, action) => adapter.updateMany(action.scenarios, state)
  ),
  on(ScenarioActions.deleteScenarioById,
    (state, action) => {
      const breadcrumbScenarios = state.breadcrumbScenarios?.filter(scenario => scenario?.id !== action.id);
      return adapter.removeOne(action.id, {...state, breadcrumbScenarios, totalCount: state.totalCount - 1});
    }
  ),
  on(ScenarioActions.deleteScenarios,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(ScenarioActions.clearScenarios,
    (state) => adapter.removeAll({
      ...state,
      breadcrumbScenarios: [],
      totalCount: 0,
      loading: false,
      errors: null,
      jobs: [],
      job: null,
      overrideEvents: [],
      allPillarModels: {monetisation: [], new_users: [], retention: []}
    })
  ),
);

// The following manual state updated are needed to keep the breadcrumbs dropdown list of all scenarios and the current scenario in sync
const updateScenario = (state: State, action: Update<Scenario>) => {
  const updatedScenario = {...state.entities[action.id], ...action.changes};
  return state.scenario?.id === action.id ? {...state.scenario, ...updatedScenario} : state.scenario
};

const updateBreadcrumbScenarios = (state: State, action: Update<Scenario>) => {
  const updatedScenario = {...state.entities[action.id], ...action.changes};
  return state.breadcrumbScenarios.map(scenario => scenario?.id === action.id ? {...scenario, ...updatedScenario} : scenario)
};

export const {
  selectEntities,
  selectIds,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

