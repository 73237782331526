<div class="flex rounded-lg h-10 border bg-white dark:border-transparent dark:bg-slate-800 overflow-hidden">
  @for (theme of themes; track theme.label) {
    <button (click)="toggleTheme(theme)"
      [ngClass]="{
          'bg-soft-blue shadow-inner dark:shadow-none dark:bg-black/30 text-yellow-400': isActiveTheme(theme),
          'text-black/60 dark:text-white/60': !isActiveTheme(theme),
      }"
      class="active flex-1 w-10 hover:bg-soft-blue dark:hover:bg-slate-700 h-full flex items-center justify-center group/button">
      <div class="h-4 group-hover/button:rotate-12 transition-all">
        <app-svg-images [svgName]="theme.icon"></app-svg-images>
      </div>
    </button>
  }
</div>