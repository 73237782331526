
import { IntercomConfig } from '../../intercom/intercom.config';
import { SentryConfig } from '../../sentry/sentry.config';
import { inject } from '@angular/core';
import { APP_CONFIG } from "../../app.constants";
import { AuthConfig } from "@auth0/auth0-angular";

interface Idps {
  google: string;
}

interface Pusher {
  key: string;
}

interface FullStory {
  enabled: boolean;
  orgId: string;
}

export interface AnalyticsNextConfig {
  enabled?: boolean;
  writeKey?: string;
}

interface Auth0Config {
  clientId: string;
  domain: string;
  audience: string;
  scope: string;
  rampOrgId: string;
}

export enum Auth0UserTokenKeys {
  EMAIL = 'email',
  EMAIL_VERIFIED = 'email_verified',
  FAMILY_NAME = 'family_name',
  GIVEN_NAME = 'given_name',
  METADATA = 'https://rampgrowth.com/metadata',
  ROLES = 'https://rampgrowth.com/roles',
  LOCALE = 'locale',
  NAME = 'name',
  NICKNAME = 'nickname',
  UPDATED_AT = 'updated_at',
}

export interface IAppConfig {
  pageTitle: string;
  fullStory: FullStory;
  pusher: Pusher;
  embedDomain: string;
  resourceServer: {
    reportsUrl: string
    runwayUrl: string
  };
  auth0: Auth0Config;
  idps: Idps;
  intercom: IntercomConfig;
  sentry: SentryConfig,
  analyticsNext: AnalyticsNextConfig;
  ramp: {
    env: string
  };
}

export class AppConfig implements IAppConfig {

  static fromJson(data: any): AppConfig {
    return Object.assign(new AppConfig(), data);
  }

  getAuth0Config(): AuthConfig {
    const {auth0: {domain, clientId, scope, audience}} = this;
    const {resourceServer: {runwayUrl, reportsUrl}} = this;

    return {
      domain,
      clientId,
      authorizationParams: {
        audience,
        redirect_uri: `${window.location.origin}/implicit/callback`,
        scope
      },
      httpInterceptor: {
        allowedList: [`${reportsUrl}*`, `${runwayUrl}*`],
      }
    };
  }

  analyticsNext: AnalyticsNextConfig;
  auth0: Auth0Config;
  embedDomain: string;
  fullStory: FullStory;
  idps: Idps;
  intercom: IntercomConfig;
  pageTitle: string;
  pusher: Pusher;
  ramp: { env: string };
  resourceServer: { reportsUrl: string; runwayUrl: string };
  sentry: SentryConfig;
}

export interface IPandasDateRange {
  min?: Date,
  max?: Date
}

export class ConfigAwareService {
  private appConfig = inject(APP_CONFIG);

  get environment() {
    return this.appConfig;
  }

  get baseUrl() {
    return this.environment.resourceServer.runwayUrl + `reports/`;
  }

  get reportsUrl() {
    return this.environment.resourceServer.reportsUrl;
  }
}
