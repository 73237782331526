@if (scenario$ | async; as scenario) {
  @if ((scenario.stale && isProcessed(scenario)) || isNewOrDuplicated(scenario)) {
    <div
      @slideDownPositionAnimation 
      class="notification py-1 bg-info" 
      [ngClass]="{
        'opacity-[0.8] dark:bg-white/30' : scenario.locked,
        'opacity-[0.95]': !scenario.locked
      }">
      <span [translate]="(isNewOrDuplicated(scenario)) ? 'data.scenario.runNow' : 'data.scenario.updatesPending'"></span>
      <mat-spinner-button [options]="options" [loading]="loading" [disabled]="scenario.locked" (click)="handleAction(scenario)">
        {{ 'data.scenario.changes' | translate }}
      </mat-spinner-button>
      @if (!isNewOrDuplicated(scenario)) {
        <span class="pl-2">
          <button type="button" mat-icon-button (click)="scenarioChanges(scenario)">
            <mat-icon>info</mat-icon>
          </button>
        </span>
      }
    </div>
  }
  @if (isProcessing(scenario)) {
    <app-scenario-progress
      [embedded]="false"
      [isNotificationBar]="true"
      [scenario]="scenario">
    </app-scenario-progress>
  }
  <ng-template #none></ng-template>
}
