import { createSelector } from '@ngrx/store';
import { getAllUserManagement } from './user-management.selectors';
import { organisationAdapter } from '../reducers';

const { selectAll } = organisationAdapter.getSelectors();

const organisationsState = createSelector(
  getAllUserManagement,
  (userManagement) => userManagement.organisations
);

export const selectAllOrganisations = createSelector(
  organisationsState, selectAll,
);

export const selectOrganisations = createSelector(
  organisationsState, selectAllOrganisations,
  ({totalCount}, results) => ({results, total: totalCount})
);

export const selectOrganisationById = (id: string) => createSelector(
  organisationsState, ({entities}) => entities[id]
);

export const getOrganisationLoading = createSelector(
  organisationsState, ({loading}) => loading
);