import {Injectable, Input} from '@angular/core';
import {BehaviorSubject, Observable, Subject, Subscription, timer} from 'rxjs';
import {finalize, map, scan, startWith, switchMap, take, takeUntil, takeWhile, tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  public currentNumber = 0;
  private loader$ = new BehaviorSubject<boolean>(false);
  private _counterSub$ = new BehaviorSubject<number>(0);
  private _destroy$ = new Subject<void>();
  public loadingState = this.loader$.asObservable();
  private _timer: Subscription;
  private _completeTimer: Subscription;
  @Input() period = 100;

  get value$() {
    return this._counterSub$;
  }

  complete(): void {
    this._timer.unsubscribe();
    this._completeTimer = timer(0, 500).pipe(
      take(2),
      map((t) => {
        return t === 0 ? 100 : 0;
      }),
      finalize(() => this._completeTimer.unsubscribe())
    ).subscribe(v => {
      this._counterSub$.next(v);
    });
    this._destroy$.next();
    this._destroy$.complete()
  }

  start(): void {
    this._timer = timer(0, this.period).pipe(
      startWith(this.currentNumber),
      scan((accumulator, value) => accumulator + value),
      takeWhile(v => v <= 100),
      takeUntil(this._destroy$)
    ).subscribe(v => {
      this._counterSub$.next(v)
    });
  }

  show() {
    this.loader$.next(true);
  }

}
