import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-panel-list-item',
  templateUrl: './panel-list-item.component.html'
})
export class PanelListItemComponent {
  @Input() enableFavourite: boolean = false;
  @Input() isFavouriteLimit: boolean = false;
  @Input() isFavourite: boolean = false;
  @Output() favouriteClick =  new EventEmitter<boolean>();

  toggleFavourite() {
    this.favouriteClick.emit(!this.isFavourite);
  }
}
