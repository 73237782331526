import { Component, EventEmitter, HostListener, Input, Output, TemplateRef } from '@angular/core';
import { MatProgressButtonOptions } from './mat-spinner-button.interface';


@Component({
  // tslint:disable-next-line:component-selector
  selector: 'mat-spinner-button',
  templateUrl: './spinner-button.component.html',
  styleUrls: ['./spinner-button.component.scss']
})
export class MatSpinnerButtonComponent {
  @Input() loading: boolean;
  @Input() disabled = false;
  @Input() options: MatProgressButtonOptions = {};
  @Output() btnClick: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  @HostListener('click', ['$event'])
  public onClick(event: MouseEvent) {
    if (!this.disabled && !this.loading) {
      this.btnClick.emit(event);
    }
  }
}
