import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { Component, Inject, OnInit } from '@angular/core';
import { DialogData } from 'src/app/views/product/model/dialog-data';

@Component({
  selector: 'app-update-app-dialogue',
  templateUrl: './update-app-dialogue.component.html',
  styleUrls: ['./update-app-dialogue.component.scss']
})
export class UpdateAppDialogueComponent implements OnInit {
  constructor(
    public dialogRef: DialogRef<boolean>,
    @Inject(DIALOG_DATA) public dialogData: DialogData) {
  }

  ngOnInit(): void {
  }

}
