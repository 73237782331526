import {animate, animateChild, group, query, state, style, transition, trigger} from '@angular/animations';

export const slideInAnimation =
  trigger('routeAnimations', [
    transition('Home <=> Home', [
      style({position: 'relative'}),
      query(':enter, :leave', [
        style({
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%'
        })
      ]),
      query(':enter', [
        style({left: '-100%'})
      ]),
      query(':leave', animateChild()),
      group([
        query(':leave', [
          animate('300ms ease-out', style({left: '100%'}))
        ]),
        query(':enter', [
          animate('300ms ease-out', style({left: '0%'}))
        ])
      ]),
      query(':enter', animateChild()),
    ]),
    transition('Home <=> Home', [
      style({position: 'relative'}),
      query(':enter, :leave', [
        style({
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%'
        })
      ]),
      query(':enter', [
        style({left: '-100%'})
      ]),
      query(':leave', animateChild()),
      group([
        query(':leave', [
          animate('200ms ease-out', style({left: '100%'}))
        ]),
        query(':enter', [
          animate('300ms ease-out', style({left: '0%'}))
        ])
      ]),
      query(':enter', animateChild()),
    ])
  ]);

export const slideDownAnimation = trigger(
  'slideDownAnimation',
  [
    transition(
      ':enter',
      [
        style({height: 0}),
        animate('0.35s ease-out',
          style({height: '168px'}))
      ]
    ),
    transition(
      ':leave',
      [
        style({height: '168px'}),
        animate('0.35s ease-in',
          style({height: 0}))
      ]
    )
  ]
);


export const slideDownPositionAnimation = trigger(
  'slideDownPositionAnimation',
  [
    transition(
      ':enter',
      [
        style({top: '-40px'}),
        animate('0.35s ease-out',
          style({top: '0px'}))
      ]
    ),
    transition(
      ':leave',
      [
        style({top: '0px'}),
        animate('0.35s ease-in',
          style({top: '-40px'}))
      ]
    )
  ]
);


export const slideUpDownAnimation = trigger(`slideUpDownAnimation`, [
  state('0', style({
    overflow: 'hidden',
    height: '*'
  })),
  state('1', style({
    overflow: 'hidden',
    height: `360px`
  })),
  transition('1 => 0', animate('400ms ease-in-out')),
  transition('0 => 1', animate('400ms ease-in-out'))
]);
