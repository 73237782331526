import { createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { ScenarioSegment } from 'src/app/views/scenario/model/scenario-segments.model';
import { Product } from '../../views/product/model/product.model';
import * as ProductActions from '../actions/product.actions';
import * as AppActions from '../actions';

export interface State extends EntityState<Product> {
  segments: ScenarioSegment[];
  totalCount?: number;
  loading: boolean;
  errors?: any;
}

export const adapter: EntityAdapter<Product> = createEntityAdapter<Product>();

export const initialState: State = adapter.getInitialState({
  segments: [],
  totalCount: 0,
  loading: false,
  errors: null,
});

export const reducer = createReducer(
  initialState,
    on(ProductActions.fetchProducts, AppActions.toggleInternalData, (state) => {
      return {...state, loading: true};
    }),
    on(ProductActions.loadProductSegmentsSuccess, (state, action) => {
      return {...state, segments: action.segments};
    }),
    on(ProductActions.addProduct,
      (state, action) => adapter.addOne(action.product, state)
    ),
    on(ProductActions.upsertProduct,
      (state, action) => adapter.upsertOne(action.product, state)
    ),
    on(ProductActions.addProducts,
      (state, action) => adapter.addMany(action.products, state)
    ),
    on(ProductActions.upsertProducts,
      (state, action) => adapter.upsertMany(action.products, {...state, totalCount: action.totalCount, loading: false})
    ),
    on(ProductActions.loadProducts,
      (state, action) => adapter.setAll(action.products, {...state, totalCount: action.totalCount, loading: false})
    ),
    on(ProductActions.updateProduct,
      (state, action) => adapter.updateOne(action.product, state)
    ),
    on(ProductActions.updateProducts,
      (state, action) => adapter.updateMany(action.products, state)
    ),
    on(ProductActions.deleteProductById,
      (state, action) => adapter.removeOne(action.id, state)
    ),
    on(ProductActions.deleteProducts,
      (state, action) => adapter.removeMany(action.ids, state)
    ),
    on(ProductActions.clearProducts,
      state => adapter.removeAll({
        ...state, 
        totalCount: 0, 
        loading: false, 
        errors: null, 
        segments: []
      })
    ),
);

export const {
  selectEntities,
  selectIds,
  selectAll,
  selectTotal,
} = adapter.getSelectors();