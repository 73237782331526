import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { IScenario } from '../model/scenario.model';
import { Store } from '@ngrx/store';
import { AppState, displayMessage, fetchScenarios, selectScenario } from 'src/app/store';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ScenarioActionsService } from '../services/scenario-actions.service';

@Component({
  selector: 'app-scenario-page-header',
  templateUrl: './scenario-page-header.component.html'
})
export class ScenarioPageHeaderComponent implements OnInit, OnDestroy {

  private store = inject(Store<AppState>);
  private router = inject(Router);
  private scenarioActions = inject(ScenarioActionsService);
  public scenario$: Observable<IScenario> = this.store.select(selectScenario);
  public isFavouritesLimit$ = this.scenarioActions.isFavouritesLimit$;

  ngOnInit(): void {
    this.scenarioActions.isListPageSignal.set(false);
  }
  ngOnDestroy(): void {
    this.scenarioActions.isListPageSignal.set(true);
  }

  async duplicateScenario(scenario: IScenario) {
    const { id, product: { id: productId } } = scenario;
    const data = { scenarioId: id, clone: true };
    const result = await this.scenarioActions.createScenario(data);

    if (!!result && !!result.scenarioId) {
      this.router.navigate(['/products', productId, 'scenarios', result.scenarioId]).then(_ => {
        this.store.dispatch(displayMessage({
          detail: 'scenario.config.advanced.cloned',
          summary: 'View'
        }));
      });
      this.store.dispatch(fetchScenarios({ paginationParams: { filter: { productId } } }));
    };
  }

  archiveScenario(scenario: IScenario) {
    this.scenarioActions.archiveScenario(scenario);
  }

  setAsBudget(scenario: IScenario) {
    this.scenarioActions.setAsBudgetScenario(scenario);
  }

  toggleReportVisibility(element: IScenario) {
    this.scenarioActions.toggleReportVisibility(element);
  }

  toggleLocked(element: IScenario) {
    this.scenarioActions.toggleLocked(element);
  }

  async deleteScenario(scenario: IScenario) {
    const shouldRedirect = true;
    await this.scenarioActions.deleteScenario(scenario, shouldRedirect);
  }

  onFavouriteClick(scenario: IScenario): void {
    const { favourite } = scenario;
    this.scenarioActions.onFavouriteClick(scenario, !favourite);
  }
}
