import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
import {Product} from '../../views/product/model/product.model';

@Pipe({
  name: 'humanize'
})
export class HumanizePipe implements PipeTransform {

  transform(value: Product, ...args: unknown[]): unknown {
    if (value.state === 'NEW')
      return 'N/A';

    return moment(value.metrics.sourceRange.end).fromNow();
  }

}
