<div class="d-flex">
  <h6 class="status-text" translate="global.uploading"></h6>
  <svg width="13px" height="13px" viewBox="0 0 13 13" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Edit-Product-version" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Kohort_EditProduct_Modal" transform="translate(-659.000000, -568.000000)" fill="#F37676" fill-rule="nonzero">
        <g id="24px-copy-3" transform="translate(658.000000, 566.000000)">
          <path d="M4.25,7.85 L4.25,9.15 L10.75,9.15 L10.75,7.85 L4.25,7.85 Z M7.5,2 C3.912,2 1,4.912 1,8.5 C1,12.088 3.912,15 7.5,15 C11.088,15 14,12.088 14,8.5 C14,4.912 11.088,2 7.5,2 Z M7.5,13.7 C4.6335,13.7 2.3,11.3665 2.3,8.5 C2.3,5.6335 4.6335,3.3 7.5,3.3 C10.3665,3.3 12.7,5.6335 12.7,8.5 C12.7,11.3665 10.3665,13.7 7.5,13.7 Z" id="cancel"></path>
        </g>
      </g>
    </g>
  </svg>
</div>
