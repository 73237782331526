import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-nav-list-item',
  templateUrl: './nav-list-item.component.html'
})
export class NavListItemComponent {
  
  @Input() isScenarioNavLink: boolean = false;
  constructor() { }
}

