<app-dialog [showActions]="false" (close)="dialogRef.close()">
  <h1 custom-dialog="header" >
    <span translate="diff.title"></span>
  </h1>
  <ng-container custom-dialog="content">
    <p translate="diff.info"></p>

    <table class="scenario-change-summary w-100">
      <thead>
        <tr>
          <th translate="diff.section"></th>
          <th translate="diff.changes"></th>
        </tr>
      </thead>
      <tbody>
        @for (sectionKey of keysWithValues; track sectionKey) {
          <tr>
            <th class="mat-header-cell" [translate]="'diff.'+sectionKey"></th>
            <td class="section-changes" [translate]="'diff.'+sectionKey">
              @for (item of data[sectionKey]; track item) {
                <p>
                  {{item}}
                </p>
              }
            </td>
          </tr>
        }
      </tbody>
    </table>
  </ng-container>
</app-dialog>

