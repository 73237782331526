import { CommonModule } from '@angular/common';
import { Component, ContentChildren, Input, QueryList, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-pill-toggle-item',
  template: `
    <ng-template #templateRef>
        <span
            class="active font-medium text-gray-700 transition-all inline-block relative"
            [ngClass]="{
              'rounded-full !text-white': isActive,
              'bg-digital-blue': variant === 'tertiary' && isActive,
              'bg-kohort-primary': variant === 'primary' && isActive,
              'bg-kohort-secondary': variant === 'secondary' && isActive,
              'py-1 px-3' : size === 'sm',
              'py-2 px-7' : size === 'lg',
            }">
              <ng-content />
          </span>
    </ng-template>
  `,
  standalone: true,
  imports: [CommonModule]
})
export class PillToggleItemComponent {
  @Input() variant: 'primary' | 'secondary' | 'default' | 'tertiary' = 'tertiary';
  @Input() size: 'sm' | 'lg' = 'sm';
  @Input() isActive: boolean = false;

  @ViewChild('templateRef', {static: true}) public templateRef: TemplateRef<any>;

}

@Component({
  selector: 'app-pill-toggle',
  standalone: true,
  imports: [PillToggleItemComponent, CommonModule],
  template: `
    <div class="p-1 bg-white"
      [ngClass]="{
        'border border-gray-300 shadow-sm rounded-full': hasBorder,
      }">
      <div class="flex items-center text-black gap-1.5 justify-center text-[13px]">
        @for (row of rows; track row) {
          <ng-container *ngTemplateOutlet="getTemplateRef(row)"></ng-container>
        }
      </div>
    </div>
  `,
  styles: `
    :host {
      display: inline-block;
    }
  `,
})
export class PillToggleComponent {
  @Input() hasBorder = false;
  private templateCache = new Map<any, TemplateRef<any>>();

  @ContentChildren(PillToggleItemComponent) rows: QueryList<PillToggleItemComponent>;

  getTemplateRef(row: any): TemplateRef<any> {
    if (!this.templateCache.has(row)) {
      this.templateCache.set(row, row.templateRef);
    }
    return this.templateCache.get(row);
  }
}
