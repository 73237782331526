import {InjectionToken} from '@angular/core';
import { AppConfig } from "../config/models/config";

export interface SentryConfig {
  dsn?: string;
  enabled?: boolean;
}

export const SENTRY_CONFIG = new InjectionToken<SentryConfig>('SENTRY_CONFIG');

export function getSentryConfig(config: AppConfig) {
  return config.sentry;
}
