<app-overlay-popup [label]="scenario.currentScenarioJob?.errors?.userMessage">
    <div 
        class="relative overflow-hidden pl-2.5 pr-3.5 py-1.5 rounded-full border hover:shadow-sm border-transparent group-hover/scenarioRow:border-gray-300 transition-all group/scenarioProgress"
        [ngClass]="{
            'bg-soft-blue': isProcessing, 
            'bg-white/30 group-hover/scenarioRow:bg-white': !isProcessing && !isError, 
            'bg-red-100/50': isError,
            'opacity-50' : scenario.locked
            }">
        <div class="bg-gradient-to-bl from-digital-blue/60 to-digital-blue/90 h-full absolute left-0 top-0 bottom-0 transition-all"
        [ngStyle]="{'width': isProcessing ? 'calc(' + (progressing$ | async) + ' * 100%)' : 0}"></div>
        <div class="relative flex gap-2 items-center">
            <button data-cy="play-stop-button" 
                class="bg-white h-8 w-8 rounded-full border border-transparent group-hover/scenarioRow:border-gray-200 group-hover/scenarioRow:shadow-sm active inline-flex items-center justify-center hover:border-gray-300"
                [disabled]="disabled"
                (click)="handleClick()">
                <app-svg-images
                class="h-4 block group-hover/scenarioProgress:text-black"
                [ngClass]="{ 'text-kohort-primary': scenario.stale && !isProcessing, 'text-gray-500': !scenario.stale && !isProcessing }"
                [svgName]="isProcessing ? 'stop' : 'play'"
                ></app-svg-images>
            </button>
            <app-state class="w-[135px]" [state]="scenario.currentScenarioJob?.state || scenario.currentScenarioJobState"></app-state>
        </div>
    </div>
</app-overlay-popup>