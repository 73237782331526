import { inject, Injectable } from '@angular/core';
import { ConfigAwareService } from '../core/config/models/config';
import { Apollo, gql } from 'apollo-angular';
import { shareReplay } from 'rxjs/operators';
import { IGraphQLParams } from './graphql.models';

export const NODE_QUERY = gql`
  query getNode($id: ID!) {
    node(id: $id) {
      id
    }
  }`;

@Injectable({
  providedIn: 'root'
})
export class GraphqlService extends ConfigAwareService {

  constructor() {
    super();
  }

  get baseUrl() {
    return this.environment.resourceServer.runwayUrl + `graphql`;
  }

  apollo = inject(Apollo);

  getNode(id) {
    return this.apollo.watchQuery({
      query: NODE_QUERY,
      variables: {
        id
      }
    }).valueChanges.pipe(shareReplay(1))
  }

  mapParams(graphqlParams: IGraphQLParams): object {
    const {page, pageSize, sort, filter} = graphqlParams
    return {
      page,
      pageSize,
       ...(sort && sort.length > 0) && {sort: sort.join('_').toUpperCase()},
      ...(filter) && {filter}
    };
  }
}
