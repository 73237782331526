import {ChangeDetectionStrategy, Component, Input, OnChanges} from '@angular/core';
import {VALID_PANDAS_DATE_RANGE} from '../../app.constants';
import {UntypedFormGroup} from '@angular/forms';
import {IPandasDateRange} from '../../config/models/config';
import {EventsState} from '../../../views/events/model/events.model';
import moment from 'moment';

@Component({
    selector: 'app-date-picker',
    templateUrl: './date-picker.component.html',
    styleUrls: ['./date-picker.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatePickerComponent implements OnChanges {

    public validateDate: IPandasDateRange;
    public startDate: string | null;

    @Input() datePickerFormGroup: UntypedFormGroup;
    @Input() datePickerLabel: string;
    @Input() datePickerName: string;
    @Input() datePickerError: string;
    @Input() datePickerCustomValidation?: IPandasDateRange;
    @Input() isDateRange?: boolean;
    @Input() showDatePickerLabel: boolean = true;
    @Input() eventState: EventsState;
    @Input() appearance = 'outline';
    @Input() className = ''

    constructor() {
    }
    ngOnChanges(): void {
        this.setValidateDate();
        this.setStartDate();
    }

    setValidateDate(): void {
        const customValidDates = Boolean(this.datePickerCustomValidation) ? this.datePickerCustomValidation : null;
        this.validateDate = {...VALID_PANDAS_DATE_RANGE, ...customValidDates};
    }

    setStartDate(): void {
        this.startDate = (this.eventState === EventsState.FUTURE)
            ? moment(this.datePickerCustomValidation.min).add(1, 'day').format('YYYY-MM-DD')
            : null;
    }

}
