import { gql } from 'apollo-angular';
import { scenarioMarketingFields, scenarioMarketingOverrides } from '../settings/graphql/queries';

export const SCENARIO_JOB_FRAGMENT = gql`
  fragment ScenarioJob on ScenarioJobNode {
    id
    createdAt
    completedAt
    actionedBy
    updatedBy
    updatedAt
    state
    stats
    errors
    runwayVersion
    engineVersion
    reportTransformationsVersion
    transformingEnd
    jobType
    scenario {
      __typename
      internal
    }
    __typename
  }
`;

export const SCENARIO_FRAGMENT = gql`
  fragment ScenarioBase on ScenarioNode {
    id
    name
    description
    config
    currentScenarioJobState
    default
    budget
    archived
    enableDefaultEvents
    estimatedRuntime
    favourite
    internal
    files {
      id
      filename
      processingStart
      processingEnd
      originalFilename
      type
      state
      errors
      createdAt
      dataCopied
      updatedAt
    }
    includeInReports
    jobTrigger
    locked
    metrics
    monitored
    stale
    staleMessage
    product {
      id
      name
    }
    maxDesiredHoursBetweenProcessing
    __typename
  }
`;

export const SCENARIO_QUERY = gql`
  ${SCENARIO_JOB_FRAGMENT}
  ${SCENARIO_FRAGMENT}
  query getScenario(
    $scenarioId: BigInt!
    $pageSize: Int
    $sort: [ScenarioUserEventNodeSortEnum]
  ) {
    scenario(id: $scenarioId) {
      ...ScenarioBase
      maxForecastDx
      enableDefaultEvents
      createdBy
      createdAt
      enableInsights
      scenarioUserEvent(pageSize: $pageSize, sort: $sort) {
        edges {
          node {
            id
            updatedAt
            updatedBy
          }
        }
      }
      currentScenarioJob {
        ...ScenarioJob
      }
      latestScenarioJob {
        ...ScenarioJob
      }
      product {
        id
        name
        metrics
        state
        config
      }
      schedule {
        days
        hour
      }
      __typename
    }
  }
`;

export const CONFIG_SUMMARY_QUERY = gql`
  query getScenarios($page: Int, $pageSize: Int, $sort: [ScenarioNodeSortEnum], $filter: ScenarioFilter) {
    scenarios(page: $page, pageSize: $pageSize, sort: $sort, filters: $filter) {
      edges {
        node {
          maxForecastDx
          name
          description
          config
          jobTrigger
          default
          files {
            filename
            type
            originalFilename
            state
          }
          schedule {
            hour
            days
          }
          scenarioMarketingConfig {
            ${scenarioMarketingFields}
            ${scenarioMarketingOverrides}
            overrideSegmentsForPaid {
              id
              name
            }
            overrideSegmentsForPvc {
              id
              name
            }
          }
          scenarioMilestones(
            filters: {
              deleted: false
            }
          ) {
            edges {
              node {
                name
                adjustments: scenarioMilestoneAdjustments(
                  sort: [TARGET_DX_ASC],
                  filters: {
                    adjustmentIsNull: false
                  }
                ) {
                  edges {
                    node {
                      id
                      adjustment
                      pillar
                    }
                  }
                }
              }
            }
          }
        }
      }
      __typename
    }
  }
`;

export const SCENARIO_LIST_QUERY = gql`
  ${SCENARIO_FRAGMENT}
  ${SCENARIO_JOB_FRAGMENT}
  query getScenarios(
    $page: Int
    $pageSize: Int
    $sort: [ScenarioNodeSortEnum]
    $filter: ScenarioFilter
  ) {
    scenarios(page: $page, pageSize: $pageSize, sort: $sort, filters: $filter) {
      totalCount
      edges {
        node {
          ...ScenarioBase
          schedule {
            hour
            days
          }
          currentScenarioJob {
            ...ScenarioJob
          }
          scenarioUserEvent(sort: [UPDATED_AT_DESC], first: 1) {
            edges {
              node {
                updatedAt
                updatedBy
              }
            }
          }
          createdBy
        }
      }
      __typename
    }
  }
`;

export const SCENARIO_JOB_LIST_QUERY = gql`
  ${SCENARIO_JOB_FRAGMENT}
  query getScenarioJobs(
    $page: Int
    $pageSize: Int
    $sort: [ScenarioJobNodeSortEnum]
    $filter: ScenarioJobFilter
  ) {
    scenarioJobs(
      page: $page
      pageSize: $pageSize
      sort: $sort
      filters: $filter
    ) {
      totalCount
      edges {
        node {
          ...ScenarioJob
          changes {
            product
            general
            newUsers
            retention
            monetisation
            marketingV2
          }
        }
      }
    }
  }
`;

export const SCENARIO_JOB_SCENARIO_QUERY = gql`
  ${SCENARIO_JOB_FRAGMENT}
  query getScenarioJobs(
    $page: Int
    $pageSize: Int
    $sort: [ScenarioJobNodeSortEnum]
    $filter: ScenarioJobFilter
  ) {
    scenarioJobs(
      page: $page
      pageSize: $pageSize
      sort: $sort
      filters: $filter
    ) {
      totalCount
      edges {
        node {
          ...ScenarioJob
          scenario {
            name
            estimatedRuntime
            id
            orgId
            createdBy
            internal
            product {
              name
              id
              config
            }
          }
        }
      }
    }
  }
`;

export const SCENARIO_JOB_QUERY = gql`
  ${SCENARIO_JOB_FRAGMENT}
  query getScenarioJob($id: BigInt!) {
    scenarioJob(id: $id) {
      ...ScenarioJob
      scenario {
        name
        estimatedRuntime
        id
        orgId
        createdBy
        internal
        product {
          name
          id
        }
      }
    }
  }
`;

export const SCENARIO_SEGMENTS_LIST_QUERY = gql`
  query getScenarioSegments(
    $page: Int
    $pageSize: Int
    $sort: [ScenarioSegmentNodeSortEnum]
    $filter: ScenarioSegmentFilter
  ) {
    scenarioSegments(
      page: $page
      pageSize: $pageSize
      sort: $sort
      filters: $filter
      onlyLastJob: true
    ) {
      totalCount
      edges {
        node {
          scenarioSegmentPKDimRampSegmentId: dimRampSegmentId
          errors
          jobType
          scenarioJobId
          segmentName: name
          metadata
          processingStart
          processingEnd
          scenarioSegmentPKScenarioId: scenarioId
          state
          __typename
        }
      }
    }
  }
`;

export const PILLAR_MODEL_QUERY = gql`
  query getPillarModels($pillar: String!) {
    pillarModels(pillar: $pillar) {
      name
      __typename
    }
  }
`;

export const ALL_PILLAR_MODEL_QUERY = gql`
  query getPillarModels($retention: String!, $monetisation: String!, $newUsers: String!) {
    retention: pillarModels(pillar: $retention) {
      name
      __typename
    }
    monetisation: pillarModels(pillar: $monetisation) {
      name
      __typename
    }
    new_users: pillarModels(pillar: $newUsers) {
      name
      __typename
    }
  }
`;
