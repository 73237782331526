import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';

@Component({
  selector: 'app-scenario-diff-dialog',
  templateUrl: './scenario-diff-dialog.component.html',
  styleUrls: ['./scenario-diff-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScenarioDiffDialogComponent implements OnInit {

  constructor(@Inject(DIALOG_DATA) public data, public dialogRef: DialogRef<any>,) {
  }

  ngOnInit(): void {
  }

  get keysWithValues() {
    return Object.keys(this.data).filter(k => this.data[k] && this.data[k].length > 0);
  }

}
