<div data-cy="confirmation-modal"
  class="flex flex-col relative z-50 w-full box-border bg-white p-6 max-w-md shadow-small overflow-y-hidden font-body rounded-md">
  <div class="space-y-4">
    <div class="flex items-center justify-between gap-6">
      <h2 class="font-medium text-lg flex w-full align-center" data-cy="confirmation-modal-heading-text">
        @if (dialogData?.icon?.length > 0) {
          <app-svg-images [svgName]="dialogData.icon" class="h-6 w-6 inline-block mr-2"></app-svg-images>
        }
        <span class="inline-block">
          {{ dialogData.title }}
        </span>
      </h2>
      <app-close-button (click)="dialogRef.close()" data-cy="confirmation-modal-x-button"></app-close-button>
    </div>
    <div class="space-y-1.5">
      <div data-cy="confirmation-modal-body-text" [innerHTML]="dialogData.message"></div>
    </div>
    <div class="flex items-center justify-end gap-8">
      <button data-cy="confirmation-modal-cancel-button" type="button"
        class="font-medium hover:underline active text-sm" translate="global.cancel" (click)="dialogRef.close()">
      </button>
      <app-button data-cy="confirmation-modal-yes-button" variant="primary" type="button"
        cdkFocusInitial
        (click)="dialogRef.close(true)">
        {{ dialogData.action || 'Yes' }}
      </app-button>
    </div>
  </div>
</div>