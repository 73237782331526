<div class="flex text-black">
  <div class="relative bg-white border border-gray-300 rounded-l-lg">
    <div class="absolute top-0 bottom-0 left-3 flex items-center">
      <span class="h-4 text-gray-400">
        <app-svg-images svgName="magnifying-glass"></app-svg-images>
      </span>
    </div>
    <label [for]="identifier">
      <input [id]="identifier"
        [placeholder]="placeholderText"
        [name]="identifier"
        type="text"
        [(ngModel)]="value"
        (input)="onSearchChange(value)"
        class="rounded-l-md py-2 rounded-r-none pl-9 pr-4 text-sm focus:outline-background" />
    </label>
  </div>
  <app-dropdown-menu menuPosition="bottom-right">
    <div
      buttonContent
      class="h-full px-2.5 py-2 rounded-r-lg flex gap-2.5 items-center bg-gray-50 border border-gray-300 hover:bg-gray-50 border-l-0 cursor-pointer"
      >
      <div class="h-4 text-gray-400">
        <app-svg-images svgName="bars-arrow-down"></app-svg-images>
      </div>
      <span class="text-sm" translate="global.sort"></span>
      <div class="h-4 text-gray-400">
        <app-svg-images svgName="chevron-down"></app-svg-images>
      </div>
    </div>

    <div menuItems class="text-xs font-medium -m-1">
      <h3 menuItem class="text-gray-500 px-2 pb-2 text-sm" translate="milestones.filters.sortBy"></h3>
      @for (item of sortItems; track item) {
        <button
          class="flex items-center justify-between space-x-6 text-black p-2 rounded hover:bg-soft-blue w-full active text-left text-sm min-w-[150px]"
          (click)="onSortSelect(item)">
          <span class="flex-1">{{ item }}</span>
          @if ((selectedSort | lowercase) === (item | lowercase)) {
            <span class="h-3">
              <app-svg-images [svgName]="direction === 'asc' ? 'arrow-up' : 'arrow-down'"></app-svg-images>
            </span>
          }
        </button>
      }
    </div>
  </app-dropdown-menu>
</div>
