import { NgControl } from '@angular/forms';
import { Directive, Input, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[disableControl]',
  exportAs: 'isControlDisabled'
})
export class DisableControlDirective implements OnChanges {

  @Input() disableControl;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.disableControl) {
      const action = this.disableControl ? 'disable' : 'enable';
      this.control.control[action]({emitEvent: false});
      if (action === 'enable') return;
      if (this.control.name === 'curve') {
        // if it's disabled we know it's monthly/linear so we set it to linear
        this.control.control.patchValue('LINEAR', {emitEvent: false});
      }
    }
  }

  constructor(private control: NgControl) {
  }

}
