import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Pipe({
  name: 'weekdaysTranslate'
})
export class WeekdaysTranslatePipe implements PipeTransform {

  constructor(private translateService: TranslateService) {}

  transform(value: string | string[], ...args: unknown[]): unknown {
    return typeof value === 'string' ?  this.translateService.instant(value) : value.map(day => this.translateService.instant(day)).join(', ');
  }

}
