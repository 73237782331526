<div
  class="rounded-lg border overflow-hidden text-sm bg-white h-[300px] min-w-[300px] overflow-y-scroll shadow-inner"
  [ngClass]="{'border-red-500': invalid}"
>
  <div class="sticky top-0 bg-white z-10 border-b">
    <div class="relative">
      <input
        type="text"
        [(ngModel)]="text"
        (keydown.escape)="clearFilter()"
        class="w-full p-3 pr-5 shadow-inner rounded-t-lg"
        placeholder="Search segments"
      />
      <div class="absolute top-0 bottom-0 right-2.5 flex items-center">
        <button
          type="button"
          class="h-4 active"
          (click)="clearFilter()"
          [ngClass]="{
                        'pointer-events-none': !text,
                    }"
        >
          <app-svg-images
            [svgName]="text ? 'x-mark' : 'magnifying-glass'"
            class="h-4"
          />
        </button>
      </div>
    </div>
  </div>
  <div class="divide-y divide-gray-100">
    @for (segment of filteredSegments; track segment.id) {
      <div
        (click)="toggleSegment(segment)"
        class="data-[selected=true]:bg-digital-blue/5 data-[selected=true]:text-digital-blue pl-3 pr-1 hover:bg-digital-blue/5 hover:text-digital-blue cursor-pointer active flex items-center gap-1 group relative"
        [attr.data-selected]="isSelected(segment.id)"
      >
        <div
          class="group-data-[selected=true]:block hidden absolute left-0 top-0 bottom-0 w-0.5 bg-digital-blue fade-in-right"
        ></div>
        <label
          class="relative max-w-fit inline-flex items-center justify-start cursor-pointer tap-highlight-transparent p-2 -m-2"
        >
          <span
            class="relative inline-flex items-center justify-center flex-shrink-0 overflow-hidden before:content-[''] before:absolute before:inset-0 before:border-solid before:border-2 before:box-border before:border-default after:content-[''] after:absolute after:inset-0 after:scale-50 after:opacity-0 after:origin-center group-data-[selected=true]:after:scale-100 group-data-[selected=true]:after:opacity-100 group-data-[hover=true]:before:bg-default-100 outline-none group-data-[focus-visible=true]:z-10 group-data-[focus-visible=true]:ring-2 group-data-[focus-visible=true]:ring-focus group-data-[focus-visible=true]:ring-offset-2 group-data-[focus-visible=true]:ring-offset-background after:bg-digital-blue after:text-white text-white w-4 h-4 mr-2 rounded-md before:rounded-md after:rounded-md before:transition-colors group-data-[pressed=true]:scale-95 transition-transform after:transition-transform-opacity after:!ease-linear after:!duration-200 motion-reduce:transition-none"
          >
            <svg
              role="presentation"
              viewBox="0 0 17 18"
              class="z-10 opacity-0 group-data-[selected=true]:opacity-100 w-3 h-2 transition-opacity motion-reduce:transition-none"
            >
              <polyline
                fill="none"
                points="1 9 7 14 15 4"
                stroke="currentColor"
                stroke-dasharray="22"
                stroke-dashoffset="44"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                style="transition: stroke-dashoffset 250ms linear 0.2s"
              ></polyline>
            </svg>
          </span>
        </label>
        <div class="flex-1 py-2.5 font-medium">{{ segment.name }}</div>
      </div>
    }
  </div>
</div>
