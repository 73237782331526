import { Component } from "@angular/core";
import { SvgImagesComponent } from "../ui/svg-images/svg-images.component";

@Component({
    selector: 'app-loading',
    template: `
      <div class="absolute inset-0 z-10 flex flex-col items-center bg-gradient-to-br from-slate-900 to-slate-950 dark text-slate-800 vh-100 w-100 transition-all">
        <div class="flex items-center justify-center mt-[40vh] p-20 relative">
            <div class="absolute inset-0 flex items-center justify-center">
                <app-svg-images svgName="spinner" class="h-24 w-24"></app-svg-images>
            </div>
            <div>
                <img src="/assets/images/logo-small-dark.png" class="h-5 dark:hidden">
                <img src="/assets/images/logo-small.png" class="h-5 hidden dark:block">
            </div>
        </div>
      </div>
    `,
    standalone: true,
    imports: [SvgImagesComponent]
  })
  export class LoadingComponent {}