<div class="flex items-center justify-between w-full active rounded-lg font-normal group-[.is-active]/route:font-medium text-base text-white/70 gap-2 group-[.is-active]/route:bg-white/10 group-[.is-active]/route:text-white group-hover/menu:!bg-white group-hover/menu:!text-black"
  [ngClass]="{
    'py-2 px-3' : isScenarioNavGroupItem, 
    'py-2.5 px-3.5' : !isScenarioNavGroupItem}">
  <div class="flex items-center gap-4">
    <ng-content></ng-content>
  </div>
  <div class="flex items-center space-x-1.5 justify-self-end">
    <ng-content select="[navListGroupTotalItems]"></ng-content>
    <app-svg-images svgName="chevron-right" class="h-3.5 group-hover/route:translate-x-1 transition-all"></app-svg-images>
  </div>
</div>
