<app-product-header>
    <ng-content></ng-content>
  @if(scenario$ | async; as scenario) {
    <div class="flex items-center justify-end gap-2">
        <app-overlay-popup>
            <button type="button" data-cy="favourite" data-app-popper="trigger" (click)="onFavouriteClick(scenario)"
                [disabled]="(isFavouritesLimit$ | async) && !scenario.favourite"
                [ngClass]="{'text-kohort-primary': scenario.favourite}"
                class="rounded-lg h-10 w-10 active transition-all border dark:border-transparent shadow-sm bg-white hover:bg-soft-blue dark:hover:bg-slate-700 dark:bg-slate-800 flex items-center justify-center">
                <app-svg-images class="h-4.5" [svgName]="scenario.favourite ? 'heart' : 'heart-outline'"></app-svg-images>
            </button>
            <div popup-content
                 class="max-w-[300px] z-100" 
                 [ngClass]="{
                    'hidden' : !((isFavouritesLimit$ | async) && !scenario.favourite)
                }" >
                <div class="bg-white p-2 space-y-1 border shadow-sm rounded-md text-black">
                    <p>{{ 'scenario.favourite.limitReached' | translate }}</p>
                </div>
            </div>
        </app-overlay-popup>
        <app-dropdown-menu>
            <button buttonContent type="button" data-cy="actions-dropdown-menu" class="rounded-lg h-10 w-10 active transition-all border dark:border-transparent shadow-sm bg-white hover:bg-soft-blue dark:hover:bg-slate-700 dark:bg-slate-800 flex items-center justify-center">
                <app-svg-images class="h-4.5 block" svgName="ellipsis-vertical"></app-svg-images>
            </button>
            <div menuItems class="-m-1.5 text-sm font-medium min-w-[180px]">
                <app-dropdown-menu-item data-cy="lock-scenario" (onClick)="toggleLocked(scenario)" [icon]="scenario.locked ? 'lock-open' : 'lock-closed'">
                    {{ (scenario.locked ? 'scenario.actions.unlock' : 'scenario.actions.lock') | translate}}
                  </app-dropdown-menu-item>
                <app-dropdown-menu-item data-cy="reports-visibility" (onClick)="toggleReportVisibility(scenario)" [icon]="scenario.includeInReports ? 'eye-slash' : 'eye'">
                    {{ (scenario.includeInReports ? 'scenario.actions.visibility.hide' : 'scenario.actions.visibility.show') | translate}}
                </app-dropdown-menu-item>
                <div class="border-t space-y-1 pt-1 border-gray-100">
                    <app-dropdown-menu-item data-cy="duplicate-scenario" (onClick)="duplicateScenario(scenario)" icon="document-duplicate">
                        {{ 'scenario.actions.duplicate' | translate}}
                    </app-dropdown-menu-item>
                    @if (!scenario.budget && !scenario.archived) {
                      <app-dropdown-menu-item data-cy="budget-scenario" (onClick)="setAsBudget(scenario)" icon="currency-dollar" [isDisabled]="scenario.default || scenario.internal">
                          {{ 'scenario.actions.setAsBudget' | translate }}
                      </app-dropdown-menu-item>
                    }
                    <app-dropdown-menu-item data-cy="archive-scenario" (onClick)="archiveScenario(scenario)" icon="archive-box" [isDisabled]="scenario.default || scenario.budget">
                        {{ (scenario.archived ? 'scenario.actions.unarchive' : 'scenario.actions.archive') | translate}}
                    </app-dropdown-menu-item>
                    <app-dropdown-menu-item data-cy="delete-scenario" (onClick)="deleteScenario(scenario)" icon="trash" state="danger">
                        {{ 'scenario.actions.delete' | translate}}
                    </app-dropdown-menu-item>
                </div>
            </div>
        </app-dropdown-menu>
    </div>
  }
</app-product-header>