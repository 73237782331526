import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { ScenarioSegment } from 'src/app/views/scenario/model/scenario-segments.model';
import { Product} from '../../views/product/model/product.model';
import { IGraphQLParams } from 'src/app/graphql/graphql.models';

export const loadProductSegments = createAction(
  '[Product] Load Product Segments',
  props<{ productId: number }>()
);

export const loadProductSegmentsSuccess = createAction(
  '[Product] Load Product Segments Success',
  props<{ segments: ScenarioSegment[] }>()
);

export const fetchProduct = createAction(
  '[Product/API] Fetch product',
  props<{ id: number }>()
);

export const fetchProducts = createAction(
  '[Product/API] Fetch products',
  props<{ paginationParams: IGraphQLParams }>()
);

export const addProduct = createAction(
  '[Product/API] Add Product',
  props<{ product: Product }>()
);

export const upsertProduct = createAction(
  '[Product/API] Upsert Product',
  props<{ product: Product }>()
);

export const loadProducts = createAction(
  '[Product/API] Load products',
  props<{ products: Product[], totalCount?: number;  }>()
);

export const addProducts = createAction(
  '[Product/API] Add products',
  props<{ products: Product[] }>()
);

export const upsertProducts = createAction(
  '[Product/API] Upsert products',
  props<{ products: Product[], totalCount?: number;  }>()
);

export const updateProduct = createAction(
  '[Product/API] Update Product',
  props<{ product: Update<Product> }>()
);

export const updateProducts = createAction(
  '[Product/API] Update products',
  props<{ products: Update<Product>[] }>()
);

export const deleteProductById = createAction(
  '[Product/API] Delete Product By ID',
  props<{ id: number }>()
);

export const deleteProducts = createAction(
  '[Product/API] Delete products',
  props<{ ids: number[] }>()
);

export const deleteProduct = createAction(
  '[Product/API] Delete Product',
  props<{ product: Product }>()
);

export const clearProducts = createAction(
  '[Product/API] Clear Products'
);