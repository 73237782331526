import {Inject, Injectable, Optional, PLATFORM_ID, Renderer2, RendererFactory2, ViewEncapsulation} from '@angular/core';
import {INTERCOM_CONFIG, IntercomConfig} from './intercom.config';
import {NavigationEnd, Router} from '@angular/router';
import {DOCUMENT, isPlatformBrowser} from '@angular/common';
import {filter} from 'rxjs/operators';
import {BootConfig} from './boot-config';
import { HelpersService } from '../services/helpers.service';

@Injectable()
export class Intercom {

  private id: string;
  private renderer2: Renderer2;

  constructor(
    @Inject(INTERCOM_CONFIG) private config: IntercomConfig,
    @Inject(PLATFORM_ID) protected platformId: object,
    @Optional() @Inject(Router) private router: Router,
    private rendererFactory: RendererFactory2,
    @Optional() @Inject(DOCUMENT) private document: any, // Document
    private helpersService: HelpersService,
  ){
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    this.renderer2 = this.rendererFactory.createRenderer(this.document, {
      id: '-1',
      encapsulation: ViewEncapsulation.None,
      styles: [],
      data: {}
    });

    // Subscribe to router changes
    if (config && config.updateOnRouterChange) {
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)
      ).subscribe(event => {
        this.update();
      });
    }
  }

  public boot(intercomData?: BootConfig): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    if (!this.config.enabled) {
      return;
    }
    const appId = intercomData?.app_id ? intercomData.app_id : this.config.appId;

    this.loadIntercom(this.config, (event?: Event) => {
      const data = {
        ...intercomData,
        app_id: appId
      };

      return this._callIntercom('boot', data);
    });
  }

  public shutdown(): void {
    return this._callIntercom('shutdown');
  }

  public update(data?: any): void {
    return this._callIntercom('update', data);
  }

  public hide(): void {
    return this._callIntercom('hide');
  }

  public show(message?: string): void {
    if (message) {
      return this.showNewMessage(message);
    }
    return this._callIntercom('show');

  }

  public showMessages(): void {
    return this._callIntercom('showMessages');
  }

  public showNewMessage(message?: string): void {
    return this._callIntercom('showNewMessage', message);

  }

  public trackEvent(eventName: string, metadata?: any): void {
    return this._callIntercom('trackEvent', eventName, metadata);
  }

  public getVisitorId(): string {
    return this._callIntercom('getVisitorId');
  }

  get visitorId(): string {
    return this._callIntercom('getVisitorId');
  }

  public onShow(handler: () => void): void {
    return this._callIntercom('onShow', handler);
  }

  public onHide(handler: () => void): void {
    return this._callIntercom('onHide', handler);
  }

  public onUnreadCountChange(handler: (unreadCount?: number) => void): void {
    return this._callIntercom('onUnreadCountChange', handler);
  }

  public startTour(tourId: number): void {
    return this._callIntercom('startTour', tourId);
  }

  private _callIntercom(fn: string, ...args) {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    if (!this.config.enabled) {
      return;
    }
    if ((window as any).Intercom) {
      return (window as any).Intercom(fn, ...args);
    }
    return;
  }

  injectIntercomScript(conf: IntercomConfig, afterInjectCallback: (ev: Event) => any): void {

    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    // Set the window configuration to conf
    (window as any).intercomSettings = {
      app_id: conf.appId,
      ...conf
    };

    // Create the intercom script in document
    const s = this.document.createElement('script');
    s.type = 'text/javascript';
    s.async = true;
    s.src = `https://widget.intercom.io/widget/${this.id}`;

    if ((s as any).attachEvent) {
      (s as any).attachEvent('onload', afterInjectCallback);
    } else {
      s.addEventListener('load', afterInjectCallback, false);
    }

    if (this.renderer2 && this.renderer2.appendChild) {
      this.renderer2.appendChild(this.document.head, s);
    }

    (window as any).Intercom('update', conf);
  }

  loadIntercom(config: IntercomConfig, afterLoadCallback: (ev?: Event) => any): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    this.id = config.appId;
    const w = window as any;
    const ic = w.Intercom;

    // Set window config for Intercom
    w.intercomSettings = config;

    if (typeof ic === 'function') {
      ic('reattach_activator');
      ic('update', config);
      afterLoadCallback();
    } else {
      const i: any = () => {
        i.c(arguments);
      };
      i.q = [];
      i.c = (args: any) => {
        i.q.push(args);
      };
      w.Intercom = i;
      this.injectIntercomScript(config, afterLoadCallback);
    }

  }
}
