import { createFeature, createReducer, on } from '@ngrx/store';
import { ReportingActions } from '../actions/reporting.actions';
import { Helpers } from "../../core/shared/helpers";
import { INCLUDED_FILTERS } from "../../core/app.constants";

export const reportingFeatureKey = 'reporting';

export interface Dashboard {
  id: number
  title: string
  viewCount: number
}

export interface State {
  reports: Dashboard[]
  personal: Dashboard[]
  filters: object
  loading: boolean
}

export const initialState: State = {
  reports: [],
  personal: [],
  loading: false,
  filters: {}
};

export const reducer = createReducer(
  initialState,
  on(ReportingActions.loadReports, state => {
    return {
      ...state,
      loading: true
    }
  }),
  on(ReportingActions.updateFilters, (state, action) => {
    const filters = Helpers.pluckKeys(INCLUDED_FILTERS, {...state.filters, ...action.filters});
    return {
      ...state,
      filters
    }
  }),
  on(ReportingActions.resetFilters, (state, action) => {
    return {
      ...state,
      filters: {}
    }
  }),
  on(ReportingActions.loadReportsSuccess, (state, action) => {
    return {
      ...state,
      reports: action.group,
      personal: action.personal,
      loading: false
    }
  }),
  on(ReportingActions.loadReportsFailure, (state, action) => {
    return {
      ...state,
      loading: false
    }
  }),
);

export const reportingFeature = createFeature({
  name: reportingFeatureKey,
  reducer,
});

