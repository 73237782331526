import {createFeatureSelector, createSelector} from '@ngrx/store';
import { activitiesFeatureKey, ActivityState, selectAll } from './activity.reducer';
import { getAuthUser } from 'src/app/views/user-management/redux/selectors';

export const selectActivityState = createFeatureSelector<ActivityState>(
  activitiesFeatureKey
);

export const selectActivitySideBar = createSelector(
  selectActivityState,
  (state) => state.isSidebarOpen
);

export const selectAllActivities = createSelector(
  selectActivityState, selectAll,
);

export const selectMyActivities = createSelector(
  getAuthUser, selectAllActivities,
  (user, allActivities) => allActivities.filter(item => item.actionedBy === user?.name)
);

export const selectOthersActivities = createSelector(
  getAuthUser, selectAllActivities,
  (user, allActivities) => allActivities.filter(item => {
    return item.actionedBy !== user?.name;
  }) 
);

export const selectUnread = createSelector(
  getAuthUser, selectAllActivities,
  (user, allActivities) => allActivities.filter(item => (item.read === false) && (item.actionedBy === user?.name)) 
);
