import { ConnectedPosition } from '@angular/cdk/overlay';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, signal } from '@angular/core';
import { firstValueFrom, map, timer } from 'rxjs';

@Component({
  selector: 'app-overlay-popup',
  templateUrl: './overlay-popup.component.html',
  styles: `:host { display: inline-flex; }`,
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class OverlayPopupComponent {
  @Input() label: string = '';
  @Input() enableClick: boolean = false;
  @Input() isCustom: boolean = false;
  @Output() closed: EventEmitter<boolean> = new EventEmitter<boolean>();

  private closeDelay = 100;
  private isHoveringContent = signal(false);
  private isHoveringMenu = signal(false);
  public isOpenState = signal(false);

  public toolTipPosition: ConnectedPosition[] = [
    { // top center
      originX: 'center',
      originY: 'top',
      overlayX: 'center',
      overlayY: 'bottom'
    },
    { // bottom center
      originX: 'center',
      originY: 'bottom',
      overlayX: 'center',
      overlayY: 'top'
    },
    { // top right
      originX: 'end', 
      originY: 'top',
      overlayX: 'end', 
      overlayY: 'bottom' 
    },
    { // bottom right
      originX: 'end', 
      originY: 'bottom', 
      overlayX: 'end', 
      overlayY: 'top' 
    }
  ];

  public popupPosition: ConnectedPosition[] = [
    { // right center
      originX: 'end', 
      originY: 'center',
      overlayX: 'start', 
      overlayY: 'center',
      offsetX: 10
    },    
    { // left center
      originX: 'start',
      originY: 'center',
      overlayX: 'end',
      overlayY: 'center',
      offsetX: -10
    },
    { // right top
      originX: 'end', 
      originY: 'top',
      overlayX: 'start', 
      overlayY: 'top' 
    }, 
    { // right bottom
      originX: 'end', 
      originY: 'bottom',
      overlayX: 'start', 
      overlayY: 'bottom' 
    }, 
    { // left top
      originX: 'start', 
      originY: 'top',
      overlayX: 'end', 
      overlayY: 'top' 
    }, 
    { // left bottom
      originX: 'start', 
      originY: 'bottom',
      overlayX: 'start', 
      overlayY: 'top' 
    }
  ];
   
  async toggleNavGroup(isHovering: boolean): Promise<void> {
    if (this.enableClick) return;
    if (isHovering) {
      this.isHoveringMenu.set(true);
      this.openMenu();
      return;
    } 

      const close = await firstValueFrom(timer(this.closeDelay).pipe(
        map(() => !this.isHoveringContent() && !isHovering)
      ))
      if (close) {
        this.closeMenu();
      }
  }

  openMenu(): void {
    this.isOpenState.set(true);
  }

  closeMenu(): void {
    this.isOpenState.set(false);
    this.closed.emit(true);
  }

  toggleGroup() {
    this.isOpenState.set(!this.isOpenState());
   }

  onMouseEnterMenu(): void {
    if (this.enableClick) return;
    this.isHoveringContent.set(true);
    this.isHoveringMenu.set(false);
    this.openMenu();
  }

  async onMouseLeaveMenu(): Promise<void> {
    if (this.enableClick) return;
    this.isHoveringContent.set(false);
    const close = await firstValueFrom(timer(this.closeDelay).pipe(
      map(() => !this.isHoveringMenu())
    ))

    if (close) {
      this.closeMenu();
    }
  }
}
