import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[customTooltip]',
  exportAs: 'isToolTipDisabled'
})
export class CustomTooltipDirective {

  @Input('customTooltip') isToolTipDisabled = true;

  constructor(private el: ElementRef) {}

  @HostListener('mouseover') onMouseOver() {
    this.checkTextLength();
  }
  @HostListener('mouseleave') onMouseLeave() {
    this.isToolTipDisabled = true;
  }
  
  private checkTextLength(): void {
    const tooltipElement = this.el.nativeElement;
    const tooltipWidth = tooltipElement.offsetWidth;
    const textWidth = tooltipElement.querySelector('[data-custom-tooltip-text]')?.offsetWidth;
    if(textWidth > tooltipWidth) {
      this.isToolTipDisabled = false;
    }
  }
}
