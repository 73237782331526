export interface IDay {
  name: string;
  id: number;
}

export interface IHour {
  hour: string;
  id: number;
}

export const DAYS: IDay[] = [
  { name: 'mon', id: 0 },
  { name: 'tue', id: 1 },
  { name: 'wed', id: 2 },
  { name: 'thu', id: 3 },
  { name: 'fri', id: 4 },
  { name: 'sat', id: 5 },
  { name: 'sun', id: 6 },
];

export const TIME: IHour[] = [
  { hour: '00:00 - 01:00', id: 0 },
  { hour: '01:00 - 02:00', id: 1 },
  { hour: '02:00 - 03:00', id: 2 },
  { hour: '03:00 - 04:00', id: 3 },
  { hour: '04:00 - 05:00', id: 4 },
  { hour: '05:00 - 06:00', id: 5 },
  { hour: '06:00 - 07:00', id: 6 },
  { hour: '07:00 - 08:00', id: 7 },
  { hour: '08:00 - 09:00', id: 8 },
  { hour: '09:00 - 10:00', id: 9 },
  { hour: '10:00 - 11:00', id: 10 },
  { hour: '11:00 - 12:00', id: 11 },
  { hour: '12:00 - 13:00', id: 12 },
  { hour: '13:00 - 14:00', id: 13 },
  { hour: '14:00 - 15:00', id: 14 },
  { hour: '15:00 - 16:00', id: 15 },
  { hour: '16:00 - 17:00', id: 16 },
  { hour: '17:00 - 18:00', id: 17 },
  { hour: '18:00 - 19:00', id: 18 },
  { hour: '19:00 - 20:00', id: 19 },
  { hour: '20:00 - 21:00', id: 20 },
  { hour: '21:00 - 22:00', id: 21 },
  { hour: '22:00 - 23:00', id: 22 },
  { hour: '23:00 - 00:00', id: 23 },
];
