@if (segmentParts(); as segment) {
  <div
    class="text-sm inline-flex border rounded-md hover:shadow-sm divide-x bg-gray-50/70 divide-gray-100 overflow-hidden border-gray-300">
    <div class="py-1.5 px-2 flex items-center last:bg-white gap-1.5 font-medium">
      @if (segment.country) {
        <span [class]="segment.country | flag"></span>
      }
      {{segment.country}}
    </div>
    <div
      class="py-1.5 px-2 flex items-center last:bg-white gap-1.5 font-medium">
      {{segment.platform}}
    </div>
    @for (other of segment.other; track other) {
      <div data-cy="other-segment-part"
        class="py-1.5 px-2 flex items-center last:bg-white gap-1.5 font-medium">{{other}}</div>
    }
  </div>
}