import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { Component, Input,signal } from '@angular/core';

@Component({
  selector: 'app-dropdown-menu',
  templateUrl: './dropdown-menu.component.html',
  standalone: true,
  imports: [CommonModule, OverlayModule],
  styles: `:host { display: inline-block; }`
})

export class DropdownMenuComponent {
  @Input() menuPosition: 'top-right' | 'top-left' | 'bottom-right' | 'bottom-left' = 'bottom-right';
  
  public open = signal(false);
  
  toggleMenu() {
    this.open.set(!this.open());
  }
  
  closeMenu() {
    this.open.set(false);
  }

  getMenuPosition() {
    return {
      'top-right': {
        originX: 'end',
        originY: 'top',
        overlayX: 'end',
        overlayY: 'bottom'
      },
      'top-left': {
        originX: 'start',
        originY: 'top',
        overlayX: 'start',
        overlayY: 'bottom'
      },
      'bottom-right': {
        originX: 'end',
        originY: 'bottom',
        overlayX: 'end',
        overlayY: 'top'
      },
      'bottom-left': {
        originX: 'start',
        originY: 'bottom',
        overlayX: 'start',
        overlayY: 'top'
      },
    }[this.menuPosition];
  }
}
