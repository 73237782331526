import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgImagesComponent } from '../../svg-images/svg-images.component';

@Component({
  selector: 'app-dropdown-menu-item',
  standalone: true,
  imports: [CommonModule, SvgImagesComponent],
  templateUrl: './dropdown-menu-item.component.html'
})
export class DropdownMenuItemComponent {
  @Input() state: 'default' | 'danger' | 'warning' = 'default';
  @Input() isDisabled: boolean = false;
  @Input() icon: string = '';
  @Output() onClick = new EventEmitter<void>();

  handleClick() {
    this.onClick.emit();
  }
}
