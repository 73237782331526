import { IPaginatedDto } from '../core/model/app.model';
import { ApolloQueryResult } from '@apollo/client/core';

export interface GraphQLResponse<T> {
  [key: string]: T;
}

export interface GraphQLEdgesResponse<T> {
  totalCount?: number;
  results: T[]
  loading?: boolean;
}

export interface IGraphQLParams {
  page?: number;
  pageSize?: number;
  sort?: string[]
  filter?: { [key: string]: any }
}

export class GraphQLParams implements IGraphQLParams {
  page = 1;
  pageSize = 20;
  sort = []
  filter = null

  static toIPaginatedDto<T>(response: ApolloQueryResult<any>, key: string): IPaginatedDto<T> {
    return {
      total: response.data[key].totalCount,
      results: response.data[key].edges.map(node => node.node)
    }
  }
}
