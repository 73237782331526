import {EventsState, IEventSegment, IEventType, IMultipliers} from './events.model';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Scenario} from '../../scenario/model/scenario.model';
import {BaseEventsComponent} from '../components/base-events/base-events.component';

export interface IEventsDto {
  dates: (Date | string)[];
  eventType: IEventType;
  multipliers: IMultipliers;
  name: string;
  scenarioId: number;
  segments: IEventSegment[];
  excluded: boolean;
  id: number;
  created_at?: string;
  updated_at?: string;
}

export interface ICreateScenarioEventDto {
  dates: string[];
  eventType: string;
  multipliers: IMultipliers;
  name: string;
  segmentIds: string[];
}

export class CreateScenarioEventDto implements ICreateScenarioEventDto {
  constructor(
    public dates: string[],
    public eventType: string,
    public multipliers: IMultipliers,
    public name: string,
    public segmentIds: string[]
  ) {
  }

  static toEvent(value: any): CreateScenarioEventDto {
    const dates = Array.isArray(value.dates) ? value.dates : BaseEventsComponent.getEventDates(value.dates);
    return {
      ...value,
      dates,
      segmentIds: value.segments,
      multipliers: (value.multipliers && ({
        newUsers: value.multipliers.newUsers ?? null,
        monetisation: value.multipliers.monetisation ?? null,
        retention: value.multipliers.retention ?? null,
      }))
    };
  }

  static toCreateEventDto(value: any): CreateScenarioEventDto {
    const dates = Array.isArray(value.dates) ? value.dates : BaseEventsComponent.getEventDates(value.dates);
    return new CreateScenarioEventDto(
      dates,
      value.eventType,
      value.multipliers,
      value.name,
      value.segments
    );
  }

  static toFormGroup(event, scenario: Scenario, state: EventsState) {
    const segmentIds = event.segments.map((segment: IEventSegment) => segment.id);
    const {multipliers} = event;
    const [start, end] = event.dates;
    const eventsStateMultiplier = (state: EventsState): number | null => state === EventsState.PAST ? 0 : null;
    const multiplierDefault = eventsStateMultiplier(state);

    const formGroup = new UntypedFormGroup({
      dates: new UntypedFormGroup({
        start: new UntypedFormControl(start, Validators.required),
        end: new UntypedFormControl(end, Validators.required),
      }),
      excluded: new UntypedFormControl(event.excluded),
      name: new UntypedFormControl(event.name?.trim(), Validators.required),
      eventType: new UntypedFormControl(event.eventType.name, Validators.required),
      segments: new UntypedFormControl(segmentIds, Validators.required),
      id: new UntypedFormControl(event.id),
      scenarioId: new UntypedFormControl(event.scenarioId),
      multipliers: new UntypedFormGroup({
        monetisation: new UntypedFormControl(multipliers.monetisation ?? multiplierDefault, [Validators.pattern(/^[0-9.-]*$/)]),
        newUsers: new UntypedFormControl(multipliers.newUsers ?? multiplierDefault, [Validators.pattern(/^[0-9.-]*$/)]),
        retention: new UntypedFormControl(multipliers.retention ?? multiplierDefault, [Validators.pattern(/^[0-9.-]*$/)]),
      })
    });
    if ((scenario.id !== event.scenarioId) || scenario.locked) {
      formGroup.disable();
    }
    return formGroup;
  }
}
