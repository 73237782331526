import { Component, EventEmitter, Input, Output } from '@angular/core';
import { USER_MANAGEMENT_ROUTE } from 'src/app/core/app.constants';
import { IOrganisation } from 'src/app/views/user-management/model/user-management.model';

@Component({
  selector: 'app-organisation-menu',
  templateUrl: './organisation-menu.component.html'
})
export class OrganisationMenuComponent {
  @Input() userOrganisations: IOrganisation[];
  @Input() currentOrganisation: IOrganisation;
  @Output() switchOrganisation: EventEmitter<IOrganisation> = new EventEmitter<IOrganisation>();

  public userManagementRoute = USER_MANAGEMENT_ROUTE;
  
  constructor() { }

  switchOrganisationEvent(org: IOrganisation) {
    this.switchOrganisation.emit(org);
  }
}
