import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {

  url$ = new BehaviorSubject<string>(null);

  constructor(
    private router: Router
  ) {}

  build() {
    this.url$.next(this.router.url);
  }
}
