import { Component, OnInit } from '@angular/core';
import {LoadingService} from '../../services/loading.service';

@Component({
  selector: 'app-navigation-loader',
  templateUrl: './navigation-loader.component.html',
  styleUrls: ['./navigation-loader.component.scss']
})
export class NavigationLoaderComponent implements OnInit {

  constructor(private loader: LoadingService) { }

  get value$() {
    return this.loader.value$;
  }

  ngOnInit(): void {
  }

}
