import { Component, ElementRef, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent {

  @Input() length: number;

  @ViewChild('value', {static: true}) value: ElementRef;

  constructor() {
  }

  get hasTooltip(): boolean {
    return this.value.nativeElement.innerText.length > this.length;
  }

  get content(): string {
    return this.value.nativeElement.innerText;
  }

}
