<label
  [ngClass]="{'border-digital-blue ring-1 ring-digital-blue bg-white text-digital-blue': active,
'bg-gray-50': !active}"
  class="h-full block p-4.5 pt-3.5 border-solid rounded-lg border text-sm space-y-2 active cursor-pointer transition-all hover:shadow-sm hover:border-digital-blue hover:ring-1 hover:ring-digital-blue">
  <div class="flex items-center gap-4 text-base">
    <ng-content></ng-content>
  </div>
  <p class="text-gray-500 pl-8">
    <ng-content select="[description]"/>
  </p>
</label>
