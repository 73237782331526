import {AbstractControl, UntypedFormArray, UntypedFormBuilder, Validators} from '@angular/forms';
import {DateRange} from '@angular/material/datepicker';
import moment from 'moment';
import {VALID_PANDAS_DATE_RANGE} from 'src/app/core/app.constants';
import {IPandasDateRange} from 'src/app/core/config/models/config';
import {CreateScenarioEventDto} from '../../model/events-create-dto.model';
import {EventsState} from '../../model/events.model';
import { Helpers } from '../../../../core/shared/helpers';
import { OnDestroy } from 'src/app/core/shared/rxjs-helpers';

export class BaseEventsComponent extends OnDestroy {

  constructor(
    protected formBuilder: UntypedFormBuilder,
  ) {
    super();
  }

  form: UntypedFormArray;
  customMaxDateLimit: IPandasDateRange;

  static getEventDates(dates: DateRange<moment.Moment>): string[] {
    const {start, end} = dates;
    const startDate = start ? typeof start === 'string' ? start : start.format('YYYY-MM-DD') : null;
    const endDate = end ? typeof end === 'string' ? end : end.format('YYYY-MM-DD') : null;
    return [startDate, endDate].filter((date) => Boolean(date));
  }

  protected getCustomSegments(formValueSegments: string[]): string[] {
    return Helpers.getCustomSegments(formValueSegments);
  }

  protected createScenarioEvent({dates, name, eventType, segments, multipliers}): CreateScenarioEventDto {
    const dateRange = Array.isArray(dates) ? dates : BaseEventsComponent.getEventDates(dates);
    return new CreateScenarioEventDto(
      dateRange,
      eventType,
      multipliers,
      name,
      segments
    );
  }

  protected createEvent({dates, name, eventType, segments, id = null, multipliers, excluded, scenarioId = null}): AbstractControl {
    const {start, end} = dates;
    return this.formBuilder.group({
      dates: this.formBuilder.group({
        start: [start, Validators.required],
        end: [end, Validators.required],
      }),
      excluded: [excluded],
      name: [name?.trim(), Validators.required],
      eventType: [eventType, Validators.required],
      segments: [segments, Validators.required],
      id: [id],
      scenarioId: [scenarioId],
      multipliers: this.formBuilder.group({
        monetisation: [multipliers.monetisation ?? null, [Validators.min(-100), Validators.max(100)]],
        newUsers: [multipliers.newUsers ?? null, [Validators.min(-100), Validators.max(100)]],
        retention: [multipliers.retention ?? null, [Validators.min(-100), Validators.max(100)]],
      })
    });
  }

  protected getCustomMaxDate(endDate: Date): IPandasDateRange {

    // @ts-ignore
    if (this.eventState === EventsState.PAST) {
      return {
        ...VALID_PANDAS_DATE_RANGE,
        ...{max: moment(endDate).toDate()},
      };
    }
    // @ts-ignore
    return {
      ...VALID_PANDAS_DATE_RANGE,
      ...{min: moment(endDate).toDate()},
    };
  }
}
