import {BaseEventsComponent} from '../components/base-events/base-events.component';

export interface IEventSegment {
  id: string;
  name: string;
}

export interface IEventSearchParams {
  event_type: string;
  recentlyAdded?: boolean;
  name: string;
  segments?: string;
  dates?: string;
}

export class EventSearchParams implements IEventSearchParams {
  dates?: string;
  // tslint:disable-next-line:variable-name
  event_type: string;
  name: string;
  recentlyAdded?: boolean;
  segments?: string;

  static toSearchParams(value: any): EventSearchParams {
    const [start, end] = BaseEventsComponent.getEventDates(value.dates);
    return {
      ...(start && {dates: [start, end].join(',')}),
      event_type: value.eventType,
      name: value.name,
      ...(value.segments && {segments: value.segments}),
      ...(value.recentlyAdded && {recentlyAdded: value.recentlyAdded}),
    };
  }
}

export interface IEventType {
  id: number;
  name: string;
  scenarioId?: string;
  weightedAverage?: IMultipliers
  segments?: IScenarioEventTypeSegmentDto[];
}

export interface IMultipliers {
  newUsers: number;
  retention: number;
  monetisation: number;
}

export interface IScenarioEventTypeSegmentDto {
  eventCount?: number;
  multipliers?: IMultipliers;
  name?: string;
  size?: number;
}

export interface IScenarioEventPerformanceDto {
  eventCount?: number;
  multipliers?: IMultipliers;
  segments?: string[];
  eventType?: string;
}

export enum EventsState {
  PAST = 'past',
  FUTURE = 'future',
}

export enum EventsSegments {
  ALL = 'all',
  GLOBAL = 'global',
  ORGANIC = 'organic',
}
