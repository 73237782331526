import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable({providedIn: 'root'})
export class AlertService {

  durationInSeconds = 3;

  constructor(private snackBar: MatSnackBar) {
  }

  message(message: string, severity: string, summary: string = '', durationInMs = 3000) {
    this.snackBar.open(message, summary, {
      duration: durationInMs,
      panelClass: severity
    });
  }

  messageWithAction(message: string, severity: string, summary: string = '', callback: () => void, durationInMs = 6000, customClass = null) {
    this.snackBar.open(message, summary, {
      duration: durationInMs,
      panelClass: [severity, customClass],
    }).onAction().subscribe(callback);
  }

  error(message: string) {
    this.snackBar.open(message, '', {
      duration: this.durationInSeconds * 1000,
      panelClass: 'error'
    });
  }

  success(message: string, summary: string = '') {
    this.snackBar.open(message, summary, {
      duration: this.durationInSeconds * 10000,
      panelClass: 'success',
      verticalPosition: 'top'
    });
  }

  add(param: { severity: string; summary: string | any; detail: string; life: number }) {
    this.message(param.detail, param.severity, '', param.life);
  }

  persistantMessage(message: string, summary: string = '') {
    this.snackBar.open(message, summary, {
      panelClass: 'success',
      verticalPosition: 'top'
    });
  }

  dismissMessage() {
    this.snackBar.dismiss();
  }
}
