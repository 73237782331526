import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-nav-list-fav-item',
  templateUrl: './nav-list-fav-item.component.html'
})
export class NavListFavItemComponent {

  @Input() itemType: 'scenario' | 'report' | 'scenario-report';
  
  constructor() { }
}
