import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { JobStatus } from 'src/app/views/scenario/model/scenario-job-dto.model';
import { SvgImagesComponent } from '../svg-images/svg-images.component';

@Component({
  selector: 'app-state',
  templateUrl: './state.component.html',
  styles: [`:host { display: inline-block }`],
  standalone: true,
  imports: [CommonModule, SvgImagesComponent]
})
export class StateComponent {
  @Input({
    transform: (value: string) => value?.toUpperCase()
  }) state: JobStatus; 
  @Input() isStyled: boolean = true; 
  @Input() showStateText: boolean = true;

  public get status(): string {
    switch (this.state) {
      case JobStatus.FAILED:
      case JobStatus.ERROR:
      case JobStatus.CANCELLED:
        return 'failed';
      case JobStatus.NEW:
        return 'default';
      case JobStatus.PROCESSED:
        return 'success';
      default:
        return 'processing';
    }
  }

}