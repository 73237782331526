import { Config, FileError, IScenario } from './scenario.model';

export enum JobStatus {
    PENDING = 'PENDING',
    NEW = 'NEW',
    MODELLING = 'MODELLING',
    STAGING = 'STAGING',
    LOADING = 'LOADING',
    TRANSFORMING = 'TRANSFORMING',
    PROCESSED = 'PROCESSED',
    CANCELLED = 'CANCELLED',
    FAILED = 'FAILED',
    ERROR = 'ERROR',
}

export interface IScenarioJobDto {
    accountId?: string;
    config?: Config;
    createdAt?: string;
    completedAt?: string;
    actionedBy?: string;
    updatedBy?: string;
    updatedAt?: string;
    dataPruned?: boolean;
    error?: string;
    errors?: {[key: string]: any};
    id?: number;
    orgId?: string;
    jobType?: string;
    loadingEnd?: string;
    loadingStart?: string;
    modellingEnd?: string;
    modellingStart?: string;
    scenarioId?: number;
    processingStart?: string;
    processingEnd?: string;
    state?: JobStatus | string;
    transformingEnd?: string;
    transformingStart?: string;
    scenario?: IScenario;
    changes?: {
        product: string[];
        general: string[];
        newUsers: string[];
        retention: string[];
        monetisation: string[];
        marketingV2: string[];
    };
}

export class ScenarioJob implements IScenarioJobDto {
    constructor(
    public accountId?: string,
    public config?: Config,
    public createdAt?: string,
    public actionedBy?: string,
    public updatedBy?: string,
    public updatedAt?: string,
    public dataPruned?: boolean,
    public errors?: FileError | {[key: string]: any},
    public id?: number,
    public jobType?: string,
    public loadingEnd?: string,
    public loadingStart?: string,
    public modellingEnd?: string,
    public modellingStart?: string,
    public scenarioId?: number,
    public segmentsPending?: string,
    public segmentsProcessed?: string,
    public segmentsProcessing?: string,
    public scenario?: IScenario,
    public state: JobStatus | string = JobStatus.NEW,
    public transformingEnd?: string,
    public transformingStart?: string){}
}
