<div class="inline-flex"
  (mouseenter)="toggleNavGroup(true)"
  (mouseleave)="toggleNavGroup(false)"
  (click)="toggleGroup()"
  cdkOverlayOrigin
  #trigger="cdkOverlayOrigin">
  <ng-content></ng-content>
</div>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="isOpenState()"
  [cdkConnectedOverlayPositions]="label?.length > 0 ? toolTipPosition : popupPosition"
  [cdkConnectedOverlayPush]="true"
  (overlayOutsideClick)="closeMenu()">

  <div class="relative"
    (mouseenter)="onMouseEnterMenu()"
    (mouseleave)="onMouseLeaveMenu()">
    @if (enableClick) {
      <button type="button" (click)="closeMenu()"
        class="absolute top-3 right-3 z-10 order rounded-md shadow-sm flex items-center justify-center p-2 active hover:text-white text-gray hover:border-digital-blue-700 hover:bg-digital-blue">
        <app-svg-images class="w-3 h-3 block" svgName="x-mark"></app-svg-images>
      </button>
    }
    @if(label?.length > 0 && !isCustom) {
      <div class="max-w-[300px] bg-digital-blue text-white p-2 shadow rounded-md text-center text-xs mb-1">
        {{ label }}
      </div>
    } @else {
      <ng-content select="[popup-content]"></ng-content>
    }
  </div>
</ng-template>