import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appNoScroll]',
  standalone: true
})
export class NoScrollDirective {
  @HostListener('wheel', ['$event'])
  onWheel(event: Event) {
    (event.target as HTMLElement).blur();
  }
}
