import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, FormsModule } from '@angular/forms';
import { SvgImagesComponent } from '../svg-images/svg-images.component';
import { DropdownMenuComponent } from '../dropdown-menu/dropdown-menu.component';
import { noop } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-search-sort',
  templateUrl: './search-sort.component.html',
  standalone: true,
  imports: [CommonModule, SvgImagesComponent, DropdownMenuComponent, FormsModule, TranslateModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchSortComponent),
      multi: true
    }
  ],
})
export class SearchSortComponent implements ControlValueAccessor {
  
  public isDisabled = false;
  public direction: 'asc' | 'desc' = 'asc';
  @Input() value: string = '';
  @Input() identifier: string;
  @Input() placeholderText: string = '';
  @Input() sortItems: string[];
  @Input() selectedSort: string = 'name';
  @Output() sortChange = new EventEmitter<{active: string, direction: 'asc' | 'desc'}>();


  onChange: (value: string) => void = noop;
  onTouch: () => void = noop;

  disabled = false;

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  writeValue(value: string): void {
    this.value = value;
  }

  onSearchChange(text: string) {
    this.onChange(text);
    this.onTouch();
    this.value = text;
  }

  onSortSelect(active: string) {
    this.selectedSort = active;
    this.direction = this.direction === 'asc' ? 'desc' : 'asc';
    this.sortChange.emit({active: active.replace(' ', '_'), direction: this.direction});
  }
}
