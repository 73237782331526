import {Directive, Input, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {distinctUntilChanged} from 'rxjs/operators';
import {AccountService} from '../services/account.service';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[HasAuthority]'
})
export class HasAuthorityDirective implements OnInit {

  private authorities: string[];

  constructor(private accountService: AccountService, private templateRef: TemplateRef<any>, private viewContainerRef: ViewContainerRef) {}

  @Input()
  set HasAuthority(value: string | string[]) {
    this.authorities = typeof value === 'string' ? [value] : value;
  }

  ngOnInit(): void {
    this.accountService.hasAnyAuthority(this.authorities).pipe(distinctUntilChanged()).subscribe((hasAnyAuthority) => {
      if (hasAnyAuthority) {
        this.viewContainerRef.createEmbeddedView(this.templateRef);
      } else {
        this.viewContainerRef.clear();
      }
    });
  }
}
