import { Injectable, WritableSignal, signal } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, filter, firstValueFrom, map } from 'rxjs';
import { AppState, archiveScenario, cancelScenario, deleteScenario, displayMessage, fetchScenarios, runScenario, selectFavouriteScenarios, updateScenarioFavourite, updateScenarioSettings, updateScenario, selectBudgetScenario } from 'src/app/store';
import { IScenario } from '../model/scenario.model';
import { ConfirmationService } from 'src/app/core/services/confirmation.service';
import { IGraphQLParams } from 'src/app/graphql/graphql.models';
import { ActivatedRoute, Router } from '@angular/router';
import { ScenarioUpdateDtoModel } from '../model/scenario-update-dto.model';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationDialogComponent } from 'src/app/core/ui/confirmation-dialog/confirmation-dialog.component';
import { DEFAULT_CURRENT_PAGE, MODAL_DEFAULT_WIDTH, FAVOURITE_SCENARIOS_LIMIT, PROCESSED_STATES } from 'src/app/core/app.constants';
import { Dialog } from '@angular/cdk/dialog';
import { AddNewScenarioComponent } from '../dialogs/add-new-scenario/add-new-scenario.component';
import { AlertService } from 'src/app/core/services/alert.service';

@Injectable({
  providedIn: 'root'
})
export class ScenarioActionsService {

  public isFavouritesLimit$: Observable<boolean> = this.store.select(selectFavouriteScenarios).pipe(map(scenarios => scenarios.length === FAVOURITE_SCENARIOS_LIMIT));
  public pageSignal: WritableSignal<number> = signal(null);
  public pageSizeSignal: WritableSignal<number> = signal(null);
  public productIdSignal: WritableSignal<number> = signal(null);
  public isListPageSignal: WritableSignal<boolean> = signal(true);
  public internalSignal: WritableSignal<boolean> = signal(false);

  constructor(
    private store: Store<AppState>,
    public dialog: Dialog,
    private router: Router, 
    public activatedRoute: ActivatedRoute,
    private confirmationService: ConfirmationService,
    private translateService: TranslateService,
    private messageService: AlertService,
  ) {
  }

  async createScenarioDialog(): Promise<void> {
    const productId = this.productIdSignal();
    const data = { productId, clone: false};
    const result =  await this.createScenario(data);

    if (!!result && !!result.scenarioId){
        this.refreshScenarioLists(this.pageSignal());
        if (result.isDuplicate) {
          this.router.navigate(['/products', productId, 'scenarios', result.scenarioId, 'milestones']).then(_ => {
            this.messageService.message(this.translateService.instant('scenario.config.advanced.cloned'), 'success');
          });
        }
    };
  }

  refreshScenarioLists(page: number = DEFAULT_CURRENT_PAGE): void {
    const productId = this.productIdSignal();
    if(this.isListPageSignal()) {
      // Refreshes paginated scenarios
      this.paginateScenarios({
        filter: {productId, internal: this.internalSignal()},
        pageSize: this.pageSizeSignal(), 
        page
      });
    }

    // refreshes scenario dropdown list
    this.paginateScenarios({
      filter: {productId, internal: null},
    })
  }

  archiveScenario(scenario: IScenario): void {
    const message = (scenario.archived) ? 'scenario.config.unarchive.message' : 'scenario.config.archive.message';
    const title = (scenario.archived) ? 'scenario.config.unarchive.title' : 'scenario.config.archive.title'

    this.confirmationService.confirm(
      () => {
        this.store.dispatch(archiveScenario({
          scenario, 
          paginationParams: {filter: {productId: scenario.product.id, internal: this.internalSignal()},
            pageSize: this.pageSizeSignal(), 
            page: this.pageSignal()
          }
        }));
      },
      () => {}, 
      message, 
      title
    );
  }

  setAsBudgetScenario(scenario: IScenario): void {
    const message = 'scenario.config.setAsBudget.message';
    const title = 'scenario.config.setAsBudget.title';

    this.confirmationService.confirm(
      async () => {
        const scenarioUpdate: ScenarioUpdateDtoModel = {
          id: scenario.id,
          budget: true
        }
        this.store.dispatch(updateScenario({scenarioUpdate}));
        const isBudget = await firstValueFrom(this.store.select(selectBudgetScenario).pipe(
          filter((budgetScenario) => !!budgetScenario && budgetScenario.id === scenario.id)
        ));
        if (isBudget) {
          this.refreshScenarioLists(this.pageSignal());
        }
      },
      () => {}, 
      message, 
      title
    );
  }

  toggleReportVisibility(element: IScenario): void {
    const {id, name, product, includeInReports} = element;
    const scenarioUpdate: ScenarioUpdateDtoModel = { id, includeInReports: !includeInReports };
    const trackingData = { 
      label: 'Scenario Visibility', 
      data: {
        product_id: product?.id,
        product_name: product?.name,
        scenario_id: id,
        scenario_name: name,
        scenario_visible: scenarioUpdate.includeInReports
      }
    }
    this.store.dispatch(updateScenarioSettings({ scenarioUpdate, trackingData }));
  }

  toggleLocked(element: IScenario): void {
    const scenarioUpdate: ScenarioUpdateDtoModel = {
      id: element.id,
      locked: !element.locked
    };
    this.store.dispatch(updateScenarioSettings({ scenarioUpdate })); 
  }

  async deleteScenario(scenario: IScenario, shouldRedirect = false): Promise<void> {
    if (scenario.default) {
      this.store.dispatch(displayMessage({ 
        detail: 'scenario.cannotDelete', 
        summary: 'error.error' 
      }));
      return;
    } else if (scenario.budget) {
      this.store.dispatch(displayMessage({
        detail: 'scenario.cannotDeleteBudget',
        summary: 'error.error'
      }));
      return;
    }

    const result = await firstValueFrom(this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: {
        message: this.translateService.instant('data.scenario.delete', {name: scenario.name}),
        title: this.translateService.instant('data.scenario.deleteTitle')
      }
    }).closed);

    if(!!result) {
      this.store.dispatch(deleteScenario({ scenario, shouldRedirect }));
    };
  }

  async process(scenario: IScenario): Promise<void> {
    const {id, name} = scenario;
    if(this.isProcessing(scenario)) {
      const result = await firstValueFrom(this.dialog.open(ConfirmationDialogComponent, {
        width: '350px',
        data: {
          title: this.translateService.instant('scenario.config.advanced.cancelRunConfirmation.title', {name}),
          message: this.translateService.instant('scenario.config.advanced.cancelRunConfirmation.message')
        }
      }).closed)
  
      if (!!result) {
        this.store.dispatch(cancelScenario({ id }));
      };
      return;
    }
    this.store.dispatch(runScenario({scenario}));
  }

  isProcessing(scenario: IScenario): boolean {
    const state = scenario.currentScenarioJob?.state || scenario.currentScenarioJobState;
    return !PROCESSED_STATES.includes(state);
  }
  
  async duplicateScenario(scenario: IScenario) {
    const { id } = scenario;
    const data = { scenarioId: id, clone: true };
    const result =  await this.createScenario(data);

    if (!!result && !!result.scenarioId) {
        this.store.dispatch(displayMessage({ 
          detail: 'scenario.config.advanced.cloned', 
          summary: 'View'
        }));
        this.refreshScenarioLists(this.pageSignal());
    };
  }

  createScenario(data: {[key: string]: any}): Promise<any> {
    return firstValueFrom(
      this.dialog.open<{ scenarioId: number, isDuplicate: boolean }>(AddNewScenarioComponent, {
        width: "450px",
        data
      }).closed
    );
  }

  onSortChange({active, direction}: {active: string, direction: 'asc' | 'desc'}): void {
    this.store.dispatch(fetchScenarios({ paginationParams: {
        filter: {productId: this.productIdSignal(), internal: this.internalSignal()},
        sort: [this.getActiveSort(active), direction],
        pageSize: this.pageSizeSignal(), 
        page: this.pageSignal()
      } 
    }));
  }

  private getActiveSort(active: string): string {
    const sort = active.toLowerCase();
    switch(sort) {
      case 'last_updated':
        return 'current_scenario_job__created_at';
      case 'runtime':
        return'current_scenario_job__runtime';
      default:
        return sort;
    }
  };

  onFavouriteClick(scenario: IScenario, favourite: boolean): void {
    const { id, product: { id: productId } } = scenario;
    this.store.dispatch(updateScenarioFavourite({scenario: {id: productId, changes: {id, favourite}}}));
  }

  updateCurrentPage(page: number): void {
    this.paginateScenarios({page, pageSize: this.pageSizeSignal(), filter: this.getFilters()});
    this.pageSignal.set(page);
  }

  updatePageSize(pageSize: number): void {
    this.paginateScenarios({pageSize, page: this.pageSignal(), filter: this.getFilters()});
    this.pageSizeSignal.set(pageSize);
  }

  private getFilters() {
    return {
      productId: this.productIdSignal(), 
      internal: this.internalSignal()
    }
  }

  paginateScenarios(paginationParams: IGraphQLParams): void {
    const { page, pageSize, filter } = paginationParams;
    const { searchText, productId, internal } = filter || {};
    const params = this.router.routerState.snapshot.root.queryParams;
    this.router.navigate([], {queryParams: {
      ...params, 
      ...(page && {page}),
      ...(pageSize && {pageSize})
    }});
    this.store.dispatch(fetchScenarios({paginationParams: {
      filter: {
        productId,
        internal,
        ...(searchText && {searchText})
      },
      ...(page && {page}),
      ...(pageSize && {pageSize})
    }}));
  }
}
