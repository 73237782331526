import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import moment from 'moment';

@Component({
  selector: 'app-duration-counter',
  templateUrl: './duration-counter.component.html',
  styleUrls: ['./duration-counter.component.scss']
})
export class DurationCounterComponent implements OnInit, OnChanges, OnDestroy {

  @Input() start;
  @Input() end;
  @Input() state = 'PROCESSING';

  running = false;
  display;
  interval;

  constructor() {
  }

  ngOnInit(): void {
  }

  get failed() {
    const states = ['failed', 'cancelled', 'new', 'error']
    return states.includes(this.state?.toLowerCase());
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.start && !this.running && !this.end) {
      this.startTimer();
    }
    if (this.end || this.failed) {
      this.display = this.processingTime;
      this.pauseTimer();
    }
  }

  get duration() {
    const diff = moment().diff(this.start);
    const hours = moment().diff(moment(this.start), 'hours');
    if (hours > 0) {
      return moment(diff).format(`${hours}:mm:ss`);
    }
    return moment(diff).format('mm:ss');
  }

  startTimer() {
    this.display = this.duration;
    this.running = true;
    this.interval = setInterval(() => {
      this.display = this.duration;
    }, 1000);
  }

  pauseTimer() {
    this.running = false;
    clearInterval(this.interval);
  }

  get processingTime() {
    if (!this.end || !this.start || this.failed) return 'N/A';
    const diff = moment(this.end).diff(moment(this.start));
    const hours = moment(this.end).diff(moment(this.start), 'hours');
    if (hours > 0) {
      return moment(diff).format(`${hours}:mm:ss`);
    }
    return moment(diff).format('mm:ss');
  }

  ngOnDestroy() {
    this.pauseTimer();
  }

}
