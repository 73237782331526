<ng-container [formGroup]="formGroup">
  <mat-form-field appearance="outline">
    <mat-label [translate]="dayLabel"></mat-label>
    <mat-select panelWidth='null' formControlName="days" multiple>
      @for (day of days; track day.id) {
        <mat-option [value]="day.id">
          {{ ('weekdays.' + day.name) | translate }}
        </mat-option>
      }
    </mat-select>
    <mat-error [translate]="dayError"></mat-error>
  </mat-form-field>
  <div class="d-inline-block">
    <mat-form-field appearance="outline" class="ml-2">
      <mat-label [translate]="timeLabel"></mat-label>
      <mat-select panelWidth='null' formControlName="hour">
        @for (time of hours; track time.id) {
          <mat-option [value]="time.id">
            {{ time.hour }}
          </mat-option>
        }
      </mat-select>
      <mat-error [translate]="timeError"></mat-error>
    </mat-form-field>
    <span class="d-inline-block ml-2">UTC</span>
  </div>
</ng-container>