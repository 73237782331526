import { Pipe, PipeTransform } from '@angular/core';

const NON_COUNTRY_CODES = ["ROW", "TIER1", "TIER2", "TIER3", "OTHER"]

@Pipe({
  name: 'flag',
  standalone: true
})

export class FlagPipe implements PipeTransform {
  transform(value: string, ...args: unknown[]): unknown {
    if (NON_COUNTRY_CODES.includes(value)) return "hidden"
    
    return `fi fi-${value.toLowerCase()}`
  }

}