
 export function toBool(value: string|boolean) {
    if (typeof value === 'boolean') {
      return value;
    }
    switch (value.toLowerCase().trim()) {
      case 'true':
      case 'yes':
      case '1':
        return true;
      case 'false':
      case 'no':
      case '0':
      case null:
        return false;
      default:
        return Boolean(value);
    }
  }

  export function splitCamelCase(input: string): string {
    // Use a regular expression to insert a space before each capital letter
    const result = input.replace(/([A-Z])/g, ' $1');
  
    // Convert the first character to uppercase and trim any leading spaces
    return result.charAt(0).toUpperCase() + result.slice(1).trim();
  }

  export function convertToString(value: any | any[]): string {
    if (Array.isArray(value)) {
      return value.join(', ');
    }
    return `${value}`;
  }
