import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { DAYS, TIME } from './day-time.constants';

@Component({
  selector: 'app-day-time-select',
  templateUrl: './day-time-select.component.html',
  styleUrls: ['./day-time-select.component.scss']
})
export class DayTimeSelectComponent {

  @Input() formGroup: UntypedFormGroup;
  @Input() dayLabel: string;
  @Input() timeLabel: string;
  @Input() dayError: string;
  @Input() timeError: string;

  days = DAYS;
  hours = TIME;

  constructor() { }

}
