import { createAction, props } from '@ngrx/store';
import { IGraphQLParams } from '../../../../graphql/graphql.models';
import { Update } from '@ngrx/entity';
import { IUser, UpdateUserRole, UserDetails } from '../../model/user-management.model';
import { IUserState } from '../reducers/user.reducer';

export const fetchUsers = createAction(
  '[User/API] Fetch Users',
  props<{ orgId: string, paginationParams?: IGraphQLParams }>()
);

export const loadMoreUsers = createAction(
  '[User/API] Load More Users',
  props<{ orgId: string, paginationParams?: IGraphQLParams }>()
);

export const fetchUserOrganisations = createAction(
  '[User] Fetch User Organisations',
  props<{ userId: string, paginationParams?: IGraphQLParams }>()
);

export const updateUserRole = createAction(
  '[Role] Update User Role',
  props<{ orgId: string, user: IUser, role: UpdateUserRole }>()
);

export const addUser = createAction(
  '[User/API] Add User',
  props<{ user: IUserState }>()
);

export const upsertUser = createAction(
  '[User/API] Upsert User',
  props<{ user: IUserState }>()
);

export const loadUsers = createAction(
  '[User/API] Load Users',
  props<{ users: IUserState[], totalCount?: number;  }>()
);

export const addUsers = createAction(
  '[User/API] Add Users',
  props<{ users: IUserState[] }>()
);

export const upsertUsers = createAction(
  '[User/API] Upsert Users',
  props<{ users: IUserState[], totalCount?: number;  }>()
);

export const updateUser = createAction(
  '[User/API] Update User',
  props<{ user: Update<IUserState> }>()
);

export const updateUsers = createAction(
  '[User/API] Update Users',
  props<{ users: Update<IUserState>[] }>()
);

export const deleteUserById = createAction(
  '[User/API] Delete User By ID',
  props<{ id: string }>()
);

export const deleteUser = createAction(
  '[User/API] Delete User',
  props<{orgId: string, userId: string, list?: UserDetails }>()
);

export const deleteUsers = createAction(
  '[User/API] Delete Users',
  props<{ ids: string[] }>()
);

export const userApiError = createAction(
  '[User/API] User Api Error',
  props<{ error: any }>()
);

export const clearUsers = createAction(
  '[User/API] Clear Users'
);