import {FileState, FileUploadType} from '../../product/model/product.model';
import {FileUploadResult} from '../../../core/metrics/scenario-metrics';

export interface IScenarioOverrideEvent {
  [key: string]: any;
  scenarioId: number;
  fileType: FileUploadType;
  fileId: number;
  filename: string;
  state: FileState;
  accountId?: string;
  orgId?: string;
  jobId?: number;
}

export interface ScenarioOverride {
  scenarioId: number;
  dimRampSegmentId: string;
  segmentName: string;
  overrides: {
    [key: string]: FileUploadResult
  }
}

export class ScenarioOverrideEvent implements IScenarioOverrideEvent {
  constructor(
    public scenarioId: number,
    public fileType: FileUploadType,
    public fileId: number,
    public filename: string,
    public state: FileState,
    public errors?: any,
  ) {
  }
}