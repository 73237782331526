<div [ngClass]="{
  'bg-info/20': enableClass('info'),
  'bg-orange-100 text-black': enableClass('warning'),
  'bg-danger/20': enableClass('danger'),
  'border-info/50': enableClass('info'),
  'border-danger/50': enableClass('danger')
  }"
 class="border border-solid rounded-md p-2.5 text-sm font-medium">
    <div class="flex items-center justify-center">
      <mat-icon aria-hidden="false" aria-label="icon" 
        [ngClass]="{
          'text-info': enableClass('info'),
          'text-warning': enableClass('warning'),
          'text-danger': enableClass('danger')
        }" 
        class="mr-2">{{ icon }}</mat-icon>  
      <span class="inline-block" [translate]="translateKey"></span>
    </div>
</div>