import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
import { AnalyticsBrowser, Traits } from '@segment/analytics-next';
import { BehaviorSubject, filter, take } from 'rxjs';
import { User } from '@auth0/auth0-angular';
import { AnalyticsNextConfig } from '../config/models/config';

@Injectable({providedIn: 'root'})
export class AnalyticsNextService {

  private analytics: AnalyticsBrowser;
  private analyticsEnabled: boolean;

  private userSubject$ = new BehaviorSubject<User>(null);
  private user$ = this.userSubject$.asObservable();

  constructor(
    @Inject(PLATFORM_ID) protected platformId: object,
  ){
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    this.analytics = new AnalyticsBrowser();
  }

  public async init(analyticsNextConfig: AnalyticsNextConfig): Promise<void> {
    if (!isPlatformBrowser(this.platformId)) return;
    const { writeKey } = analyticsNextConfig;

   try {
      await this.analytics.load({ writeKey });
    } catch (error) {
      console.warn('Segment analytics-next failed to load', error);
    }

    this.identify();
  }

  public setUser(userId: string, traits: Traits): void {
    this.userSubject$.next({ userId, traits });
  }

  public identify(): void {
    this.user$.pipe(
      filter(user => !!user), 
      take(1)
    ).subscribe(({ userId, traits }) => {
      this.analytics.identify(userId, traits);
    });
  }

  public track(event: string, data = {}): void {
    if(!this.analyticsEnabled) false;
    this.analytics.track(event, data);
  }

  public page(name: string): void {
    if(!this.analyticsEnabled) false;
    this.analytics.page(name);
  }

  public shutdown(): void {
    if(!this.analyticsEnabled) false;
    // Clears cookies and localStorage created by Segment.com
    this.analytics.reset();
  }

}

