import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Theme, ThemeService } from '../../services/theme.service';

@Component({
  selector: 'app-theme-switcher',
  templateUrl: './theme-switcher.component.html',
})
export class ThemeSwitcherComponent implements OnInit {
  currentTheme: Theme;
  themes: Theme[] = [];
  private destroyRef = inject(DestroyRef);

  constructor(public themeService: ThemeService) {}

  ngOnInit() {
    this.themes = this.themeService.getThemes();

    this.themeService.getCurrentTheme().pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(theme => {
      this.currentTheme = theme;
    });
  }

  isActiveTheme(theme: Theme): boolean {
    return this.currentTheme?.label === theme.label;
  }

  public toggleTheme(theme: Theme): void {
    this.themeService.setTheme(theme.label);
  }
}
