import { Component, Input, OnInit } from '@angular/core';

type banner =  'info' | 'warning' | 'danger';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {

  @Input() icon: banner = 'info';
  @Input() translateKey: string;

  constructor() { }

  ngOnInit(): void {
  }

  enableClass(bannerType: string): boolean {
    return bannerType == this.icon;
  }
}
