import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as fromOrganisationActions from '../actions/organisation.actions';
import * as PageActions from 'src/app/store/actions/page.actions';
import { AlertService } from 'src/app/core/services/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { UserManagementService } from '../../services/user-management.service';
import { HelpersService } from 'src/app/core/services/helpers.service';
import { UPDATE_ORG_LOGO_MUTATION, ADD_ORG_LOGO_MUTATION, DELETE_ORG_LOGO_MUTATION } from '../../graphql/mutations';
import { loadAppSuccess } from '../actions/user-management.actions';

@Injectable()
export class OrganisationEffects {
  constructor(
    private actions$: Actions,
    private userManagementService: UserManagementService,
    private helpersService: HelpersService,
    public messageService: AlertService,
    private translateService: TranslateService,
  ) {
  }

  fetchOrganisation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromOrganisationActions.fetchOrganisation),
      mergeMap(({orgId}) =>
        this.userManagementService.getOrganisation(orgId).pipe(
          map((organisation) => fromOrganisationActions.upsertOrganisation({organisation})),
          catchError((error) => of(PageActions.pageApiError({error})))
        )
      )
    )
  );

  fetchOrganisations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromOrganisationActions.fetchOrganisations),
      mergeMap(({paginationParams}) =>
        this.userManagementService.getOrganisations(paginationParams).pipe(
          map(({results, total}) => fromOrganisationActions.loadOrganisations({organisations: results, totalCount:total})),
          catchError((error) => of(PageActions.pageApiError({error})))
        )
      )
    )
  );

  loadMoreOrganisations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromOrganisationActions.loadMoreOrganisations),
      mergeMap(({paginationParams}) =>
        this.userManagementService.getOrganisations(paginationParams).pipe(
          map(({results, total}) => fromOrganisationActions.upsertOrganisations({organisations: {results, total}})),
          catchError((error) => of(PageActions.pageApiError({error})))
        )
      )
    )
  );

  upsertAuthUserOrganisation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAppSuccess),
      map(({data}) => fromOrganisationActions.loadOrganisations({
        organisations: data.organisations?.results || data.userOrganisations.results, 
        totalCount: data.organisations?.total || data.userOrganisations.total
      }))
    )
  );

  createOrganisation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromOrganisationActions.createOrganisation),
      mergeMap(({name}) =>
        this.userManagementService.createOrganisation(name).pipe(
          map((organisation) => fromOrganisationActions.addOrganisation({organisation})),
          catchError((error) => of(PageActions.pageApiError({error})))
        )
      )
    )
  );

  updateOrganisation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromOrganisationActions.updateOrganisation),
      mergeMap(({orgId, organisation}) =>
        this.userManagementService.updateOrganisation(orgId, organisation).pipe(
          map((organisation) => fromOrganisationActions.updateOrganisationSuccess({organisation: {id: orgId, changes: organisation}})),
          catchError((error) => {
            const status = this.helpersService.getSupportedErrorCode(error?.status);
            this.messageService.error(
              this.translateService.instant(`error.${status}`)
            );
            return of(PageActions.pageApiError({error}))
          })
        )
      )
    )
  );


  uploadLogo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromOrganisationActions.uploadLogo),
      mergeMap(({id, file, operation}) => {
        const isUpdate = operation.toLocaleLowerCase().includes('update');
        const mutation = isUpdate ? UPDATE_ORG_LOGO_MUTATION : ADD_ORG_LOGO_MUTATION;
        return this.userManagementService.uploadImage(id, file, operation, mutation).pipe(
          map(({organisation}) => fromOrganisationActions.updateOrganisationSuccess({organisation: {id: organisation.id, changes: organisation}})),
          catchError((error) => of(fromOrganisationActions.uploadLogoFailure({error})))
          )
        }
      ),
    )
  );

  deleteLogo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromOrganisationActions.deleteLogo),
      mergeMap(({id, operation}) => this.userManagementService.deleteImage(id, operation, DELETE_ORG_LOGO_MUTATION).pipe(
        map(({organisation}) =>fromOrganisationActions.updateOrganisationSuccess({organisation: {id: organisation.id, changes: organisation}})),
        catchError((error) => of(fromOrganisationActions.deleteLogoFailure({error})))
      )),
    )
  );

}
