<button
  type="button"
  [disabled]="isDisabled"
  (click)="handleClick()"
  class="flex items-center space-x-3 p-2 rounded w-full active text-left"
    [ngClass]="{ 
        'bg-gray-50' : isDisabled, 
        'hover:bg-soft-blue': !isDisabled,
        'text-gray-400' : state !== 'danger' && isDisabled, 
        'text-stone-700' : state !== 'danger' && !isDisabled, 
        'text-red-500' : state === 'danger' && !isDisabled,
        'text-red-200' : state === 'danger' && isDisabled
    }">
  @if (icon.length > 0) {
    <app-svg-images class="h-4 block" [svgName]="icon"></app-svg-images>
  }
  <div class="flex-1">
    <ng-content></ng-content>
  </div>
</button>