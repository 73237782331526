<div
  [matTooltip]="value.toFixed() + '%' + notificationsBarState"
  [matTooltipClass]="(isEvents) ? 'events': null"
  class="notification p-0" @slideDownPositionAnimation>
  <mat-progress-bar
    [ngClass]="{'big': !embedded, 'events': isEvents}"
    [mode]="mode"
    [value]="value">
  </mat-progress-bar>
</div>
