import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { IProduct, Product, ProductMarketingConfig, UploadedFile } from '../model/product.model';
import { ScenarioSegment } from '../../scenario/model/scenario-segments.model';
import { GraphqlService } from '../../../graphql/graphql.service';
import {
  PRODUCT_FILE_LIST_QUERY,
  PRODUCT_LIST_QUERY, PRODUCT_MARKETING_MUTATION,
  PRODUCT_MARKETING_QUERY,
  PRODUCT_QUERY,
  PRODUCT_SEGMENTS_QUERY
} from '../graphql/queries';
import { catchError, filter, map, tap } from 'rxjs/operators';
import { GraphQLEdgesResponse, GraphQLParams, GraphQLResponse, IGraphQLParams } from '../../../graphql/graphql.models';
import { Store } from '@ngrx/store';
import { AppState, updateProduct, trackItem } from 'src/app/store';
import { deleteScenarioJobsActivity } from 'src/app/core/activity/store';
import { AlertService } from 'src/app/core/services/alert.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';


@Injectable({providedIn: 'root'})
export class ProductService extends GraphqlService {

  get runwayUrl() {
    return this.environment.resourceServer.runwayUrl + 'products';
  }

  constructor(
    private http: HttpClient,
    private store: Store<AppState>,
    private alertService: AlertService,
    private router: Router,
    private translateService: TranslateService,
  ) {
    super();
  }

  find(productId: number) {
    return this.apollo.query<GraphQLResponse<IProduct>>({
      fetchPolicy: 'network-only',
      query: PRODUCT_QUERY,
      variables: {
        id: productId
      }
    }).pipe(
      map(response => {
        if(response.errors) {
          const errorMessages: string = [...new Set(response.errors.map(err => err.message))].join('.');
          console.error('[Product Query error]: ', errorMessages);
        }
        if(!response.data.product) {
          console.error(`Product id ${productId} not found`);
        }
        if(response.errors || !response.data.product) {
          this.alertService.error(this.translateService.instant('data.product.loadError', {id: productId}));
          this.router.navigate(['/products']);
          return null;
        }
        return response.data.product;
      }),
      filter(product => !!product)
    );
  }


  files(productId: number, graphqlParams: IGraphQLParams): Observable<GraphQLEdgesResponse<UploadedFile>> {
    return this.apollo.query<any>({
      query: PRODUCT_FILE_LIST_QUERY,
      fetchPolicy: 'network-only',
      variables: {
        productId,
        ...this.mapParams(graphqlParams)
      }
    }).pipe(
      map(response => ({
        loading: response.loading,
        totalCount: response.data.productFiles.totalCount,
        results: response.data.productFiles.edges.map(productFile => productFile.node)
      }))
    );
  }

  run(productId: number) {
    return this.http.post<null>(this.runwayUrl + `/${productId}/job`, {
      observe: 'response'
    });
  }

  create(product: Product): Observable<HttpResponse<IProduct>> {
    return this.http.post<IProduct>(this.runwayUrl, product, {
      observe: 'response'
    }).pipe(
      map((response) => {
        const {id, name} = response.body;
        this.store.dispatch(trackItem({
          label: 'Product Created',
          data: {
            product_id: id,
            product_name: name
          }
        }));
        return response;
      })
    );
  }

  async deleteFile(file: UploadedFile): Promise<null> {
    return this.http.delete<null>(this.runwayUrl + `/${file.productId}/files/${file.id}`,).toPromise();
  }

  fail(productId: number): Observable<IProduct> {
    return this.http.post<IProduct>(this.runwayUrl + `/${productId}/fail`, {});
  }

  findAll(options: IGraphQLParams = new GraphQLParams()): Observable<GraphQLEdgesResponse<IProduct>> {
    const {page, pageSize, sort, filter} = options;
    return this.apollo.watchQuery<any>({
      query: PRODUCT_LIST_QUERY,
      fetchPolicy: 'network-only',
      variables: {
        page,
        pageSize,
        ...(sort && sort.length > 0) && {sort: sort.join('_').toUpperCase()},
        ...(filter) && {filter}
      },
    }).valueChanges.pipe(
      map((products) => ({
        loading: products.loading,
        totalCount: products.data.products.totalCount,
        results: products.data.products.edges.map(product => product.node)
      })),
    );
  }

  delete(product: Product): Observable<null> {
    return this.http.delete<null>(this.runwayUrl + `/${product.id}`).pipe(
      map((response) => {
        const {id, name} = product;
        this.store.dispatch(trackItem({
          label: 'Product Deleted',
          data: {
            product_id: id,
            product_name: name
          }
        }));
        this.store.dispatch(deleteScenarioJobsActivity({productId: product.id}))
        return response;
      })
    );
  }

  update(product: Product) {
    return this.http.patch<Product>(this.runwayUrl + `/${product.id}`, product).pipe(
      tap((product) => this.store.dispatch(updateProduct({product: {id: product.id, changes: product}})))
    );
  }

  segments(productId: number, sort = null): Observable<ScenarioSegment[]> {
    return this.apollo.query<any>({
      query: PRODUCT_SEGMENTS_QUERY,
      variables: {
        productId,
        sort
      }
    }).pipe(
      map(response => response.data.productSegments.edges.map(segment => segment.node))
    );
  }

  getMarketingConfig(productId: number): Observable<ProductMarketingConfig> {
    return this.apollo.query<any>({
      fetchPolicy: 'network-only',
      query: PRODUCT_MARKETING_QUERY,
      variables: {
        id: productId
      }
    }).pipe(
      map(response => response.data.marketingConfig)
    );
  }

  updateMarketingConfig(productId: number, productMarketingConfig: any) {
    return this.http.post<any>(
      this.baseUrl,
      {
        query: PRODUCT_MARKETING_MUTATION,
        variables: {id: productId, productMarketingConfig}
      }
    ).pipe(
      map(({data}) => data.updateProductMarketingConfig.marketingConfig)
    );
  }
}
