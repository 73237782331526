export const SCENARIO_REPORT_LINKS = [
  {
    label: 'titles.reportGroups.general',
    icon: 'general',
    links: [
      {
        label: 'titles.scenario.reports.active_users_layer_cake.title',
        link: 'active_users_layer_cake',
        role: null,
        isFavourite: false,
      },
      {
        label: 'titles.scenario.reports.accuracy-metrics.title',
        link: 'accuracy-metrics',
        role: 'BETA',
        isFavourite: false,
      },
      {
        label: 'titles.scenario.reports.scenario-history.title',
        link: 'scenario-history',
        role: 'BETA',
        isFavourite: false,
      },
    ],
  },
  {
    label: 'titles.reportGroups.marketing',
    icon: 'revenue',
    links: [
      {
        label: 'titles.scenario.reports.new-users.title',
        link: 'new-users',
        role: null,
        isFavourite: false,
      },
      {
        label: 'titles.scenario.reports.marketing-spend.title',
        link: 'marketing-spend',
        role: null,
        isFavourite: false,
      },
      {
        label: 'titles.scenario.reports.cost-per-acquisition.title',
        link: 'cost-per-acquisition',
        role: null,
        isFavourite: false,
      },
      {
        label: 'titles.scenario.reports.roas.title',
        link: 'roas',
        role: null,
        isFavourite: false,
      },
      {
        label: 'titles.scenario.reports.roas-history.title',
        link: 'roas-history',
        role: 'BETA',
        isFavourite: false,
      },
    ],
  },
  {
    label: 'titles.reportGroups.engagement',
    icon: 'users-retention',
    links: [
      {
        label: 'titles.scenario.reports.daily-active-users.title',
        link: 'daily-active-users',
        role: null,
        isFavourite: false,
      },
      {
        label: 'titles.scenario.reports.retention-trends.title',
        link: 'retention-trends',
        role: null,
        isFavourite: false,
      },
      {
        label: 'titles.scenario.reports.retention-cohort.title',
        link: 'retention-cohort',
        role: null,
        isFavourite: false,
      },
      {
        label: 'titles.scenario.reports.retention-curve.title',
        link: 'retention-curve',
        role: null,
        isFavourite: false,
      },
    ],
  },
  {
    label: 'titles.reportGroups.revenue',
    icon: 'cost-spend',
    links: [
      {
        label: 'titles.scenario.reports.user-revenue.title',
        link: 'user-revenue',
        role: null,
        isFavourite: false,
      },
      {
        label: 'titles.scenario.reports.cohort-monetisation.title',
        link: 'cohort-monetisation',
        role: null,
        isFavourite: false,
      },
      {
        label: 'titles.scenario.reports.lifetime-value.title',
        link: 'lifetime-value',
        role: null,
        isFavourite: false,
      },
      {
        label: 'titles.scenario.reports.cumulative-lifetime-value.title',
        link: 'cumulative-lifetime-value',
        role: null,
        isFavourite: false,
      },
      {
        label: 'titles.scenario.reports.ltv-ratio.title',
        link: 'ltv-ratio',
        role: 'BETA',
        isFavourite: false,
      },
      {
        label: 'titles.scenario.reports.revenue.title',
        link: 'revenue',
        role: null,
        isFavourite: false,
      },
    ],
  },
];

export const SCENARIO_ADVANCED_SETTINGS = [
  {
    label: 'scenario.advanced.segmentStatus',
    link: 'segments',
    icon: 'squares-2x2-solid',
    hasDropdown: false,
    role: null,
    dropdownItems: [],
  },
  {
    label: 'scenario.advanced.modelSettings.title',
    link: 'settings',
    icon: 'cog-6-tooth-solid',
    hasDropdown: true,
    role: 'INTERNAL',
    dropdownItems: [
      {
        label: 'scenario.advanced.modelSettings.newUsers',
        link: 'new-user-settings',
      },
      {
        label: 'scenario.advanced.modelSettings.retention',
        link: 'retention-settings',
      },
      {
        label: 'scenario.advanced.modelSettings.monetisation',
        link: 'monetisation-settings',
      },
    ],
  },
  {
    label: 'scenario.advanced.history',
    link: 'history',
    icon: 'clock-solid',
    hasDropdown: false,
    role: null,
    dropdownItems: [],
  },
];
