import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ProductBlendedSegmentNode } from 'src/app/views/scenario/settings/models/config.models';
import { SegmentLabelComponent } from '../segment-label/segment-label.component';
import { SvgImagesComponent } from '../svg-images/svg-images.component';
import { FormsModule } from '@angular/forms';

export interface SegmentParts {
  country: string;
  platform: string;
  other: string[];
}

@Component({
  selector: 'app-segment-picker',
  templateUrl: './segment-picker.component.html',
  imports: [
    SegmentLabelComponent,
    SvgImagesComponent,
    FormsModule,
    NgClass
],
  standalone: true,
})
export class SegmentPickerComponent {
  @Input() segments: ProductBlendedSegmentNode[];
  @Input() selected: string[];
  @Input() invalid: boolean = false;

  @Output() onSelect: EventEmitter<ProductBlendedSegmentNode> = new EventEmitter<ProductBlendedSegmentNode>();
  @Output() onDeselect: EventEmitter<string> = new EventEmitter<string>();

  text: string = '';

  getSegmentById(id: string) {
    return this.segments.find((segment) => segment.id === id);
  }

  get filteredSegments() {
    if (this.text === '') return this.segments ?? [];
    return this.segments.filter((segment) =>
      segment.name.toLowerCase().includes(this.text.toLowerCase())
    );
  }

  toggleSegment(segment: ProductBlendedSegmentNode) {
    if (this.selected.includes(segment.id)) {
      this.onDeselect.emit(segment.id);
    } else {
      this.onSelect.emit(segment);
    }
  }

  isSelected(segmentId: string) {
    return this.selected.includes(segmentId);
  }

  clearFilter() {
    this.text = '';
  }
}
