import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import pkg from '../../../../../package.json';
import { KOHORT_COMPANY_NAME } from '../../app.constants';
import { AccountService } from '../../services/account.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  public version = pkg.version;
  public accountId$: Observable<string>;
  public companyName = KOHORT_COMPANY_NAME;

  constructor(private accountService: AccountService) { }

  ngOnInit(): void {
    this.accountId$ = this.accountService.getAuthenticationState().pipe(
      map(user => user?.org_id)
    );
  }

}
