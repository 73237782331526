import {Injectable, inject} from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import {Observable, map} from 'rxjs';
import {ConfigAwareService} from '../../../../core/config/models/config';
import { IScenario } from '../../model/scenario.model';
import { Store } from '@ngrx/store';
import { trackItem } from 'src/app/store';

@Injectable({providedIn: 'root'})
export class RunwayService extends ConfigAwareService {
  get baseUrl() {
    return this.environment.resourceServer.runwayUrl;
  }
  private store = inject(Store);

  constructor(
    private http: HttpClient
  ) {
    super();
  }

  processScenario(scenario: IScenario): Observable<HttpResponse<null>> {
    const {id, name, product} = scenario;
    return this.http.post<null>(this.baseUrl + `scenarios/${id}/models`, {
      observe: 'response'
    }).pipe(
      map((response) => {
        this.store.dispatch(trackItem({ 
          label: 'Scenario Run', 
          data: {
            product_id: product.id,
            product_name: product.name,
            scenario_id: id,
            scenario_name: name
          }
        }));
        return response;
      })
    );
  }

  diff(scenarioId: number): Observable<any> {
    return this.http.get<null>(this.baseUrl + `scenarios/${scenarioId}/updates`);
  }
}
