export interface IUser {
  [key: string]: any;
  createdAt?: string | Date;
  id?: string;
  name?: string;
  email?: string;
  hasPassword?: boolean;
  isInternal?: boolean;
  lastActive?: string;
  loginCount?: number;
  organisation?: IOrganisation;
  picture?: string;
  role?: IRole;
  invitationId?: string;
  default?: string;
}

export interface IRole {
  id?: string;
  name?: string;
  description?: string;
}

export interface IOrganisation {
  id?: string;
  name: string;
  logo: string;
  role?: IRole;
  slackWebhook?: string | null;
  defaultConnectionStrategy?: string;
  enabledConnections?: IEnabledConnection[];
}

export interface IEnabledConnection {
  connectionId: string;
  name: string;
  strategy: string;
}

export enum UserRole {
  USER = 'USER',
  ADMIN = 'ADMIN',
  SUPER_ADMIN = 'SUPER ADMIN'
}

export enum UserDetails {
  INVITED = 'Invited User',
  INVITED_LIST = 'invitations',
  USER_LIST = 'users',
}

export class CreateOrganisationUserInvitation {
  constructor(
    public email: string,
    public role: string,
    public connectionId?: string,
  ){}
}

export class UpdateUserProfile implements IUser {
  constructor(
    public name: string,
    public picture: string,
  ){}
}

export class UpdateUserRole implements IRole {
  constructor(
    public id: string,
  ){}
}

export class UpdateOrganisation implements IOrganisation {
  constructor(
    public name: string,
    public logo: string,
    public slackWebhook: string | null,
    public defaultConnectionStrategy: string,
  ){}
}
