import { gql } from 'apollo-angular';

const PRODUCT_JOB_FRAGMENT = gql`
  fragment ProductJob on ProductJobNode {
    state
    errors
    __typename
  }`

const PRODUCT_FRAGMENT = gql`
  ${PRODUCT_JOB_FRAGMENT}
  fragment Product on ProductNode {
    id
    name
    state
    orgId
    processingStart
    processingEnd
    config
    metrics
    internal
    scenarioCount
    productCategories
    segmentCount
    currentProductJob {
      ...ProductJob
    }
    frozen
    __typename
  }`

export const PRODUCT_QUERY = gql`
  ${PRODUCT_FRAGMENT}
  query getProduct($id: BigInt!) {
    product(id: $id) {
      id
      state
      ...Product
    }
  }
`;

export const PRODUCT_MARKETING_QUERY = gql`
  query getProductMarketing($id: BigInt!) {
    marketingConfig: productMarketingConfig(productId: $id) {
      defaultBlendedSegmentSplit
    }
  }
`

export const PRODUCT_MARKETING_MUTATION = `
  mutation updateProductMarketing($id: BigInt!, $productMarketingConfig: ProductMarketingConfigInput!) {
    updateProductMarketingConfig(productId: $id, productMarketingConfig: $productMarketingConfig) {
      marketingConfig: productMarketingConfig {
        defaultBlendedSegmentSplit
      }
    }
  }
`

export const PRODUCT_LIST_QUERY = gql`
  ${PRODUCT_FRAGMENT}
  query getProducts($page: Int, $pageSize: Int, $sort: [ProductNodeSortEnum], $filter: ProductFilter) {
    products(page: $page, pageSize: $pageSize, sort: $sort, filters: $filter) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          ...Product
        }
      }
    }
  }
`;

export const PRODUCT_FILE_LIST_QUERY = gql`
  query getProductFiles($productId: BigInt!, $page: Int, $pageSize: Int, $sort: [ProductFileNodeSortEnum], $filter: ProductFileFilter) {
    productFiles(productId: $productId, page: $page, pageSize: $pageSize, sort: $sort, filters: $filter) {
      totalCount
      edges {
        node {
          id
          filename
          state
          processingStart
          processingEnd
          productId
          errors
          type
        }
      }
    }
  }
`;

export const PRODUCT_SEGMENTS_QUERY = gql`
  query getSegments($productId: BigInt!, $sort: [ProductSegmentNodeSortEnum]) {
    productSegments(productId: $productId, sort: $sort) {
      totalCount
      edges {
        node {
          id
          name
          __typename
        }
      }
    }
  }
`;
