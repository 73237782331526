import { gql } from 'apollo-angular';

export const GET_DASHBOARDS = gql`
  query getOrgDashboards($page: Int, $pageSize: Int) {
    orgDashboards: customDashboards(page: $page, pageSize: $pageSize, folderType: GROUP) {
      id
      description
      title
      viewCount
    }
    myDashboards: customDashboards(page: $page, pageSize: $pageSize, folderType: PERSONAL) {
      id
      description
      title
      viewCount
    }
  }
`;
