<div class="text-black px-5 w-full text-left group flex items-center text-sm gap-4">
  @if (enableFavourite) {
    <app-overlay-popup label="{{ isFavouriteLimit ? ('scenario.favourite.limitReached' | translate) : undefined }}">
      <button
        type="button"
        data-cy="favourite"
        [disabled]="isFavouriteLimit"
        (click)="toggleFavourite()"
        class="p-1.5 active hover:bg-soft-blue rounded">
        <app-svg-images
          class="block h-3.5 group-hover:text-opacity-50 transition-all"
          [ngClass]="{
            'text-kohort-primary': isFavourite,
            'text-black/60': !isFavourite
          }"
          [svgName]="isFavourite ? 'heart': 'heart-outline'">
        </app-svg-images>
      </button>
    </app-overlay-popup>
  }
  <div class="grow">
    <ng-content></ng-content>
  </div>
</div>