import { gql } from 'apollo-angular';
import { ORG_FRAGMENT } from './queries';

export const CREATE_ORG_MUTATION = gql`
  ${ORG_FRAGMENT}
  mutation CreateOrganisation($name: String!) {
    createOrganisation(name: $name) {
      organisation {
        ...OrgBase
      }
    }
  }
`;

export const ADD_ORG_LOGO_MUTATION = gql`
 ${ORG_FRAGMENT}
  mutation AddOrganisationLogo($imageFile: Upload!, $id: String!) {
    addOrganisationLogo(logoFile: $imageFile, id: $id) {
      organisation {
        ...OrgBase
      }
    }
  }
`;

export const UPDATE_ORG_LOGO_MUTATION = gql`
 ${ORG_FRAGMENT}
  mutation UpdateOrganisationLogo($imageFile: Upload!, $id: String!) {
    updateOrganisationLogo(logoFile: $imageFile, id: $id) {
      organisation {
        ...OrgBase
      }
    }
  }
`;

export const DELETE_ORG_LOGO_MUTATION = gql`
  ${ORG_FRAGMENT}
  mutation DeleteOrganisationLogo($id: String!) {
    deleteOrganisationLogo(id: $id) {
      organisation {
        ...OrgBase
      }
    }
  }
`;

export const ADD_PROFILE_IMAGE_MUTATION = gql`
  mutation AddUserProfileImage($imageFile: Upload!, $id: String!) {
    addUserProfileImage(imageFile: $imageFile, id: $id) {
      user {
        id
        name
        picture
      }
    }
  }
`;

export const UPDATE_PROFILE_IMAGE_MUTATION = gql`
  mutation UpdateUserProfileImage($imageFile: Upload!, $id: String!) {
    updateUserProfileImage(imageFile: $imageFile, id: $id) {
      user {
        id
        name
        picture
      }
    }
  }
`;

export const DELETE_PROFILE_IMAGE_MUTATION = gql`
  mutation DeleteUserProfileImage($id: String!) {
    deleteUserProfileImage(id: $id) {
      user {
        id
        name
        picture
      }
    }
  }
`;