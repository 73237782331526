import { gql } from 'apollo-angular';

export const ORG_FRAGMENT = gql`
  fragment OrgBase on OrganisationNode {
    id
    name
    slackWebhook
    logo
    __typename
  }`

export const ORGANISATIONS_QUERY = gql`
  ${ORG_FRAGMENT}
  query getOrganisations($page :Int, $pageSize: Int) {
    organisations(page: $page, pageSize: $pageSize) {
      totalCount
      edges {
        node {
          ...OrgBase
        }
      }
    }
  }
`;

export const ORGANISATION_QUERY = gql`
  ${ORG_FRAGMENT}
  query getOrganisation($orgId: String!) {
    organisation(id: $orgId) {
      ...OrgBase
      defaultConnectionStrategy
      enabledConnections {
        connectionId
        name
        strategy
      }
    }
  }
`;

const USER_FRAGMENT = gql`
  fragment User on UserNode {
    id
    name
    loginCount
    email
    picture
    hasPassword
    createdAt
    isInternal
    __typename
  }
`

const ROLE_FRAGMENT = gql`
  fragment Role on RoleNode {
    id
    name
    description
    __typename
  }
`

export const USER_QUERY = gql`
  ${ROLE_FRAGMENT}
  ${USER_FRAGMENT}
  ${ORG_FRAGMENT}
  query getUser($id: String!) {
    user(id: $id) {
      ...User,
      organisation {
        ...OrgBase
      }
      role {
        ...Role
      }
    }
  }
`;

export const USER_ORGANISATIONS_QUERY = gql`
  ${ROLE_FRAGMENT}
  query getUserOrganisations($id: String!, $sort: UserOrganisationNodeSortEnum, $page: Int, $pageSize: Int!) {
    userOrganisations(userId: $id, sort: $sort, page: $page, pageSize: $pageSize) {
      totalCount
      edges {
        node {
          id
          default
          name
          logo
          slackWebhook
          role {
            ...Role
          }
        }
      }
    }
  }
`

export const ROLES_QUERY = gql`
  ${ROLE_FRAGMENT}
  query getRoles($page: Int, $pageSize: Int) {
    roles(page: $page, pageSize: $pageSize) {
      totalCount
      edges {
        node {
          ...Role
        }
      }
    }
  }
`

export const ORGANISATION_USERS_QUERY = gql`
  ${ROLE_FRAGMENT}
  query getOrganisationUsers($orgId: String!, $page: Int, $pageSize: Int!, $sort: OrganisationNodeSortEnum) {
    organisationUsers(page: $page, pageSize: $pageSize, orgId: $orgId, sort: $sort) {
      totalCount
      edges {
        node {
          id
          name
          email
          picture
          lastActive
          invitationId
          role {
            ...Role
          }
        }
      }
    }
  }
`;

export const INIT_APP_QUERY = gql`
  ${ROLE_FRAGMENT}
  ${USER_FRAGMENT}
  ${ORG_FRAGMENT}
  query initialiseApp($userId: String!, $page: Int, $pageSize: Int!, $sort: UserOrganisationNodeSortEnum, $isNormalUser: Boolean!, $isSuperAdmin: Boolean!) {
    user(id: $userId) {
      ...User,
      organisation {
        ...OrgBase
      }
      role {
        ...Role
      }
    }
    userOrganisations(userId: $userId, sort: $sort, page: $page, pageSize: $pageSize) {
      totalCount
      edges {
        node {
          id
          default
          name
          logo
          slackWebhook
          role {
            ...Role
          }
        }
      }
    }

    roles (page: $page, pageSize: $pageSize) @skip(if: $isNormalUser) {
      totalCount
      edges {
        node {
          ...Role
        }
      }
    }

    organisations (page: $page, pageSize: $pageSize) @include(if: $isSuperAdmin) {
      totalCount
      edges {
        node {
          ...OrgBase
        }
      }
    }
  }
`;
