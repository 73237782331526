import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Directive, ElementRef, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { of, Subscription } from 'rxjs';

@Directive({
  selector: '[appDropdownScroll]'
})
export class DropdownScrollDirective implements AfterViewInit, OnInit, OnDestroy {

  
  private dropdownElement: HTMLElement;
  private heightSubscription: Subscription;

  constructor(private elementRef: ElementRef, @Inject(DOCUMENT) public document: Document) { }

  ngOnDestroy(): void {
    this.heightSubscription?.unsubscribe();
  }

  ngOnInit(): void {
      this.dropdownElement = this.elementRef.nativeElement as HTMLElement;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    if(this.dropdownElement?.hasAttribute('style')) {
      this.dropdownElement?.removeAttribute('style');
    }
    this.setMaxHeight();
  }

  ngAfterViewInit(): void {
    this.heightSubscription = of(true).subscribe(() => this.setMaxHeight());
  }

  private setMaxHeight(): void {
    if(!this.dropdownElement) return;
    const BOTTOM_OFFSET = 150;
    const notificationHeight = this.document.querySelector('app-notification')?.getBoundingClientRect().height || 0;
    const breadcrumbsHeight = this.document.querySelector('app-breadcrumbs-menu')?.getBoundingClientRect().height || 0;
    const height = breadcrumbsHeight + notificationHeight + BOTTOM_OFFSET;
    const maxHeight = `${window.innerHeight - height}px`;
    this.dropdownElement?.setAttribute('style', `max-height: ${maxHeight}; overflow-y: auto;`);
  }

}
