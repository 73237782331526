import { createFeatureSelector, createSelector } from '@ngrx/store';
import { scenarioFeatureKey, State, selectAll, selectEntities } from '../reducers/scenario.reducer';
import { ScenarioJob } from '../../views/scenario/model/scenario-job-dto.model';
import moment from 'moment';
import { FAVOURITE_SCENARIOS_LIMIT } from 'src/app/core/app.constants';
import { ScenarioOverrideEvent } from 'src/app/views/scenario/model/scenario-override.models';

export const selectScenarioState = createFeatureSelector<State>(
  scenarioFeatureKey
);

export const selectScenario = createSelector(
  selectScenarioState,
  (state) => state.scenario
);

export const selectScenarioId = createSelector(
  selectScenarioState,
  (state) => state.scenario.id
);


export const selectScenarioJobs = createSelector(
  selectScenarioState,
  (state) => state.jobs
);

export const selectScenarioLatestJob = createSelector(
  selectScenarioState,
  (state) => state.scenario.currentScenarioJob || new ScenarioJob()
);

export const getOverrideEvents = createSelector(
  selectScenarioState,
  (state) => state.overrideEvents
);

export const getFileOverrideEvent = (fileId: number) => createSelector(
  selectScenarioState,
  (state) => {
    const file = state.scenario.files.find(file => file.id === fileId);
    return new ScenarioOverrideEvent(
      state.scenario.id,
      file?.type,
      fileId,
      file?.filename,
      file?.state,
      file?.errors
    );
  }
);

export const selectPillarModels = createSelector(
  selectScenarioState,
  (state) => {
    return state.allPillarModels;
  }
);

export const selectScenarioDateRange = createSelector(
  selectScenario,
  (scenario) => ({
    min: moment.utc(scenario.config.inputRange.start).toDate(),
    max: moment.utc(scenario.config.output.range.date).toDate(),
    end: moment.utc(scenario.config.inputRange.end).toDate(),
  })
)


export const selectInputRangeClasses = createSelector(
  selectScenarioDateRange,
  (dateRange) => (cellDate, view) => cellDate <= dateRange.end && view === 'month' ? 'input-range' : ''
)

// UI V2 - Selectors
export const selectScenarios = createSelector(
  selectScenarioState, selectAll,
);

export const selectAllScenarios = createSelector(
  selectScenarioState, ({breadcrumbScenarios}) => breadcrumbScenarios
);

// only show the first 10 favourite scenarios
export const selectFavouriteScenarios = createSelector(
  selectAllScenarios,
  (scenarios) => (scenarios.filter(({favourite}) => favourite).filter((s,i) => i < FAVOURITE_SCENARIOS_LIMIT))
);

export const selectPaginatedScenarios = createSelector(
  selectScenarioState, selectScenarios,
  ({totalCount}, scenarios) => ({scenarios, totalCount})
);

export const selectScenarioById = (id: number) => createSelector(
  selectScenarioState, selectEntities,
  (scenarios) => scenarios.entities[id],
);

export const selectDefaultScenario = createSelector(
  selectAllScenarios,
  (scenarios) => scenarios.find(s => s.default),
);

export const selectBudgetScenario = createSelector(
  selectAllScenarios,
  (scenarios) => scenarios.find(s => s.budget),
);

export const selectOverrideFiles = createSelector(
  selectScenario,
  (scenario) => scenario?.files
);

export const loadingScenariosState = createSelector(
  selectScenarioState,
  (state) => state.loading
);
