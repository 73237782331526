import {Action, createReducer, on} from '@ngrx/store';
import * as UserManagementActions from '../actions/user-management.actions';
import { IUser, IRole, IOrganisation } from '../../model/user-management.model';
import { IPaginatedDto } from 'src/app/core/model/app.model';

export const userManagementFeatureKey = 'userManagement';

export interface State {
  user: IUser;
  roles: IPaginatedDto<IRole>;
  loading: boolean;
  error?: any;
}

export const initialState: State = {
  user: null,
  roles: { results: [], total: 0 },
  loading: false,
  error: ''
};

export const userManagementReducer = createReducer(
  initialState,
  on(UserManagementActions.loadApp, UserManagementActions.loadAuthUser, UserManagementActions.updateUserProfile, UserManagementActions.uploadUserImage, UserManagementActions.deleteUserImage, (state) => {
    return {...state, error: '', loading: true};
  }),
  on(UserManagementActions.loadAppSuccess, (state, action) => {
    const {orgId, data} = action;
    const {roles, organisations} = data || {};
    const isInitUser = (!state.user || state.user?.id === action.data?.user?.id);
    const userOrganisations = isInitUser ? action.data?.userOrganisations : [];
    const role = isInitUser ? userOrganisations?.results?.find(org => org.id === orgId)?.role : state.user?.role;
    return {
      ...state, 
      user: {
        ...state.user, 
        ...action.data?.user, 
        currentOrgId: orgId,
        ...(role && {role}), 
        organisations: userOrganisations 
      },
      ...(roles && {roles}), 
      ...(organisations && {organisations}), 
      error: '', 
      loading: false
    };
  }),
  on(UserManagementActions.loadAppFailure, UserManagementActions.loadAuthUserFailure, UserManagementActions.updateUserProfileFailure, UserManagementActions.uploadUserImageFailure, UserManagementActions.deleteUserImageFailure, (state, action) => {
    return {...state, error: action.error, loading: false};
  }),
  on(UserManagementActions.loadAuthUserSuccess, (state, action) => {
    const user = {...state.user, ...action.user, currentOrgId: action.orgId };
    return {...state, user, error: '', loading: false};
  }),
  on(UserManagementActions.updateUserProfileSuccess, UserManagementActions.uploadUserImageSuccess, UserManagementActions.deleteUserImageSuccess, (state, action) => {
    return {...state, user: { ...state.user, ...action.user }, error: '', loading: false};
  }),
  on(UserManagementActions.updateAuthUserOrganisation, (state, action) => {
    const {logo, name} = action.organisation.changes;
    const results = state.user.organisations?.results.map((org: IOrganisation) => org.id === action.organisation.id ? {...org, logo, name} : org);
    return {
      ...state, 
      user: {
        ...state.user,
        organisations: {
          ...state.user.organisations,
          results
        }
      }
    }
  })
);

export function reducer(state: State | undefined, action: Action) {
  return userManagementReducer(state, action);
}
