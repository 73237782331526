import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-breadcrumbs-dropdown',
  templateUrl: './breadcrumbs-dropdown.component.html'
})
export class BreadcrumbsDropdownComponent {
  @Input() icon: string = '';
  @Input() isScenarioRoute: boolean = false;
  @Input() isProductRoute: boolean = false;
  @Input() hasDropdownList: boolean = true;
  @Input() isTotalItems: boolean = true;
  @Input() redirectTo: string = '';
  @Output() loadMore = new EventEmitter<boolean>();

  constructor(private router: Router) {}

  redirect(): void {
    if (this.redirectTo?.length) {
      this.router.navigateByUrl(this.redirectTo);
    }
  }

  isIntersecting(status: boolean): void {
    if (status) {
      this.loadMore.emit(true);
    }
  }
}
