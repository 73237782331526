import { Component } from '@angular/core';
import { ButtonComponent } from '../button/button.component';
import { SvgImagesComponent } from '../svg-images/svg-images.component';

@Component({
  selector: 'app-close-button',
  templateUrl: './close-button.component.html',
  styles: [
    `
      :host {
        display: inline-block;
      }
    `
  ],
  standalone: true,
  imports: [SvgImagesComponent]
})
export class CloseButtonComponent extends ButtonComponent {

  constructor() { 
    super();
  }

}
