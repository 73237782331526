import { NgControl, Validators } from '@angular/forms';
import { AfterViewChecked, Directive, Input, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[requiredControl]',
  exportAs: 'isControlRequired'
})
export class RequiredControlDirective implements OnChanges, AfterViewChecked {

  @Input() requiredControl;

  ngOnChanges(changes: SimpleChanges) {
  }

  constructor(private control: NgControl) {
  }

  ngAfterViewChecked(): void {
    if (this.control.control.value) return;
    if (this.requiredControl || this.requiredControl === 0) {
      this.control.control.addValidators(Validators.required);
    } else {
      this.control.control.removeValidators(Validators.required);
    }
    this.control.control.updateValueAndValidity({emitEvent: false});
    this.control.control.markAsTouched({onlySelf: true});
  }

}
