import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, UntypedFormGroup } from '@angular/forms';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { EventsSegments, IEventSegment } from '../../../model/events.model';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { debounceTime, map, startWith } from 'rxjs/operators';
import { Helpers } from '../../../../../core/shared/helpers';

@Component({
  selector: 'app-segment-input',
  templateUrl: './segment-input.component.html',
  styleUrls: ['./segment-input.component.scss']
})
export class SegmentInputComponent implements OnInit {
  @Input() set segmentsList(value: IEventSegment[]) {
    this._segments.next(value);
  }

  public get segmentsList(): IEventSegment[] {
    return this._segments.value;
  }

  @Input() formGroup: UntypedFormGroup;
  @Input() multi = true;
  @Input() e2eTag = '';
  @Input() appearance = 'outline';
  @Input() suffix = false;
  @Input() enableGlobalSelect = true;
  @Input() showLabel = true;
  @Input() searchEnabled = false;
  @Input() className = '';
  @Input() helpText = '';
  @Input() label = '';
  @Input() placeholder = '';
  @Input() control = 'segments';
  @Input() disabledOptions = [];
  globalValue = EventsSegments.GLOBAL;
  _segments: BehaviorSubject<IEventSegment[]> = new BehaviorSubject([]);

  searchTerm = new FormControl<string>('');
  visibleSegments$: Observable<IEventSegment[]>;

  @ViewChild('segment', {static: true}) segment: MatSelect

  constructor() {
  }

  get segments(): AbstractControl {
    return this.formGroup?.get('segments');
  }

  ngOnInit(): void {
    this.visibleSegments$ = combineLatest([
      this.searchTerm.valueChanges.pipe(startWith('')),
      this._segments.asObservable()
    ]).pipe(
      debounceTime(0),
      map(([searchTerm, segments]) => segments.filter(segment => segment.name.toLowerCase().includes(searchTerm.toLowerCase())))
    );
  }

  reset($event: Event) {
    $event.stopPropagation();
    this.segments.patchValue('');
  }

  get hasValue() {
    return this.segments.value && this.segments.value.length;
  }

  toggleSelection($change: MatSelectChange): void {
    if (this.multi && this.enableGlobalSelect) {
      Helpers.resetGlobalSegment($change, () => {
        $change.source?.options.forEach(option => {
          if (option.value === this.globalValue) {
            option.select();
          } else {
            option.deselect();
          }
        })
      });
    }
    if (!this.multi) {
      this.segment.close();
    }
  }
}
