import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'errorTranslate'
})
export class ErrorTranslatePipe implements PipeTransform {

  constructor(private translateService: TranslateService) {}

  transform(errors: unknown, ...args: unknown[]): unknown {
    const keys = Object.keys(errors);
    return keys.map((key) => {
      return this.translateService.instant(`milestones.errors.${key}`)
    }).join('\n')
  }

}
