import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, mergeMap } from "rxjs/operators";
import * as fromProduct from "../actions/product.actions";
import * as fromAuth from "../../views/user-management/redux/actions";
import { throwError } from "rxjs";
import { ProductService } from "src/app/views/product/services/product.service";
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from 'src/app/core/services/alert.service';
import { GraphQLParams, IGraphQLParams } from "src/app/graphql/graphql.models";
import { DEFAULT_CURRENT_PAGE, DEFAULT_PAGE_SIZE, INTERNAL_USER_LOCAL_STORAGE_KEY } from "src/app/core/app.constants";
import { HelpersService } from "src/app/core/services/helpers.service";

@Injectable()
export class ProductEffects {
  constructor(
    private actions$: Actions,
    private productService: ProductService,
    private translateService: TranslateService,
    private helpersService: HelpersService,
    private messageService: AlertService
  ) {}

  loadPaginatedProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromProduct.fetchProducts, fromAuth.organisationChanged),
      map((data) => {
        return (data.type === fromAuth.organisationChanged.type) ? {} : data.paginationParams;
      }),
      mergeMap((paginationParams) =>
        this.productService.findAll(this.getProductsParams((paginationParams))).pipe(
          map(({ results, totalCount }) => fromProduct.loadProducts({products: results, totalCount})),
          catchError((err) => {
            const errorMsg = this.translateService.instant('error.internalServerError');
            this.messageService.add({
              severity: 'error',
              summary: this.translateService.instant('error.error'),
              detail: `${errorMsg}: ${err.error?.message}`,
              life: 8000
            });
            return throwError(() => err);
          })
        )
      )
    )
  );

  toggleProductInternalData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAuth.toggleInternalData),
      map(({paginationParams}) => {
        const hasProductRouteData = paginationParams.filter.productId;
        // Refetch products breadcrumbs list
        if (hasProductRouteData) {
          return fromProduct.fetchProducts({paginationParams: {...paginationParams, page: DEFAULT_CURRENT_PAGE, pageSize: 100}});
        }
        // Refetch products on list page
        return fromProduct.fetchProducts({paginationParams})
      })
    )
  );
  
  getProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromProduct.fetchProduct),
      mergeMap(({id}) =>
        this.productService.find(id).pipe(
          map((product) => fromProduct.addProduct({product})),
          catchError((err) => {
            const errorMsg = this.translateService.instant('error.internalServerError');
            this.messageService.add({
              severity: 'error',
              summary: this.translateService.instant('error.error'),
              detail: `${errorMsg}: ${err.error?.message}`,
              life: 8000
            });
            return throwError(() => err);
          })
        )
      )
    )
  );

  deleteProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromProduct.deleteProduct),
      mergeMap(({product}) =>
        this.productService.delete(product).pipe(
          map(() => (fromProduct.deleteProductById({id: product.id}))),
          catchError((err) => {
            const errorMsg = this.translateService.instant('error.internalServerError');
            this.messageService.add({
              severity: 'error',
              summary: this.translateService.instant('error.error'),
              detail: `${errorMsg}: ${err.error?.message}`,
              life: 8000
            });
            return throwError(() => err);
          })
        )
      )
    )
  );

  fetchEventsSegments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromProduct.loadProductSegments),
      mergeMap((props) =>
        this.productService.segments(props.productId, ['NAME_ASC']).pipe(
          map((segments) =>
            fromProduct.loadProductSegmentsSuccess({segments})
          ),
          catchError((err) => {
            const errorMsg = this.translateService.instant('error.internalServerError');
            this.messageService.add({
              severity: 'error',
              summary: this.translateService.instant('error.error'),
              detail: `${errorMsg}: ${err.error?.message}`,
              life: 8000
            });
            return throwError(() => err);
          })
        )
      )
    )
  );

  private getProductsParams(paginationParams: IGraphQLParams): GraphQLParams {
    const { page, pageSize, sort, filter } = paginationParams;
    const { searchText } = filter || {};
    const internal = this.helpersService.getFromLocalStorage(INTERNAL_USER_LOCAL_STORAGE_KEY).isInternal;

    return {
      filter: {
        ...(searchText && {nameIlike: `%${searchText}%`}),
        ...((typeof internal === 'boolean' && !internal) && {internal: false})
      },
      sort: (sort && sort.length > 0 ? sort : ['name', 'asc']),
      pageSize: +pageSize || DEFAULT_PAGE_SIZE,
      page: +page || DEFAULT_CURRENT_PAGE
    }
  }
}
