import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State, selectAll } from '../reducers/product.reducer';

export const productFeatureKey = 'product';

export const productState = createFeatureSelector<State>(
    productFeatureKey
);

export const getProductSegments = createSelector(
  productState,
  (state) => state?.segments
);

export const selectProducts = createSelector(
  productState, selectAll,
);

export const selectAllProducts = createSelector(
  selectProducts,
 (products) => products
);

export const selectPaginatedProduct = createSelector(
  productState, selectProducts,
  ({totalCount}, products) => ({products, totalCount})
);

export const selectProductById = (id: number) => createSelector(
  productState,
  ({entities}) => entities[id]
);

export const loadingProductsState = createSelector(
  productState,
  (state) => state.loading
);