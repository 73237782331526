<div class="flex items-center justify-end w-full gap-12 text-sm" data-cy="pagination">
  <app-dropdown-menu menuPosition="top-right">
    <button buttonContent type="button" class="bg-white text-black text-sm rounded-lg px-3 h-10 active border border-gray-300 flex items-center gap-4 shadow-sm hover:bg-gray-100">
      <span>
        <span class="font-medium">{{ pageSize }} </span> <span translate="global.itemsPerPage"></span>
      </span>
      <div class="h-3">
        <app-svg-images svgName="chevron-down"></app-svg-images>
      </div>
    </button>
    <div menuItems class="text-sm -m-1.5">
      @for (size of pageSizeOptions; track size) {
        <app-dropdown-menu-item (onClick)="onPageSizeChange(size)" [attr.data-cy]="size">
          <div class="flex items-center justify-between gap-10" [ngClass]="{'bg-soft-blue -m-2 p-2 rounded': size === pageSize}">
            <span [ngClass]="{ 'font-medium': size === pageSize }">
              {{ size }}
            </span>
            <div class="h-4">
              @if (size === pageSize) {
                <app-svg-images svgName="check"></app-svg-images>
              }
            </div>
          </div>
        </app-dropdown-menu-item>
      }
    </div>
  </app-dropdown-menu>
  <div class="flex items-center justify-end gap-3">
    <span>
      <span class="font-medium">{{ startIndex }}</span>
      <span> - </span>
      <span class="font-medium">{{ endIndex }}</span>
      <span> of </span>
      <span class="font-medium">{{ numberOfItems }}</span>
    </span>
    <div
      class="flex items-center rounded-lg border border-gray-300 divide-x divide-gray-300 overflow-hidden shadow-sm bg-white text-black text-sm h-9"
    >
      <button
        data-cy="previous-page"
        class="px-2.5 bg-gray-50 enabled:hover:bg-gray-100 transition-all enabled:active disabled:opacity-30 text-black h-full"
        (click)="onPageChange(currentPage - 1)"
        [disabled]="currentPage <= 1"
      >
        <div class="h-3.5">
          <app-svg-images svgName="chevron-left"></app-svg-images>
        </div>
      </button>
      <button
        data-cy="next-page"
        class="px-2.5 bg-gray-50 enabled:hover:bg-gray-100 transition-all text-black h-full disabled:opacity-30 enabled:active"
        (click)="onPageChange(currentPage + 1)"
        [disabled]="currentPage === numberOfPages"
      >
        <div class="h-3.5">
          <app-svg-images svgName="chevron-right"></app-svg-images>
        </div>
      </button>
    </div>
  </div>
</div>
