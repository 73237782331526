import { createAction, props } from '@ngrx/store';
import { IGraphQLParams } from '../../../../graphql/graphql.models';
import { Update } from '@ngrx/entity';
import { IOrganisation, UpdateOrganisation } from '../../model/user-management.model';
import { IPaginatedDto } from 'src/app/core/model/app.model';


export const fetchOrganisation = createAction(
  '[Organisation/API] Fetch Organisation',
  props<{ orgId: string }>()
);

export const fetchOrganisations = createAction(
  '[Organisation/API] Fetch Organisations',
  props<{ paginationParams?: IGraphQLParams }>()
);

export const loadMoreOrganisations = createAction(
  '[Organisation/API] Load More Organisations',
  props<{ paginationParams?: IGraphQLParams }>()
);

export const createOrganisation = createAction(
  '[Organisation] Create Organisation',
  props<{ name: string }>()
);

export const addOrganisation = createAction(
  '[Organisation/API] Add Organisation',
  props<{ organisation: IOrganisation }>()
);

export const upsertOrganisation = createAction(
  '[Organisation/API] Upsert Organisation',
  props<{ organisation: IOrganisation }>()
);

export const loadOrganisations = createAction(
  '[Organisation/API] Load Organisations',
  props<{ organisations: IOrganisation[], totalCount?: number; }>()
);

export const addOrganisations = createAction(
  '[Organisation/API] Add Organisations',
  props<{ organisations: IOrganisation[], totalCount?: number; }>()
);

export const upsertOrganisations = createAction(
  '[Organisation/API] Upsert Organisations',
  props<{ organisations: IPaginatedDto<IOrganisation>; }>()
);

export const updateOrganisation = createAction(
  '[Organisation] Update Organisation',
  props<{ orgId: string, organisation: UpdateOrganisation }>()
);

export const updateOrganisationSuccess = createAction(
  '[Organisation/API] Update Organisation',
  props<{ organisation: Update<IOrganisation> }>()
);

export const updateOrganisations = createAction(
  '[Organisation/API] Update Organisations',
  props<{ organisations: Update<IOrganisation>[] }>()
);

export const deleteOrganisationById = createAction(
  '[Organisation/API] Delete Organisation By ID',
  props<{ id: string }>()
);

export const deleteOrganisations = createAction(
  '[Organisation/API] Delete Organisations',
  props<{ ids: string[] }>()
);

export const deleteOrganisation = createAction(
  '[Organisation/API] Delete Organisation',
  props<{ organisation: IOrganisation; }>()
);


export const uploadLogo = createAction(
  '[Image] Upload Logo',
  props<{ id: string, file: File, operation: string }>()
);

export const uploadLogoSuccess = createAction(
  '[Image] Upload Logo Success',
  props<{ organisation: IOrganisation }>()
);

export const uploadLogoFailure = createAction(
  '[Image] Upload Logo Failure',
  props<{ error: any }>()
);

export const deleteLogo = createAction(
  '[Image] Delete Logo',
  props<{ id: string, operation: string }>()
);

export const deleteLogoSuccess = createAction(
  '[Image] Delete Logo Success',
  props<{ organisation: IOrganisation }>()
);

export const deleteLogoFailure = createAction(
  '[Image] Delete Logo Failure',
  props<{ error: any }>()
);

export const organisationApiError = createAction(
  '[Organisation/API] Organisation Api Error',
  props<{ error: any }>()
);

export const clearOrganisations = createAction(
  '[Organisation/API] Clear Organisations'
);