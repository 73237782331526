import {
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SvgImagesComponent } from '../svg-images/svg-images.component';
import { TranslateModule } from '@ngx-translate/core';
import { DropdownMenuComponent } from '../dropdown-menu/dropdown-menu.component';
import { DropdownMenuItemComponent } from '../dropdown-menu/dropdown-menu-item/dropdown-menu-item.component';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  standalone: true,
  imports: [CommonModule, SvgImagesComponent, FormsModule, TranslateModule, DropdownMenuComponent, DropdownMenuItemComponent],
})

export class PaginationComponent {
  @Input() pageSize: number = 20;
  @Input() currentPage: number = 1;
  @Input() numberOfItems: number = 1;
  @Input() pageSizeOptions: number[] = [10, 20, 50];
  @Output() pageSizeChange = new EventEmitter<number>();
  @Output() currentPageChange = new EventEmitter<number>();

  numberOfPages: number = 10;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['pageSize'] || changes['numberOfItems']) {
      this.numberOfPages = Math.ceil(this.numberOfItems / this.pageSize);
      if (this.currentPage > this.numberOfPages && this.numberOfItems > 0) {
        this.currentPage = this.numberOfPages;
        this.currentPageChange.emit(this.currentPage);
      }
    }
  }

  onPageSizeChange(newSize: number): void {
    const size = Number(newSize);
    if (!isNaN(size)) {
      this.pageSizeChange.emit(size);
    }
  }

  get startIndex(): number {
    return (this.currentPage - 1) * this.pageSize + 1;
  }

  get endIndex(): number {
    return Math.min(this.currentPage * this.pageSize, this.numberOfItems);
  }

  onPageChange(newPage: number): void {
    if (newPage >= 1 && newPage <= this.numberOfPages) {
      this.currentPage = newPage;
      this.currentPageChange.emit(newPage);
    }
  }
}
