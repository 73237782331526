import {InjectionToken} from '@angular/core';
import { AppConfig } from "../config/models/config";

export interface IntercomConfig {
  enabled?: boolean;
  appId: string;
  updateOnRouterChange?: boolean;
}

export const INTERCOM_CONFIG = new InjectionToken<IntercomConfig>('INTERCOM_CONFIG');

export function getIntercomConfig(config: AppConfig) {
  return config.intercom;
}
