import {Component, Input, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {Scenario} from '../../model/scenario.model';
import {slideDownPositionAnimation} from '../../../../core/animations';
import moment from 'moment';
import {Subscription, BehaviorSubject, interval, NEVER, Observable} from 'rxjs';
import {switchMap, mapTo} from 'rxjs/operators';

@Component({
  selector: 'app-scenario-progress',
  templateUrl: './scenario-progress.component.html',
  styleUrls: ['./scenario-progress.component.scss'],
  animations: [slideDownPositionAnimation]
})
export class ScenarioProgressComponent implements OnInit, OnChanges, OnDestroy {
  value = 0;
  valueFunc: () => number;
  mode = 'determinate';
  progressSub: Subscription;

  private progress$ = new BehaviorSubject<boolean>(false);
  public progressing$ = this.progress$.asObservable();

  @Input() scenario: Scenario;
  @Input() embedded = true;
  @Input() isNotificationBar = false;

  constructor() { }

  ngOnInit(): void {
    this.progressSub = this.progressStart(1000).subscribe(() => {
      this.setValues();
    });
  }

  ngOnChanges(): void {
    this.scenarioJobsProgress(this.scenario);
  }

  ngOnDestroy(): void {
    this.progressSub?.unsubscribe();
    this.progress$.complete();
  }

  get notificationsBarState(): string {
    return ` - ${this.scenario.currentScenarioJob.state}`;
  }

  get isEvents() {
    return this.scenario.currentScenarioJob.jobType === 'PAST_EVENTS';
  }

  private scenarioJobsProgress(scenario: Scenario) {
    const {currentScenarioJob, estimatedRuntime} = scenario;
    const {createdAt} = currentScenarioJob;

    this.mode = this.getMode(estimatedRuntime);
    this.progress$.next(true);

    this.valueFunc = this.duration(createdAt, estimatedRuntime);
  }

  private setValues(): void {
    this.value = this.valueFunc();
  }

  private duration(day: string, estimatedRuntime: number) {
    return () => {
      return !!estimatedRuntime ? (moment().diff(day, 'seconds') / estimatedRuntime) * 100 : 0;
    };
  }

  private getMode(estimatedRuntime: number): string {
    return !!estimatedRuntime ? 'determinate' : 'indeterminate';
  }

  private progressStart(seconds: number): Observable<boolean> {
    return this.progressing$.pipe(
      switchMap(isInProgress => isInProgress ? interval(seconds).pipe(mapTo(isInProgress)) : NEVER)
    );
  }

}
