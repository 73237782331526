<div class="gap-4 items-start py-2 px-3.5 text-base flex font-normal group-[.is-active]/route:font-medium rounded-lg active w-full text-white/70 hover:bg-kohort-tertiary text-white/70 hover:text-white group-hover/route:bg-kohort-tertiary group-[.is-active]/route:bg-kohort-tertiary group-[.is-active]/route:text-white">
    <div class="relative h-5 w-5 opacity-80" 
        [ngClass]="{
            'scenario' : 'group-hover/route:text-teal-green',
            'scenario-report' : 'group-hover/route:text-teal-green', 
            'report': 'group-hover/route:text-digital-blue'
        }[itemType]">
        <div class="h-4 ml-0.5">
            <app-svg-images [svgName]="itemType === 'scenario' ? 'square-3-stack-3d-solid': 'chart-bar-solid'"></app-svg-images>
        </div>
        <div class="h-3 absolute right-0 bottom-0 group-hover/route:text-kohort-primary text-gray-500">
            <app-svg-images svgName="heart"></app-svg-images>
        </div>
    </div>
    <ng-content></ng-content>
</div>
