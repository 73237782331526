@if (!isDateRange) {
  <mat-form-field subscriptSizing="dynamic"
    [formGroup]="datePickerFormGroup"
    [appearance]="appearance"
    id="datePicker"
    [ngClass]="className"
    >
    @if (showDatePickerLabel) {
      <mat-label [translate]="datePickerLabel"></mat-label>
    }
    <input
      data-lpignore="true"
      autocomplete="off"
      [formControlName]="datePickerName"
      matInput
      [min]="validateDate.min"
      [max]="validateDate.max"
      [matDatepicker]="picker"
      placeholder="Choose a date"
      />
    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
    <mat-error [translate]="datePickerError"></mat-error>
  </mat-form-field>
} @else {
  <mat-form-field subscriptSizing="dynamic" [formGroup]="datePickerFormGroup" [appearance]="appearance" id="dateRangePicker">
    @if (showDatePickerLabel) {
      <mat-label [translate]="datePickerLabel"></mat-label>
    }
    <mat-date-range-input
      [formGroupName]="datePickerName"
      [rangePicker]="picker"
      [min]="validateDate.min"
      [max]="validateDate.max"
    >
      <input matStartDate formControlName="start" placeholder="Start date" />
      <input matEndDate formControlName="end" placeholder="End date" />
    </mat-date-range-input>
    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker [startAt]="startDate" #picker></mat-date-range-picker>
    <mat-error [translate]="datePickerError"></mat-error>
  </mat-form-field>
}
