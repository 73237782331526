import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-nav-list-group',
  templateUrl: './nav-list-group.component.html'
})
export class NavListGroupComponent {
  @Input() isScenarioNavGroup: boolean = false;
  @Input() listCount: number = 0;
  constructor() { }
}
