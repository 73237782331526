import { Metrics } from '../../../core/metrics/metrics.model';
import { JobStatus } from '../../scenario/model/scenario-job-dto.model';

export interface IProduct {
  id?: number;
  name: string;
  metrics?: Metrics;
  state: string;
  processingStart?: string;
  processingEnd?: string;
  config?: ProductConfig;
  internal?: boolean;
  orgId?: string;
  scenarioCount?: number;
  productCategories?: string[];
  segmentCount?: number;
  currentProductJob?: IProductJobDto;
  frozen?: boolean;
}

export interface IProductJobDto {
  errors?: { [key: string]: any };
  id?: number;
  processingStart?: string;
  processingEnd?: string;
  state?: JobStatus | string;
}

export class Product implements IProduct {
  constructor(
    public id?: number,
    public metrics?: Metrics,
    public name: string = 'Product',
    public state = 'No data',
    public processingStart?: string,
    public processingEnd?: string,
    public config?: ProductConfig,
    public internal?: boolean,
    public orgId?: string,
    public scenarioCount?: number,
    public productCategories?: string[],
    public segmentCount?: number,
    public currentProductJob?: IProductJobDto,
    public frozen?: boolean
  ) {
  }
}

export interface PaidCategory {
  category: string;
  paidValues: string[];
}

export interface ProductConfig {
  acquisitionCategory?: ProductMarketingConfig;
  financialYearEnd: number;
  grossMarginPercentage?: number;
  paidCategory?: PaidCategory;
}

export interface IProductStats {
  revenueTotal?: number;
  customerTotal?: number;
  transactionTotal?: number;
}

export class ProductStats implements IProductStats {
  constructor(
    public revenueTotal?: number,
    public customerTotal?: number,
    public transactionTotal?: number,
  ) {
  }
}

export class ProductRevenueStats {
  constructor(public value: number, public date: Date) {
  }
}

export enum FileUploadType {
  NEW_USER_OVERRIDE = 'NEW_USER_OVERRIDE',
  MONETISATION_OVERRIDE = 'MONETISATION_OVERRIDE',
  CLF_ACTIVITY = 'CLF_ACTIVITY',
  AGGREGATED_ACTIVITY = 'AGGREGATED_ACTIVITY',
  RETENTION_OVERRIDE = 'RETENTION_OVERRIDE',
  DAU_MULTIPLIER = 'DAU_MULTIPLIER',
}

export enum FileState {
  NEW = 'NEW',
  PROCESSING = 'PROCESSING',
  PROCESSED = 'PROCESSED',
  FAILED = 'FAILED',
  PENDING = 'PENDING'
}

export class UploadedFile {
  public id?: number;
  public productId: number;
  public filename: string;
  public type?: FileUploadType;
  public state?: FileState;
  public message?: string;
  public processingStart: Date;
  public processingEnd: Date;

}

export interface Category {
  id: string;
  category: string;
  values: Value[]
}

export interface Value {
  id: string;
  value: string;
  category: string;
}

export class ProductMarketingConfig {
  defaultBlendedSegmentSplit?: number;
}
