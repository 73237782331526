<ng-container [formGroup]="formGroup">
  <mat-form-field subscriptSizing="dynamic" [appearance]="appearance" [ngClass]="className">
    @if (showLabel) {
      <mat-label [translate]="label.length ? label : 'scenario.events.formLabel.segments' | translate"></mat-label>
    }
    <mat-select 
      #segment 
      panelWidth='null'
      panelClass="segment-select"
      (focus)="segment.open()"
      [placeholder]="placeholder"
      (closed)="searchTerm.reset('')"
      [attr.data-cy]="e2eTag"
      [formControlName]="control"
      [multiple]="multi"
      (selectionChange)="toggleSelection($event)">
      @if (searchEnabled) {
        <input
          class="mat-input-element"
          [formControl]="searchTerm" type="text" [placeholder]="'scenario.events.searchLabel.segments' | translate"/>
      }
      @if (enableGlobalSelect) {
        <mat-option
          [value]="globalValue">
          {{ globalValue | uppercase }}
        </mat-option>
      }
      @for (segment of visibleSegments$ | async; track segment.id) {
        <mat-option
          [disabled]="disabledOptions.includes(segment.id)"
          [value]="segment.id">
          {{ segment.name }}
        </mat-option>
      }
    </mat-select>
    @if (suffix && hasValue) {
      <div matIconSuffix class="pr-2">
        <mat-icon (click)="reset($event)">clear</mat-icon>
      </div>
    }
    @if (helpText.length) {
      <mat-hint>{{ helpText }}</mat-hint>
    }
    <mat-error [translate]="'milestones.errors.required'"></mat-error>
  </mat-form-field>
</ng-container>
