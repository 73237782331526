import { ChangeDetectionStrategy, Component, Inject, OnInit, OnDestroy, inject } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Observable, BehaviorSubject, take } from 'rxjs';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../store/reducers';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { SvgImagesComponent } from 'src/app/core/ui/svg-images/svg-images.component';
import { ButtonComponent } from 'src/app/core/ui/button/button.component';
import { ScenarioService } from '../../services/scenario.service';
import { IScenario } from '../../model/scenario.model';
import { selectAllScenarios } from 'src/app/store';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AccountService } from 'src/app/core/services/account.service';
import { CustomValidators } from 'src/app/core/validators/custom.validators';

@Component({
    selector: 'app-add-new-scenario',
    templateUrl: './add-new-scenario.component.html',
    styleUrls: ['./add-new-scenario.component.scss'],
    standalone: true,
    imports: [CommonModule, ReactiveFormsModule, SvgImagesComponent, ButtonComponent, TranslateModule, MatCheckboxModule, MatFormFieldModule, MatSelectModule],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddNewScenarioComponent implements OnInit, OnDestroy {
  private accountService = inject(AccountService);
  private readonly _isSaving$ = new BehaviorSubject<boolean>(false);
  readonly isSaving$ = this._isSaving$.asObservable();
  private scenariosSubscription;
  scenarioForm: FormGroup;
  scenarios: IScenario[];
  isInternalUser$ = this.accountService.hasAnyAuthority(['ROLE_INTERNAL'])

  constructor(
    public dialogRef: DialogRef<{ scenarioId: number, isDuplicate: boolean }>,
    private scenarioService: ScenarioService,
    private formBuilder: FormBuilder,
    private translateService: TranslateService,
    private store: Store<AppState>,
    @Inject(DIALOG_DATA) public data: {clone: boolean, productId: number, scenarioId?: number}
  ) {
  }

  ngOnInit(): void {
    this.scenarioForm = this.formBuilder.group({
      name: [''],
      duplicate: [true],
      internal: [false],
      scenarioList: ['']
    });

    this.scenariosSubscription = this.store.select(selectAllScenarios).pipe(
      take(1)
    ).subscribe(scenarios => {
      this.scenarios = scenarios;
      const defaultScenario = scenarios.find(scenario => scenario.default);
      if (defaultScenario) {
        this.scenarioForm.get('scenarioList').setValue(defaultScenario.id);
      }
      this.scenarioForm.get('name').setValidators([Validators.required, CustomValidators.hasNoWhitespace, CustomValidators.uniqueNameValidator(scenarios)]);
    });
  }

  getErrorMessage() {
    const name = this.scenarioForm.get('name');
    return name.hasError('required') ? this.translateService.instant('scenario.errorText.required') : name.hasError('unique') ?
      this.translateService.instant('scenario.exists', {name: name.value}) : '';
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  create($event) {
    $event.preventDefault();
    $event.stopPropagation();
    $event.stopImmediatePropagation();

    if (this.scenarioForm.invalid) {
      return;
    }
    this._isSaving$.next(true)

    let fromScenario: IScenario = null;
    if (this.data.clone && this.data.scenarioId) {
      fromScenario = this.scenarios.find(scenario => scenario.id === this.data.scenarioId);
    } else if (!this.data.clone && this.scenarioForm.get('duplicate').value) {
      const selectedScenarioId = this.scenarioForm.get('scenarioList').value;
      fromScenario = this.scenarios.find(scenario => scenario.id === selectedScenarioId);
    }

    const name = this.scenarioForm.controls.name.value;
    const internal = this.data.clone ? fromScenario.internal : this.scenarioForm.controls.internal.value;
    const productId = this.data.productId;
    
    this.subscribeToCreateResponse(this.scenarioService.create(
      name,
      productId,
      internal,
      fromScenario,
    ));
  }

  protected subscribeToCreateResponse(result: Observable<any>) {
    result.subscribe({
      next: (scenario) => this.onSaveSuccess(scenario),
      error: (error) => this.onSaveError(error)
    });
  }

  protected onSaveSuccess(scenario: IScenario) {
    this._isSaving$.next(false);
    this.dialogRef.close({ scenarioId: scenario.id, isDuplicate: this.scenarioForm.get('duplicate').value || this.data.clone });
  }
  
  protected onSaveError(error: any) {
    this._isSaving$.next(false);
    const hasDuplicateNameError = error.graphQLErrors.some(err => err.message.includes("Duplicate name provided for scenario"));
    if (hasDuplicateNameError) {
      this.scenarioForm.get('name').setErrors({unique: true});
    }
  }

  onDuplicateChange(event: MatCheckboxChange) {
    if (event.checked) {
      this.scenarioForm.get('scenarioList').enable();
    } else {
      this.scenarioForm.get('scenarioList').disable();
    }
  }

  ngOnDestroy(): void {
    if (this.scenariosSubscription) {
      this.scenariosSubscription.unsubscribe();
    }
  }
}
