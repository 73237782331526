import {NEVER, of} from 'rxjs';

export class AuthHelpers {

  static unauthorized(error) {
    if (error.status === 401) {
      return of(null);
    }
    console.warn(error);

    return NEVER;
  }
}
