<div
  [ngClass]="{ 
    'pb-4' : showHeading,
}"
>
  @if (showHeading) {
    <h2 class="text-base pb-1.5 font-medium text-black dark:text-white">
      <ng-content select="[panelHeading]"></ng-content>
    </h2>
  }
  <div class="divide-y divide-gray-200/70 border shadow-sm rounded-md bg-white">
    @if (isLoading) {
      @for (n of getLoadingRange(); track n) {
        <div class="w-full py-[1rem] flex justify-between px-6 gap-16 animate-pulse">
          <div class="w-24 h-5 bg-gray-200 rounded-md"></div>
          <div class="w-12 h-5 bg-gray-200 rounded-md"></div>
          <div class="w-12 h-5 bg-gray-200 rounded-md"></div>
        </div>
      }
    } @else {
      <ng-content select="[panelContent]"></ng-content>
    }
  </div>
</div>
