import { DestroyRef, inject, Injectable, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { filter, map, switchMap } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

export class OnDestroy {
  destroyRef = inject(DestroyRef);
}

abstract class FormUpdates {
  abstract onFormSubmit(value: any): void;

  abstract onFormLoad(value: any): void;
}

@Injectable()
export abstract class ConfigComponent<T, K> extends OnDestroy implements OnInit, FormUpdates {
  form: FormGroup
  service: K;

  ngOnInit() {
    this.form.valueChanges
      .pipe(
        filter(() => this.form.valid),
        map((_) => this.form.getRawValue()),
        takeUntilDestroyed(this.destroyRef),
        switchMap(this.onFormSubmit.bind(this)),
      ).subscribe();
  }

  onFormLoad<T>(value: T): void {
    this.form.patchValue({
      ...value,
    }, {emitEvent: false});
  }

  onFormSubmit(value: any): void {
    throw Error('Method not implemented.');
  }

}
