<button mat-ripple mat-button [class.active]="loading"
  [class.fullWidth]="options.fullWidth"
  [class.mat-mdc-fab]="options.fab"
  [class.mat-mdc-unelevated-button]="options.flat"
  [class.mat-mdc-raised-button]="options.raised"
  [class.mat-mdc-outlined-button]="options.stroked"
  [disabled]="loading || disabled"
  [ngClass]="options.customClass"
  [type]="options.type"
  [color]="options.buttonColor || 'primary'"
  type="button">

  @if (!options.fab) {
    <span
      [class.active]="loading && !disabled"
      class="button-text">
      <ng-content></ng-content>
    </span>
  }

  @if (options.fab && options.icon) {
    <mat-icon
      [color]="options.icon.color"
      [fontIcon]="options.icon.fontIcon"
      [fontSet]="options.icon.fontSet"
      [inline]="options.icon.inline"
      [svgIcon]="options.icon.svgIcon">
      {{ options.icon.fontSet ? '' : options.icon.fontIcon }}
    </mat-icon>
  }

  <mat-spinner [class.active]="loading && !disabled"
               [color]="options.spinnerColor"
               [diameter]="20"
               [mode]="options.mode || 'indeterminate'"
               [value]="options.value"
               class="spinner">
  </mat-spinner>
</button>
