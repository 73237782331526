import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-main-header',
  templateUrl: './main-header.component.html',
  styles: [`:host { display: block }`],
})
export class MainHeaderComponent {
  @Input() isDark: boolean = false
}
