<div class="dark:text-white dark:bg-slate-950 relative flex flex-col items-center"
  [ngClass]="{
    '-mt-3': hasGranularity,
    '-mt-5': !hasGranularity
  }">
  @if (hasGranularity) {
    <div
    [ngClass]="{
      'opacity-0 pointer-events-none': !lookerIframe.dashboardLoaded
    }"
      class="transition-all inline-flex h-10 rounded-lg bg-white border border-gray-300 dark:bg-slate-900 dark:border-transparent dark:text-white overflow-hidden divide-x dark:divide-slate-950 font-medium">
      @for (granularity of granularityOptions; track granularity.label) {
        <button
          (click)="onChange(granularity)"
        [ngClass]="{
          'bg-soft-blue dark:shadow-none dark:bg-black/30 shadow-inner': isActive(granularity),
          'text-black/60 dark:text-white/60': !isActive(granularity),
        }"
          class="active flex-1 px-3.5 h-full flex items-center justify-center text-sm gap-3 hover:bg-soft-blue dark:hover:bg-slate-700">
        <div class="h-4" [ngClass]="{
          'text-digital-blue': isActive(granularity),
        }">
            <app-svg-images [svgName]="granularity.icon"></app-svg-images>
          </div>
          <span>
            {{ "global.granularity." + granularity.label| translate }}
          </span>
        </button>
      }
    </div>
  }
  <app-looker-iframe #lookerIframe [hideTitle]="true" [dashboard]="dashboard" [loaded]="loaded" [url]="url"></app-looker-iframe>
</div>
