import { combineReducers } from '@ngrx/store';
import { OrganisationState, organisatioStateReducer } from './organisation.reducer';
import { UserState, userReducer } from './user.reducer';
import { State, userManagementReducer } from './user-management.reducer';

export * from './user.reducer';
export * from './organisation.reducer';
export * from './user-management.reducer';
export interface UserManagementState {
    users: UserState;
    organisations: OrganisationState;
    userManagement: State;
}

export const userManagementReducers = combineReducers<UserManagementState>({
    users: userReducer,
    organisations: organisatioStateReducer,
    userManagement: userManagementReducer,
});