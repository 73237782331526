import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const ReportingActions = createActionGroup({
  source: 'Reporting',
  events: {
    'Load Reports': emptyProps(),
    'Load Reports Success': props<{ group: any[], personal: any[] }>(),
    'Load Reports Failure': props<{ error: unknown }>(),
    'Update Filters': props<{ filters: any }>(),
    'Reset Filters': emptyProps(),
  }
});
