import {AfterViewInit, Directive, ElementRef, OnDestroy} from '@angular/core';

@Directive({
  selector: '[appFocusable]'
})
export class FocusableDirective implements AfterViewInit, OnDestroy {

  constructor(private host: ElementRef) {
    this.host.nativeElement.addEventListener('keypress', this.endEditing.bind(this));
  }

  ngAfterViewInit() {
    this.host.nativeElement.focus();
  }

  endEditing(e) {
    if (e.key.toLowerCase() === 'enter') {
      this.host.nativeElement.blur();
    }
  }

  ngOnDestroy(): void {
    this.host.nativeElement.removeEventListener('keypress', this.endEditing.bind(this));
  }
}
