export const PRODUCT_REPORT_LINKS = [
  {
    label: 'titles.reportGroups.general',
    icon: 'general',
    links: [
      {
        label: 'titles.product.reports.scenario.title',
        link: 'scenario',
        isFavourite: false,
      },
      {
        label: 'titles.product.reports.config-summary.title',
        link: 'config-summary',
        isFavourite: false,
      }
    ],
  },
  {
    label: 'titles.reportGroups.marketing',
    icon: 'revenue',
    links: [
      {
        label: 'titles.product.reports.new-users.title',
        link: 'new-users',
        isFavourite: false,
      },
      {
        label: 'titles.product.reports.product-marketing-spend.title',
        link: 'product-marketing-spend',
        isFavourite: false,
      },
      {
        label: 'titles.product.reports.product-cpa.title',
        link: 'product-cpa',
        isFavourite: false,
        role: null,
      }
    ],
  },
  {
    label: 'titles.reportGroups.engagement',
    icon: 'users-retention',
    links: [
      {
        label: 'titles.product.reports.active-users.title',
        link: 'active-users',
        isFavourite: false,
      },
      {
        label: 'titles.product.reports.retention-curve.title',
        link: 'retention-curve',
        isFavourite: false,
      },
    ],
  },
  {
    label: 'titles.reportGroups.revenue',
    icon: 'cost-spend',
    links: [
      {
        label: 'titles.product.reports.user-revenue.title',
        link: 'user-revenue',
        isFavourite: false,
      },
      {
        label: 'titles.product.reports.lifetime-value.title',
        link: 'lifetime-value',
        isFavourite: false,
      },
      {
        label: 'titles.product.reports.revenue.title',
        link: 'revenue',
        isFavourite: false,
      },
      {
        label: 'titles.product.reports.product-revenue-summary.title',
        link: 'product-revenue-summary',
        isFavourite: false,
      }
    ],
  },
];
