@if (notifications$ | async; as notifications) {
  <button (click)="toggleBadgeVisibility(notifications)" class="h-6 flex items-center gap-1 active group/button dark:text-white">
    <app-svg-images [svgName]="notifications.length ? 'bell-alert' : 'bell'" class="h-6 group-hover/button:rotate-12 transition-all"></app-svg-images>
    @if (notifications.length) {
      <span class="inline-block rounded-md bg-kohort-primary p-1 text-xs font-medium text-white group-hover/button:scale-105 transition-all">
        {{ notifications.length }}
      </span>
    }
  </button>
}
