import { Inject, Injectable } from '@angular/core';
import { SENTRY_CONFIG, SentryConfig } from './sentry.config';
import * as Sentry from '@sentry/angular';
import { User } from '@auth0/auth0-angular';
import pkg from '../../../../package.json';
import { APP_CONFIG } from "../app.constants";
import { AppConfig } from "../config/models/config";

@Injectable()
export class SentryService {

  constructor(
    @Inject(SENTRY_CONFIG) private sentryConfig: SentryConfig, @Inject(APP_CONFIG) private config: AppConfig) {
  }

  public boot(): void {
    if (!this.sentryConfig.enabled)
      return;

    const {runwayUrl, reportsUrl} = this.config.resourceServer;

    Sentry.init({
      dsn: this.sentryConfig.dsn,
      release: `version-${pkg.version}`,
      integrations: [
        Sentry.browserTracingIntegration(),
      ],
      tracePropagationTargets: [runwayUrl, reportsUrl],
      tracesSampleRate: 0.05,
      replaysSessionSampleRate: 0.05,
      replaysOnErrorSampleRate: 1.0
    })
  }

  setUser(identity: User) {
    if (!this.sentryConfig.enabled) {
      console.warn('Sentry not initialized');
      return;
    }

    Sentry.setUser({email: identity.email, id: identity.sub, username: identity.name});
  }
}
