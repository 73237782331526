import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChange,
  SimpleChanges,
  ViewChild,
  inject, EventEmitter, Output
} from '@angular/core';
import { LookerResponse } from '../../services/looker.service';
import { Preset } from '../../looker/looker-granularity/looker-granularity.component';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { Store } from '@ngrx/store';
import { getProductSegments, trackItem } from 'src/app/store';
import { REPORTS_BY_SEGMENT_ROUTES } from '../../app.constants';
import { ReportingActions } from "../../../store/actions/reporting.actions";

@Component({
  selector: 'app-looker-iframe',
  templateUrl: './looker-iframe.component.html',
  styleUrls: ['./looker-iframe.component.scss'],
})
export class LookerIframeComponent implements OnChanges {

  constructor(private elRef: ElementRef, private router: Router, private activatedRoute: ActivatedRoute) {
    this.elRef.nativeElement.style.setProperty('min-height', this.minHeight);
    this.isBySegmentsChart = (
      REPORTS_BY_SEGMENT_ROUTES.includes(this.activatedRoute.snapshot?.routeConfig?.path?.toLocaleLowerCase())
      &&
      this.router.url.includes('scenarios')
    );
  }

  get pxHeight() {
    return `${this.lastHeight}px`;
  }

  set height(value) {
    const loadingIconSize = 60;
    const minHeight = value > loadingIconSize ? value : 0;
    this.elRef.nativeElement.style.setProperty('min-height', `${minHeight}px`);
    this.elRef.nativeElement.style.setProperty('height', `${value}px`);
  }

  get isLoading() {
    return this.loading;
  }

  get instanceName() {
    const url = new URL(this.currentUrl);
    return `${url.protocol}//${url.host}`;
  }

  @Input() url: LookerResponse;
  @Input() loaded: boolean;
  @Input() visible = true;
  @Input() dashboard!: string;
  @Input() hideTitle = false;
  @Output() onLoaded = new EventEmitter();
  @ViewChild('lookerIframe', {static: true}) lookerIframe: ElementRef;
  lastHeight = 1024;
  private store = inject(Store);
  private loading = false;
  public segments$ = this.store.select(getProductSegments);
  public isBySegmentsChart = false;
  public dashboardLoaded = false;
  public currentUrl = 'about:blank';
  public elements;
  public isLoaded: BehaviorSubject<boolean> = new BehaviorSubject(false);

  @Input() minHeight = '614px';
  stackedOnLoad = [];

  ngOnChanges(changes: SimpleChanges): void {
    if (this.visible && this.loaded) {
      this.height = this.lastHeight;
    } else {
      this.height = 0;
    }
    if (changes.loaded && (changes.loaded as SimpleChange).currentValue === true) {
      this.currentUrl = this.url?.url;
      window.addEventListener('message', (event) => {
        if (event.source === this.lookerIframe.nativeElement.contentWindow) {
          const data = JSON.parse(event.data);

          if (data.type === 'page:properties:changed') {
            this.lastHeight = data.height === 0 ? this.lastHeight : data.height;
            if (this.visible) {
              this.height = this.lastHeight;
            } else {
              this.height = 0;
            }
          }
          if (data.type === 'dashboard:loaded') {
            this.onLoaded.emit(data);
            this.elements = data.dashboard.options.elements;
            this.dashboardLoaded = true;
            this.isLoaded.next(true);
            const {dashboard_filters} = data.dashboard;
            this._syncFiltersToUrl(dashboard_filters, false);

            //Waiting for the iframe content to load to avoid flickering
            setTimeout(() => {
              this.loading = false;
            }, 2000);
          }
          if (data.type === 'dashboard:filters:changed') {
            const {dashboard_filters} = data.dashboard;
            this._syncFiltersToUrl(dashboard_filters);
          }

          if (['dashboard:download', 'dashboard:tile:download', 'dashboard:tile:explore'].includes(data.type)) {
            const {product, scenario} = this.activatedRoute.snapshot.data;
            const {dashboard, tile, type} = data;
            const eventName = data.type.includes('download') ? 'Download' : 'Explore';
            this.store.dispatch(trackItem({
              label: 'Looker Dashboard ' + eventName,
              data: {
                product_id: product?.id,
                product_name: product?.name,
                ...(!!scenario?.id ? {scenario_id: scenario.id} : null),
                ...(!!scenario?.name ? {scenario_name: scenario.name} : null),
                title: (tile?.title || dashboard?.title),
                type
              }
            }));
          }
        }
      });
    } else {
      if (this.loaded && changes.url) {
        this.currentUrl = changes.url.currentValue?.url;
      }
    }
    if (!this.loaded) {
      this.loading = true;
    }
  }

  public refresh() {
    if (this.currentUrl !== 'about:blank' && this.lookerIframe.nativeElement.contentWindow != null) {
      const reloadRequest = JSON.stringify(
        {
          type: 'dashboard:run'
        }
      );
      this.lookerIframe.nativeElement.contentWindow.postMessage(reloadRequest, this.instanceName);
    }
  }

  public update(filters?) {
    if (this.currentUrl !== 'about:blank' && this.lookerIframe.nativeElement.contentWindow != null) {
      const reloadRequest = JSON.stringify(
        {
          type: 'dashboard:filters:update',
          filters,
        }
      );
      this.lookerIframe.nativeElement.contentWindow.postMessage(reloadRequest, this.instanceName);
      this.refresh();
    }
  }

  toggleFilters($event: Preset) {
    const filters = {...$event.filters};
    this.update(filters);
  }

  private _syncFiltersToUrl(filters, sync = true) {
    const params = this.router.routerState.snapshot.root.queryParams;
    const newParams = {...params, ...filters};
    if (sync) {
      this.store.dispatch(ReportingActions.updateFilters({filters: newParams}));
      }
    this.router.navigate([], {queryParams: newParams});
  }
}

