<div class="flex items-center text-sm border rounded-md bg-white group-hover/productLink:shadow-sm">
  <div class="py-1 px-2">
    <div class="items-center flex">
      <app-svg-images class="text-kohort-primary h-4" svgName="squares-2x2"></app-svg-images>
      @if (segmentCount) {
        <span class="pl-1">{{segmentCount}}</span>
      }
    </div>
  </div>
  @if (!alwaysExpanded) {
    <div class="flex items-center xl:hidden py-1 pr-2.5 pl-2 divide-x border-l">
      {{segments.length}}
      <span [translate]="segments.length > 1 ? 'data.product.categories' : 'data.product.category'" class="pl-1"></span>
    </div>
  }
  @for (segment of segments; track segment; let idx = $index) {
    @if (idx < totalVisibleSegments) {
      <div 
        class="items-center divide-x border-l"
        [ngClass]="{
          'hidden xl:flex': !alwaysExpanded, 
          'flex': alwaysExpanded,
        }">
          <span class="text-gray-600 py-1 px-2.5 font-normal">
            {{segment | titlecase}}
          </span>
      </div>
    }
  }
  @if (segments.length > totalVisibleSegments) {
    <div 
      [matTooltip]="segments.slice(totalVisibleSegments).join(' | ')" 
      matTooltipPosition="above"
      class="items-center divide-x border-l"
      [ngClass]="{
        'hidden xl:flex': !alwaysExpanded, 
        'flex': alwaysExpanded,
      }">
      <span class="text-gray-600 py-1 px-2.5 font-normal">
        ...
      </span>
    </div>
  }
</div>
