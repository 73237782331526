import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as PageActions from '../actions/page.actions';
import { tap } from 'rxjs/operators';
import { HelpersService } from 'src/app/core/services/helpers.service';
import { AlertService } from 'src/app/core/services/alert.service';


@Injectable()
export class PageEffects {

  constructor(
    private actions$: Actions,
    private helpersService: HelpersService,
    public messageService: AlertService
  ) {}

  saveItemLocally$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PageActions.saveToLocalStorage),
      tap(({key, value}) => {
        this.helpersService.saveToLocalStorage(key, value);
      })
    )
  }, {dispatch: false});

  showError$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PageActions.pageApiError),
      tap(({error}) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: error?.message,
            life: 8000
          });
      }),
    )
  }, {dispatch: false});
}
