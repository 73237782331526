import { Component, Input } from '@angular/core';
import { DEFAULT_LOADING_PAGE_SIZE } from 'src/app/core/app.constants';

@Component({
  selector: 'app-panel-list-group',
  templateUrl: './panel-list-group.component.html'
})
export class PanelListGroupComponent {
  @Input() icon: string;
  @Input() showHeading: boolean = true;
  @Input({
    transform: (value: number) => value || DEFAULT_LOADING_PAGE_SIZE
  }) loadingPageSize: number;
  @Input() isLoading: boolean = false;

  getLoadingRange(): number[] {
    return Array(this.loadingPageSize).fill(0).map((x, i) => i);
  }
}
