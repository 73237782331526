import { Component, Input } from '@angular/core';
import { ProductBlendedSegmentNode } from 'src/app/views/scenario/settings/models/config.models';
import { FlagPipe } from 'src/app/views/scenario/pipes/flag.pipe';

export interface SegmentParts {
  country: string;
  platform: string;
  other: string[];
}

@Component({
  selector: 'app-segment-label',
  templateUrl: './segment-label.component.html',
  imports: [FlagPipe],
  standalone: true,
})
export class SegmentLabelComponent {
  @Input() segment: ProductBlendedSegmentNode;

  segmentParts(): SegmentParts {
    if (!this.segment) return;
    const [country, platform, ...other] = this.segment.name.split('|');
    return { country, platform, other };
  }
}
