import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { catchError, map } from 'rxjs/operators';
import { of, switchMap } from 'rxjs';
import { ReportingActions } from '../actions/reporting.actions';
import { LookerService } from '../../core/services/looker.service';
import * as fromAuth from "../../views/user-management/redux/actions";
import * as fromProduct from "../actions";
import { Action } from "@ngrx/store";


@Injectable()
export class ReportingEffects implements OnInitEffects {
  private looker = inject(LookerService);

  loadReports$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ReportingActions.loadReports, fromAuth.organisationChanged),
      switchMap(() =>
        this.looker.getCustomDashboards().pipe(
          map(({group, personal}) => ReportingActions.loadReportsSuccess({group, personal})),
          catchError(error => of(ReportingActions.loadReportsFailure({error}))))
      )
    );
  });

  resetFilters$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromAuth.organisationChanged, fromProduct.clearScenarios),
      map(() => ReportingActions.resetFilters())
    );
  })

  ngrxOnInitEffects(): Action {
    return ReportingActions.loadReports()
  }

  constructor(private actions$: Actions) {
  }
}
