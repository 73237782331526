import { createReducer, on } from '@ngrx/store';
import * as UserActions from '../actions/user.actions';
import * as UserManagementActions from '../actions/user-management.actions';
import { IUser, IOrganisation, IRole } from '../../model/user-management.model';
import { IPaginatedDto } from 'src/app/core/model/app.model';
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';

export interface IUserState extends IUser {
  currentOrgId?: string;
  organisations?: IPaginatedDto<IOrganisation>;
  roles?: IPaginatedDto<IRole>;
}

export interface UserState extends EntityState<IUserState> {
  loading: boolean;
  totalCount?: number;
}

export const userAdapter: EntityAdapter<IUserState> = createEntityAdapter<IUserState>();

export const initialUserState: UserState = userAdapter.getInitialState({
  loading: false,
  totalCount: 0,
});

export const userReducer = createReducer(
  initialUserState,
  on(
    UserManagementActions.loadApp,
    UserActions.deleteUser,
    UserActions.fetchUserOrganisations,
    UserActions.fetchUsers,
    UserActions.updateUserRole,
    UserManagementActions.updateUserProfile,
    UserManagementActions.uploadUserImage,
    UserManagementActions.deleteUserImage,
    (state) => ({ ...state, loading: true })
  ),
  on(UserActions.addUser,
    (state, action) => userAdapter.addOne(action.user, {...state, loading: false})
  ),
  on(UserActions.upsertUser,
    (state, action) => userAdapter.upsertOne(action.user, {...state, loading: false})
  ),
  on(UserActions.addUsers,
    (state, action) => userAdapter.addMany(action.users, {...state, loading: false})
  ),
  on(UserActions.upsertUsers,
    (state, action) => userAdapter.upsertMany(action.users, {...state, loading: false})
  ),
  on(UserActions.updateUser,
    (state, action) => userAdapter.updateOne(action.user, {...state, loading: false})
  ),
  on(UserActions.updateUsers,
    (state, action) => userAdapter.updateMany(action.users, {...state, loading: false})
  ),
  on(UserActions.deleteUserById,
    (state, action) => userAdapter.removeOne(action.id, {...state, loading: false})
  ),
  on(UserActions.deleteUsers,
    (state, action) => userAdapter.removeMany(action.ids, {...state, loading: false})
  ),
  on(UserActions.loadUsers,
    (state, action) => userAdapter.setAll(action.users, {...state, totalCount: action.totalCount, loading: false})
  ),
  on(UserActions.clearUsers,
    state => userAdapter.removeAll(state)
  ),
);
