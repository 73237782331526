import {
  Component,
  DestroyRef,
  inject,
  Input,
  OnChanges,
  OnInit,
  SimpleChange,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { LookerService } from '../../services/looker.service';
import { Scenario } from '../../../views/scenario/model/scenario.model';
import { LookerIframeComponent } from '../../ui/looker-iframe/looker-iframe.component';
import { BaseGranularity } from '../base-granularity/base-granularity';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { IProduct } from 'src/app/views/product/model/product.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Theme, ThemeService } from '../../services/theme.service';
import { Store } from "@ngrx/store";
import { selectFilters, selectFiltersByDashboard } from "../../../store/selectors/reporting.selectors";
import { lastValueFrom, map, switchMap, takeLast } from "rxjs";
import { Helpers } from "../../shared/helpers";
import { AccountService } from '../../services/account.service';
import { SHOW_INTERNAL_FILTER } from '../../app.constants';
import { take } from "rxjs/operators";

export interface Preset {
  label: string;
  type: string;
  icon: string;
  filters: object;
}

@Component({
  selector: 'app-looker-granularity',
  templateUrl: './looker-granularity.component.html',
  styleUrls: ['./looker-granularity.component.scss'],
})
export class LookerGranularityComponent extends BaseGranularity implements OnInit, OnChanges {

  @Input('chart') chart: string;
  @Input('scenario') scenario: Scenario;
  @Input('product') productId: number | IProduct;
  @Input('model') model: string;
  @Input('hasGranularity') hasGranularity = true;
  @ViewChild('lookerIframe', { static: true }) lookerIframe: LookerIframeComponent;

  protected activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private router: Router = inject(Router);
  private store: Store = inject(Store);
  private accountService: AccountService = inject(AccountService);

  _params: Params = {};
  public isDailyGranularity: boolean;
  public presetSet: boolean = false;
  private destroyRef = inject(DestroyRef);
  isInternal: boolean;
  currentTheme: Theme;
  themes: Theme[] = [];

  constructor(
    private lookerService: LookerService,
    public themeService: ThemeService
  ) {
    super();
    this.activatedRoute.queryParams
      ?.pipe(
        takeUntilDestroyed(this.destroyRef),
        // If the preset exists in route data set it from there otherwise it will be overridden from queryParams
        switchMap(params => this.activatedRoute.data.pipe(
          map(({preset}) => ({...params, ...preset && !params.preset && {preset}})))
        ),
        switchMap(params => this.store.select(selectFilters).pipe(
          map(filters => ({...params, ...filters})))
        )
      ).subscribe(params => this._params = params)
  }

  ngOnInit() {
    this.setPreset();
    this.themes = this.themeService.getThemes();

    this.themeService.getCurrentTheme().pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(theme => {
      this.currentTheme = theme;
      this.loadDashboard();
    });

    this.accountService.hasAnyAuthority(['ROLE_INTERNAL']).pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(isInternal => {
      this.isInternal = isInternal;
      this.loadDashboard();
    });

  }

  public async loadDashboard() {
    this.loaded = false;
    if (this.scenario) {
      await this.loadScenarioDashboard();
    } else if (this.productId) {
      await this.loadProductDashboard();
    }
  }

  getHiddenFilters(): string[] {
    if (this.scenario && this.hasGranularity) {
      return this.scenarioHiddenFilters;
    } else if (this.productId && this.hasGranularity) {
      return this.productHiddenFilters;
    } else {
      return [];
    }

  }

  private async loadScenarioDashboard() {
    const filters = await this._filters();
    this.url = await lastValueFrom(this.lookerService.createScenarioDashboardEmbeddedUrl(this.scenario.id, this.dashboard, filters, null, this.getHiddenFilters()))
    this.loaded = true;
  }

  private async loadProductDashboard() {
    const filters = {
      ...await this._filters(),
      ...(typeof this.isInternal === 'boolean' && {
          [SHOW_INTERNAL_FILTER]: (this.isInternal ? 'yes' : 'no')
        }
      )
    };
    const id = typeof this.productId === 'number' ? this.productId : this.productId.id;
    this.url = await this.lookerService.createProductDashboardEmbeddedUrl(id, this.dashboard, filters, null, this.getHiddenFilters()).toPromise();
    this.loaded = true;
  }

  get dashboard() {
    let dashboard = this.chart;

    if ((!this.presetSet && this._params.preset && this._params.preset != 'day') ||
      this.selectedPreset.label !== 'Day') {
      dashboard = `${ this.chart }_non_daily`;
    }
    return dashboard;
  }

  async _filters() {
    const filters = await lastValueFrom(this.store.select(selectFiltersByDashboard(this.dashboard)).pipe(take(1)))
    const presetFilters = this.selectedPreset.filters
    if (!Helpers.hasDefaultDateRange(this._params)) {
      delete presetFilters['Date Range'];
    }
    return {
      ...this._params,
      ...(this.hasGranularity && this.presetSet && presetFilters),
      ...filters
    }
  }


  ngOnChanges(changes: SimpleChanges): void {
    const scenario: SimpleChange = (changes.scenario);
    const productId: SimpleChange = (changes.productId);
    const chart: SimpleChange = (changes.chart);
    if (scenario && scenario.currentValue) {
      this.loadScenarioDashboard();
    } else if (productId && productId) {
      this.loadProductDashboard();
    } else if (chart.currentValue !== chart.previousValue) {
      this.loadDashboard();
    }
  }

  async onChange($event) {
    this.selectedPreset = $event;
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        ...this._params,
        ...{
          preset: this.selectedPreset.label.toLowerCase(),
        }
      }
    })

    // Check if we need to load a new dashboard
    if (this.isDailyGranularity !== ($event.label === 'Day')) {
      await this.loadDashboard();
      this.setDailyGranularity();
    } else {
      this.lookerIframe.toggleFilters($event);
    }
  }

  isActive(preset: Preset) {
    return preset === this.selectedPreset;
  }

  private setPreset() {
    if (this._params.preset) {
      this.selectedPreset = this.getPresetByLabel(this._params.preset) ?? this.granularityOptions[0];
    }
    this.setDailyGranularity();
    this.presetSet = true;
  }

  setDailyGranularity() {
    this.isDailyGranularity = this.selectedPreset.label === 'Day';
  }

  private getPresetByLabel(label: string): Preset {
    return this.granularityOptions.find(preset => preset.label.toLowerCase() === label);
  }

  public toggleTheme(theme: Theme): void {
    this.themeService.setTheme(theme.label);
  }
}
