import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface Theme {
  label: string;
  icon: string;
  lookerTheme: string;
}

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private themes: Theme[] = [
    { label: 'Dark', icon: 'moon-solid', lookerTheme: 'dark' },
    { label: 'Light', icon: 'sun-solid', lookerTheme: 'v2' },
  ];

  private currentTheme$ = new BehaviorSubject<Theme>(this.getTheme(this.loadInitialThemeLabel()));

  constructor() {}

  private loadInitialThemeLabel(): string {
    return localStorage.getItem('currentThemeLabel') || this.getDefaultTheme().label;
  }

  getCurrentTheme(): Observable<Theme> {
    return this.currentTheme$.asObservable();
  }

  public getCurrentThemeValue(): Theme {
    return this.currentTheme$.value;
  }
  
  private getTheme(label: string): Theme {
    return this.themes.find(theme => theme.label === label);
  }

  setTheme(themeLabel: string): void {
    const theme = this.themes.find(t => t.label === themeLabel);
    if (theme) {
      localStorage.setItem('currentThemeLabel', themeLabel);
      this.currentTheme$.next(this.getTheme(themeLabel));
    }
  }

  public isDarkMode(): Observable<boolean> {
    return this.getCurrentTheme().pipe(
      map(theme => theme.label === 'Dark')
    );
  }

  private getDefaultTheme(): Theme {
    return this.getTheme('Dark');
  }

  getThemes(): Theme[] {
    return this.themes;
  }
}
