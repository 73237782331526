import {DateRange, Metrics} from '../../../core/metrics/metrics.model';
import {UserRevenueConfig} from '../reports/model/user-revenue.model';
import {Seasonality} from '../reports/model/seasonality.model';
import {RetentionModel} from '../settings/retention-curve-config/retention-model';
import {FileState, FileUploadType, Product} from '../../product/model/product.model';
import {ScenarioMetrics} from '../../../core/metrics/scenario-metrics';
import {RetentionSeasonality} from '../reports/model/retention-seasonality.model';
import { IScenarioJobDto, JobStatus } from './scenario-job-dto.model';
import { ScenarioMarketingConfig } from '../settings/models/config.models';
import { IPusherPayload } from "../../../core/services/event.service";

export class RangeConfig {
  constructor(public date?: string, public relative?: boolean, public numberOfDays?: number, public automatic?: boolean) {
  }
}

export class SeasonalityFit {
  constructor(public calendarDate?: boolean) {
  }
}

export class AdjustmentRangeConfig {
  constructor(public type: string = 'Relative', public date?: Date, public numberOfDays?: number) {
  }
}

export class Adjustments {

  constructor(public apply: boolean = false, public start: AdjustmentRangeConfig = new AdjustmentRangeConfig(),
              public by: AdjustmentRangeConfig = new AdjustmentRangeConfig(), public ratio: number = 2) {
  }
}

export class Multiplier {
  constructor(public adjustments?: Adjustments[]) {
  }
}

export class MarketingConfig {
  public paybackPeriod = 12;
  public paybackMethod = 'blended';
  public organicAcquisitionPercentage = 50;
  public organicAcquisitionPercentagePerSegment: Array<any> = [];
}

export class NewUsers {

  constructor(public extrapolation?: RangeConfig, public overrideModel?: boolean,
              public seasonality?: Seasonality, public model?: string, public multiplier?: Multiplier,
              public inputAssumptions?: string,
              public marketing?: MarketingConfig
  ) {
  }
}

export class DxAdjustment {
  constructor(public apply: boolean = false, public percentage: number, public targetDx: number) {
  }
}

export class TemporalLookBack {
  constructor(public apply: boolean = false, public numberOfDays: number = 14) {
  }
}

export class CohortAdjustment {
  constructor(public start?: number, public end?: number) {
  }
}

export class Retention {
  [key: string]: any;

  constructor(public extrapolation?: RangeConfig,
              public seasonality?: RetentionSeasonality,
              public cohortAdjustment?: CohortAdjustment,
              public model?: RetentionModel,
              public overrideModel?: boolean,
              public applyMultiplier?: boolean,
              public temporalLookback?: TemporalLookBack,
              public minimumCohortContributionSize?: number,
              public inputAssumptions?: string,
              public dxAdjustment?: DxAdjustment,
              public useRdLstmModel?: boolean,
  ) {
  }
}

export class CustomerLifetimeValue {
  constructor(public extrapolation?: RangeConfig) {
  }
}

export class Output {
  constructor(public range?: RangeConfig) {
  }
}

export class Config {
  constructor(
    public inputRange?: DateRange,
    public output?: Output,
    public newUsers?: NewUsers,
    public retention?: Retention,
    public marketingV2?: any,
    public userRevenue?: UserRevenueConfig,
    public customerLifetimeValue?: CustomerLifetimeValue,
    public grossMarginPercentage?: number,
    public overrideProductGrossMargin?: boolean,
    public displayEndDateActual?: Date | string
  ) {
  }
}

export interface IScenarioUserEvent {
  id?: number;
  updatedAt?: string;
  updatedBy?: string;
}

export interface IScenario {
  orgId?: any;
  id?: number;
  productId?: number;
  name?: string;
  config?: Config;
  createdBy?: string;
  createdAt?: string;
  favourite?: boolean;
  scenarioUserEvent?: IScenarioUserEvent;
  estimatedRuntime?: number;
  latestScenarioJob?: IScenarioJobDto;
  currentScenarioJob?: IScenarioJobDto;
  currentScenarioJobState?: JobStatus;
  productMetrics?: Metrics;
  metrics?: ScenarioMetrics;
  stale?: boolean;
  product?: Product;
  includeInReports?: boolean;
  internal?: boolean;
  jobTrigger?: string;
  schedule?: ISchedule;
  locked?: boolean;
  default?: boolean;
  budget?: boolean;
  archived?: boolean;
  enableDefaultEvents?: boolean;
  enableInsights?: boolean;
  files?: File[];
  scenarioMarketingConfig?: ScenarioMarketingConfig;
  scenarioMilestones?: any;
}

export class FileError {
  errorMessages: string[];
}

export interface OverrideFileDownload {
  downloadLink?: string
}

export interface ISchedule {
  hour: number;
  days: number[];
}

export interface IPillarModel {
  name:	string
}


export type pillarType =  'monetisation' | 'new_users' | 'retention';

export type pillarModelsType = { [K in pillarType]: string[] };
export interface IFile {
  filename: string;
  type: FileUploadType;
  id?: number;
  state?: FileState;
  errors?: FileError;
  processingStart?: Date;
  processingEnd?: Date;
  dataCopied?: boolean;
  createdAt?: Date;
  updatedAt?: Date;
  originalFilename?: string;
}
export class File implements IFile {
  constructor(
    public filename: string,
    public type: FileUploadType,
    public id?: number,
    public state?: FileState,
    public errors?: FileError,
    public originalFilename?: string
  ) {
  }
}

export class Scenario implements IScenario {
  [key: string]: any;

  constructor(
    public id?: number,
    public name?: string,
    public description?: string,
    public config?: Config,
    public createdBy?: string,
    public createdAt?: string,
    public scenarioUserEvent?: IScenarioUserEvent,
    public estimatedRuntime?: number,
    public latestScenarioJob?: IScenarioJobDto,
    public files?: File[],
    public metrics?: ScenarioMetrics,
    public currentScenarioJob?: IScenarioJobDto,
    public currentScenarioJobState?: JobStatus,
    public product?: Product,
    public locked?: boolean,
    public favourite?: boolean,
    public stale?: boolean,
    public jobTrigger?: string,
    public schedule?: ISchedule,
    public includeInReports?: boolean,
    public enableDefaultEvents?: boolean,
    public monitored?: boolean,
    public archived?: boolean,
    public maxDesiredHoursBetweenProcessing?: number,
    public maxForecastDx?: number,
    public enableInsights?: boolean
  ) {
  }
}

export interface IScenarioUserEventPayload extends IPusherPayload {
  scenario?: IScenarioUserEvent
}
